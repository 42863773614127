import gql from 'graphql-tag';

export const checkKitId = gql`
  query checkKitId($kitId: String!){
    checkKitId(kitId: $kitId){
      valid
      panelMeta
      contextDefaults
    }
  }
`;

export const allOrders = gql`
  query orders {
    orders {
      id
      orderId
      panel {
        name
        meta
      }
      resultsPdfAttachmentsInfo {
        fileId
      }
      activity {
        type
        createdAt
      }
      outreachStatus
      status
      createdAt
    }
  }
`;

export const portalRegisterKit = gql`
  mutation portalRegisterKit($patient: PatientInputType!, $kitId: String!) {
    portalRegisterKit(patient: $patient, kitId: $kitId) {
      id
    }
  }
`;

export const orderDetails = gql`
  query order($id: ID, $orderId: String) {
    order(id: $id, orderId: $orderId) {
      id
      orderId
      panel {
        name
        meta
      }
      resultsPdfAttachmentsInfo {
        fileId
        fileName
        createdAt
      }
      panelAttachmentsInfo {
        fileId
        fileName
      }
      brand {
        id
        name
        meta
      }
      lab {
        name
        address {
          addressLine1
          city
          state
          zip
        }
      }
      releasedAssignedMd {
        firstName
        lastName
      }
      patient {
        firstName
        lastName
        gender
        email
        dob
        phone
        address {
          addressLine1
          city
          state
          zip
        }
      }
      markers {
        name
        rangeRef
        markerRef
        markerDesc
        resultCode
        markerNote
        reading
        specimen
        units
        isCritical
        isThreshold
        rangeType
        ranges {
          name
          type
          value
          isCritical
          isThreshold
          rangeType
        }
      }
    }
  }
`;


export const getDownloadLink = gql`
  query getDownloadLink($orderId: String!, $fileId: ID!) {
    getDownloadLink( orderId: $orderId, fileId: $fileId ) {
      fileId
      signedUrl
    }
  }
`;

export const downloadAllPdf = gql`
mutation downloadAllPdf($id: ID!) {
  downloadAllPdf(id: $id)
}
`;