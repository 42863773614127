import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import ForgotPasswordInitiate from '../components/ForgotPasswordInitiate';
import ResetPassword from '../components/ResetPassword';

class Register extends Component {
  state = {
    // FORGOT_PASS|RESET_PASS
    nextStep: 'FORGOT_PASS',
  }
  constructor(props) {
    super(props);
    const { authStore } = this.props;
    authStore.resetForm('FORGOT_PASS_FRM');
    authStore.resetForm('RESET_PASS_FRM');
  }

  stepHandler = (nextStep) => {
    this.setState({ nextStep })
  }

  render() {
    const { nextStep } = this.state;
    return (
      <section className="full-section d-flex aic jcc">
        <div className="auth-form-wrap">
          {nextStep === 'FORGOT_PASS' && <ForgotPasswordInitiate stepHandler={this.stepHandler} />}
          {nextStep === 'RESET_PASS' && <ResetPassword stepHandler={this.stepHandler} />}
        </div>
      </section>
    );
  }
}
export default inject('authStore', 'kitStore')(withRouter(observer(Register)));
