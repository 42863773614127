import React from 'react';
import { get } from 'lodash';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import Header from '../../theme/layout/Header';
import { Sidebar, Menu } from 'semantic-ui-react';
import NavigationItems from './NavigationItems';

const PusherMenu = (props) => {
  const { pusherVisible } = props.uiStore;
  const { brandMeta } = props.authStore;
  const urlMaker = url => (url.includes('https://') || url.includes('http://')) ? url : `https://${url}`;
  const brandLink = get(brandMeta, 'logoInfo.logoUrl') ? urlMaker(get(brandMeta, 'logoInfo.logoUrl')) : 'https://livesmart.health/';
  let navs = [
    {
      title: get(brandMeta, 'brandTitle') || 'LiveSmart',
      to: brandLink,
      extLink: true,
    },
    {
      title: 'Dashboard',
      to: 'dashboard',
    },
    {
      title: 'Register Kit',
      to: 'register-kit',
    },
    {
      title: 'Account Settings',
      to: 'account-settings',
    }
  ];
  if (!!get(brandMeta, 'privateNav')) {
    navs = get(brandMeta, 'privateMenu') || navs;
  }
  return (
    <Sidebar.Pushable>
      <Header />
      <Sidebar
        direction="right"
        as={Menu}
        animation='overlay'
        // onHide={() => setFieldValue('pusherVisible', false)}
        vertical
        visible={pusherVisible}
      >
        <NavigationItems navList={navs} />
      </Sidebar>
      <Sidebar.Pusher>
        {props.children}
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  );
}

export default inject('uiStore', 'authStore')(withRouter(observer(PusherMenu)));