import React from 'react';
import { observer } from 'mobx-react';
import { Form } from 'semantic-ui-react';
import Autocomplete from 'react-google-autocomplete';
import { FieldError } from '../../shared';

class AutoComplete extends React.Component {
  state = { showError: false };

  componentDidMount() {
    const el = document.getElementsByClassName('pac-container');
    if (el.length) {
      //document.getElementById('addressField').appendChild(el[0]);
    }
  }

  componentDidUpdate() {
    const el = document.getElementsByClassName('pac-container');
    if (el.length) {
      //document.getElementById('addressField').appendChild(el[0]);
    }
  }

  triggerError = (val) => {
    this.setState({ showError: val });
  };

  render() {
    const { props } = this;
    const { label, error, value, placeHolder, rule } = props.fielddata;
    const { displayMode, readOnly, noAnimate } = props;
    const classes = `${props.containerClassName || ''} ${props.readOnly ? 'display-only' : ''}`;
    return (
      <Form.Field
        error={(!!error && this.state.showError) || (!!error && props.showError)}
        className={`${!noAnimate ? 'animate-input' : classes}`}
        id="addressField"
      >
        <div className="ui fluid input">
          {noAnimate && !props.isHideLabel && (label !== '' || props.label !== '')
            && (
              <label>
                {`${props.label || label}${rule.includes('required') ? '*' : ''}`}
              </label>
            )
          }
          {/* <label>{(props.asterisk && props.asterisk === 'true' ? `${label}*` : label)}</label> */}
          <Autocomplete
            {...props}
            onPlaceSelected={(place) => {
              props.onPlaceSelected(place);
            }}
            value={value}
            placeholder={(displayMode || readOnly) ? 'N/A' : placeHolder}
            types={['address']}
            componentRestrictions={{ country: 'us' }}
            onChange={(e) => {
              props.changed(e, {});
              this.triggerError(props.showError || false);
            }}
            onBlur={() => this.triggerError(true)}
          />
          {!noAnimate && !props.isHideLabel && (label !== '' || props.label !== '')
            && (
              <label>
                {`${props.label || label}${rule.includes('required') ? '*' : ''}`}
              </label>
            )
          }
        </div>
        {((error && this.state.showError) || (error && props.showError))
        && <FieldError error={error}/>
        }
      </Form.Field>
    );
  }
}

export default observer(AutoComplete);