/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-prototype-builtins */
import { toJS } from 'mobx';
import FormHandle from 'validatorjs';
import moment from 'moment';
import { mapValues, set, replace, map, mapKeys, isArray, toArray, reduce, includes, forEach, get, isUndefined, pickBy, identity, isEmpty } from 'lodash';
import CustomValidations from './CustomValidations';
import Helper from '../utilities/utility';

class FormValidator {
  emptyDataSet = { data: [] };

  prepareFormObject =
    (fields, isDirty = false, isValid = false, metaData) => ({
      fields: { ...fields },
      refMetadata: metaData ? { ...metaData } : JSON.parse(JSON.stringify({ ...fields })),
      meta: {
        isValid,
        error: '',
        isDirty,
      },
      response: {},
    });

  pullValues = (e, data) => ({
    name: typeof data.name === 'undefined' ? e.target.name : data.name,
    value: typeof data.value === 'undefined' ? e.target.value : data.value,
  });

  pullValuesForPassword = (e, { name }) => ({
    name: name || 'password',
    value: e.password,
  });

  pullValuesForCangePassword = e => ({
    name: 'newPasswd',
    value: e.newPasswd,
  });

  convertValueByFieldType = (fieldType, element) => {
    let convertedValue;
    if (!isUndefined(element.value)) {
      switch (fieldType) {
        case 'string':
          convertedValue = element.value.toString();
          break;
        case 'integer':
          convertedValue = parseInt(element.value, 10);
          break;
        case 'float':
          convertedValue = parseFloat(element.value.toFixed(2), 10);
          break;
        default:
          convertedValue = element.value;
          break;
      }
    }
    return convertedValue;
  }

  onChange = (form, element, type, isDirty = true, checked = undefined) => {
    const currentForm = form;
    CustomValidations.loadCustomValidations(form);
    let customErrMsg = {};
    if (element && element.name) {
      element.value = this.convertValueByFieldType(currentForm.fields[element.name].fieldType, element);
      if (type === 'checkbox' || (Array.isArray(toJS(currentForm.fields[element.name].value)) && type !== 'dropdown')) {
        const index = currentForm.fields[element.name].value.indexOf(element.value);
        if (index === -1) {
          currentForm.fields[element.name].value.push(element.value);
        } else {
          currentForm.fields[element.name].value.splice(index, 1);
        }
      } else if (checked) {
        currentForm.fields[element.name].value = checked.value;
      } else {
        currentForm.fields[element.name].value = element.value;
      }
      customErrMsg = (currentForm.fields[element.name]
        && currentForm.fields[element.name].customErrors)
        ? currentForm.fields[element.name].customErrors : {};
    }
    const validation = new FormHandle(
      mapValues(currentForm.fields, f => f.value),
      mapValues(currentForm.fields, f => f.rule),
      customErrMsg,
    );
    currentForm.meta.isValid = validation.passes();
    currentForm.fields[element.name].isDirty = true;
    if (element && element.name) {
      // currentForm.fields[element.name].error = validation.errors.first(element.name);
      currentForm.fields[element.name].error = validation.errors.first(element.name)
        ? replace(
          validation.errors.first(element.name),
          element.name,
          currentForm.fields[element.name].label,
        ) : undefined;
    }
    currentForm.meta.isDirty = isDirty;
    return currentForm;
  };

  resetDirtyFields = (form) => {
    const currentForm = form;
    forEach(currentForm.fields, (field, key) => {
      if (Array.isArray(field)) {
        forEach(currentForm.fields[key], (v, k) => {
          forEach(currentForm.fields[key][k], (fie, ke) => {
            currentForm.fields[key][k][ke].isDirty = false;
          });
        });
      } else {
        currentForm.fields[key].isDirty = false;
      }
    });

    return currentForm;
  };

  validateForm = (form, params) => {
    const { isMultiForm, showErrors, resetDirtyFields } = { isMultiForm: false, showErrors: false, resetDirtyFields: false, ...params };
    CustomValidations.loadCustomValidations(form);
    let currentForm = form;
    let validation;
    if (!isMultiForm) {
      validation = new FormHandle(
        mapValues(currentForm.fields, f => f.value),
        mapValues(currentForm.fields, f => f.rule),
        {
          required: 'required',
          required_if: 'required',
        },
      );
    } else {
      const formData = this.ExtractFormValues(toJS(currentForm.fields));
      let formRules = this.ExtractFormRules(toJS(currentForm.fields));
      formRules = pickBy(formRules, identity);
      validation = new FormHandle(
        formData,
        formRules,
        {
          required: 'required',
        },
      );
    }
    currentForm.meta.isValid = validation.passes();
    if (validation.errorCount && showErrors) {
      const { errors } = validation.errors;
      map(errors, (error, key) => {
        const [err] = error;
        if (includes(key, '.')) {
          const field = key.split('.');
          if (field[0] === 'businessPlan') {
            currentForm.fields[field[0]].error = err;
          } else {
            const cusErr = get(currentForm.fields[field[0]][field[1]][field[2]], `customErrors.${[field[2]]}`);
            currentForm.fields[field[0]][field[1]][field[2]].error = cusErr || err;
            currentForm.meta.error = cusErr || err;
          }
        } else {
          currentForm.fields[key].error = err;
        }
      });
    } else if (!showErrors && !isMultiForm) {
      forEach(currentForm.fields, (field, key) => {
        currentForm.fields[key].error = undefined;
      });
    }
    if (resetDirtyFields) {
      currentForm = this.resetDirtyFields(currentForm);
    }
    return currentForm;
  };

  onArrayFieldChange =
    (form, element, formName = null, formIndex = -1, type, checked = undefined) => {
      const currentForm = form;
      CustomValidations.loadCustomValidations(form);
      let currentFormRelative;
      let fieldName = element.name;
      let customErrMsg = {};
      if (formIndex > -1 && formName) {
        currentFormRelative = currentForm.fields[formName][formIndex];
        fieldName = `${formName}.${formIndex}.${element.name}`;
      } else if (formName) {
        currentFormRelative = currentForm.fields[formName];
        fieldName = `${formName}.${element.name}`;
      } else {
        currentFormRelative = currentForm.fields;
      }
      if (element && element.name) {
        element.value = this.convertValueByFieldType(currentFormRelative[element.name].fieldType, element);
        if (type === 'checkbox' || (Array.isArray(toJS(currentFormRelative[element.name].value)) && type !== 'dropdown')) {
          const index = currentFormRelative[element.name]
            .value.indexOf(element.value);
          if (index === -1) {
            currentFormRelative[element.name].value.push(element.value);
          } else {
            currentFormRelative[element.name].value.splice(index, 1);
          }
        } else if (checked) {
          currentFormRelative[element.name].value = checked.value;
        } else {
          currentFormRelative[element.name].value = element.value;
        }
        customErrMsg = (currentFormRelative[element.name]
          && currentFormRelative[element.name].customErrors)
          ? currentFormRelative[element.name].customErrors : {};
      }
      const formData = this.ExtractFormValues(toJS(currentForm.fields));
      const formRules = pickBy(this.ExtractFormRules(toJS(currentForm.fields)), identity);
      const validation = new FormHandle(
        formData,
        formRules,
        customErrMsg,
      );
      currentForm.meta.isValid = validation.passes();
      currentFormRelative[element.name].isDirty = true;
      if (element && element.name) {
        currentFormRelative[element.name].error = validation.errors.first(fieldName);
      }
      return currentForm;
    };

  ExtractValues = fields => mapValues(fields, f => f.value);

  ExtractFormValues = fields => mapValues(fields, f => (isArray(f) ? toArray(mapValues(f, d => mapValues(d, s => s.value)))
    : f.value));

  ExtractFormRules = fields => reduce(mapValues(fields, (f, key) => (isArray(f) ? mapKeys(mapValues(f[0], k => k.rule), (s, v) => `${key}.*.${v}`)
    : { [key]: f.rule })), (a, b) => Object.assign(a, b));

  resetFormData = (form, targetedFields) => {
    const currentForm = form;
    const fieldsToReset = (targetedFields && targetedFields.length && targetedFields)
      || Object.keys(currentForm.fields);
    fieldsToReset.map((field) => {
      if (Array.isArray(toJS(currentForm.fields[field].value))) {
        currentForm.fields[field].value = [];
        if (currentForm.fields[field].objType === 'fileUpload') {
          currentForm.fields[field].id = [];
          currentForm.fields[field].fileName = [];
          currentForm.fields[field].fileSize = [];
          currentForm.fields[field].fileType = [];
          currentForm.fields[field].base64String = [];
        }
      } else if (Array.isArray(toJS(currentForm.fields[field]))) {
        const arr = toJS(currentForm.fields[field]);
        arr.map((item, index) => {
          const fieldKeys = Object.keys(currentForm.fields[field][index]);
          fieldKeys.map((f) => {
            if (Array.isArray(toJS(currentForm.fields[field][index][f].value))) {
              currentForm.fields[field][index][f].value = currentForm.fields[field][index][f].defaultValue || [];
              if (currentForm.fields[field][index][f].objType === 'fileUpload') {
                currentForm.fields[field][index][f].id = [];
                currentForm.fields[field][index][f].fileName = [];
                currentForm.fields[field][index][f].fileSize = [];
                currentForm.fields[field][index][f].fileType = [];
                currentForm.fields[field][index][f].base64String = [];
              }
            } else {
              currentForm.fields[field][index][f].value = currentForm.fields[field][index][f].defaultValue || '';
              if (currentForm.fields[field][index][f].objType === 'fileUpload') {
                currentForm.fields[field][index][f].id = '';
                currentForm.fields[field][index][f].fileName = '';
                currentForm.fields[field][index][f].fileSize = '';
                currentForm.fields[field][index][f].fileType = '';
                currentForm.fields[field][index][f].base64String = '';
              }
            }
            return true;
          });
          return true;
        });
        currentForm.fields[field].splice(1);
      } else {
        currentForm.fields[field].value = currentForm.fields[field].defaultValue || '';
        if (currentForm.fields[field].objType === 'fileUpload') {
          currentForm.fields[field].id = '';
          currentForm.fields[field].fileName = '';
          currentForm.fields[field].fileSize = '';
          currentForm.fields[field].fileType = '';
          currentForm.fields[field].base64String = '';
        }
      }
      currentForm.fields[field].error = undefined;
      currentForm.response = {};
      return true;
    });
    currentForm.meta.isValid = false;
    currentForm.meta.error = '';
    return currentForm;
  }

  setAddressFields = (place, form) => {
    const currentForm = form;
    const data = Helper.gAddressClean(place);
    if (isEmpty(data)) {
      return false;
    }
    if (currentForm.fields.street) {
      this.onChange(currentForm, { name: 'street', value: data.residentialStreet });
    } else if (currentForm.fields.residentialStreet) {
      this.onChange(currentForm, { name: 'residentialStreet', value: data.residentialStreet });
    }
    if (currentForm.fields.state) {
      this.onChange(currentForm, { name: 'state', value: data.state || '' });
    }
    if (currentForm.fields.city) {
      this.onChange(currentForm, { name: 'city', value: data.city || '' });
    }
    if (currentForm.fields.zip) {
      this.onChange(currentForm, { name: 'zip', value: data.zip || '' });
    }
    if (currentForm.fields.lat) {
      this.onChange(currentForm, { name: 'lat', value: data.lat || '' });
    }
    if (currentForm.fields.lng) {
      this.onChange(currentForm, { name: 'lng', value: data.lng || '' });
    }
  };

  setAddressFieldsIndex = (place, form, subForm = 'data', index, action = false, US_STATES = false) => {
    const currentForm = form;
    const data = Helper.gAddressClean(place);
    let stateValue = '';
    if (US_STATES) {
      const state = US_STATES.find(s => s.text === data.state.toUpperCase());
      stateValue = state ? state.key : '';
    }

    if (index > -1 && subForm && currentForm.fields[subForm][index].street) {
      this.onArrayFieldChange(currentForm, { name: 'street', value: data.residentialStreet }, subForm, index);
    } else if (subForm && currentForm.fields[subForm].street) {
      this.onArrayFieldChange(currentForm, { name: 'street', value: data.residentialStreet }, subForm);
    } else if (currentForm.fields.state) {
      this.onArrayFieldChange(currentForm, { name: 'street', value: data.residentialStreet });
    } else {
      this.onArrayFieldChange(currentForm, { name: 'residentialStreet', value: data.residentialStreet }, subForm, index);
    }
    this.onArrayFieldChange(currentForm, { name: 'state', value: action ? stateValue : data.state }, subForm, index);
    this.onArrayFieldChange(currentForm, { name: 'city', value: data.city }, subForm, index);
    this.onArrayFieldChange(currentForm, { name: 'zip', value: data.zip }, subForm, index);
  }

  resetFormToEmpty = metaData => this.prepareFormObject(metaData || this.emptyDataSet);

  getMetaData = form => form.refMetadata;

  getRefFromObjRef = (objRef, data) => {
    let tempRef = false;
    objRef.split('.').map((k) => {
      tempRef = !tempRef ? data[k] : tempRef[k];
      return tempRef;
    });
    return tempRef;
  }

  addMoreRecordToSubSection = (form, key, count = 1, defaultBlank = false) => {
    const currentForm = form;
    currentForm.fields[key] = currentForm.fields[key] && currentForm.fields[key][0]
      ? this.addMoreFields(currentForm.fields[key], count) : (
        defaultBlank ? currentForm.refMetadata[key] : []
      );
    currentForm.meta = { ...currentForm.meta, isValid: false };
    return currentForm;
  }

  addMoreFields = (fields, count = 1) => {
    const arrayData = [...toJS(fields)];
    for (let i = count; i > 0; i -= 1) {
      arrayData.push(this.resetMoreFieldsObj(JSON.parse(JSON.stringify({ ...fields[0] }))));
    }
    return arrayData;
  }

  resetMoreFieldsObj = (formFields) => {
    const fields = formFields;
    Object.keys(fields).forEach((key) => {
      if (fields[key] && Array.isArray(toJS(fields[key]))) {
        fields[key] = this.resetMoreFieldsObj(fields[key]);
      } else if (fields[key].objType === 'fileUpload') {
        fields[key] = {
          ...fields[key],
          ...{
            value: '', id: '', fileSize: '', fileType: '', fileName: '', base64String: '', showLoader: false, confirmModal: false, error: undefined,
          },
        };
      } else {
        fields[key].value = fields[key].hasOwnProperty('defaultValue') ? fields[key].defaultValue : '';
      }
    });
    return fields;
  };

  setDataForLevel = (refFields, data, keepAtLeastOne) => {
    const fields = { ...refFields };
    Object.keys(fields).map((key) => {
      try {
        if (fields[key] && Array.isArray(toJS(fields[key]))) {
          const tempRef = toJS(fields[key])[0].objRef
            ? this.getRefFromObjRef(fields[key][0].objRef, data) : false;
          if ((data && data[key] && data[key].length > 0)
            || (tempRef && tempRef[key] && tempRef[key].length > 0)) {
            const addRec = ((data[key] && data[key].length)
              || (tempRef[key] && tempRef[key].length)) - toJS(fields[key]).length;
            fields[key] = this.addMoreFields(fields[key], addRec);
            (data[key] || tempRef[key]).forEach((record, index) => {
              fields[key][index] = this.setDataForLevel(
                fields[key][index],
                (data[key] && data[key][index]) || (tempRef[key] && tempRef[key][index]),
                keepAtLeastOne,
              );
            });
          } else if (!keepAtLeastOne) {
            fields[key] = [];
          }
        } else if (fields[key].objRef) {
          const tempRef = this.getRefFromObjRef(fields[key].objRef, data);
          if (fields[key].objType === 'fileUpload') {
            const refKey = fields[key].keyAlias || key;
            if (tempRef[refKey] && Array.isArray(toJS(tempRef[refKey]))
              && fields[key] && Array.isArray(toJS(fields[key].value))) {
              if (tempRef[refKey].length > 0) {
                tempRef[refKey].map((item) => {
                  fields[key].value.push(item.url);
                  fields[key].id.push(item.id);
                  fields[key].fileName.push(item.fileName);
                  return false;
                });
              } else {
                fields[key].value = [];
                fields[key].id = [];
                fields[key].fileName = [];
              }
            } else {
              const refKey = fields[key].keyAlias || key;
              fields[key].value = Array.isArray(toJS(tempRef[refKey]))
                ? tempRef[refKey][0].url : tempRef[refKey].url;
              fields[key].id = Array.isArray(toJS(tempRef[refKey]))
                ? tempRef[refKey][0].id : tempRef[refKey].id;
              fields[key].fileName = Array.isArray(toJS(tempRef[refKey]))
                ? tempRef[refKey][0].fileName : tempRef[refKey].fileName;
            }
          } else if (fields[key].objType === 'DATE') {
            fields[key].value = tempRef[key] ? Helper.formatDateObjectToLocal(tempRef[key]) : '';
          } else {
            fields[key].value = tempRef[fields[key].keyAlias || key];
          }
        } else if (key === 'value') {
          fields[key] = data && typeof data === 'string' ? data : data[key];
        } else if (fields[key].objType === 'fileUpload') {
          const refKey = fields[key].keyAlias || key;
          if (data[refKey] && Array.isArray(toJS(data[refKey]))
            && fields[key] && Array.isArray(toJS(fields[key].value))) {
            if (data[refKey].length > 0) {
              data[refKey].map((item) => {
                fields[key].value.push(item.url);
                fields[key].id.push(item.id);
                fields[key].fileName.push(item.fileName);
                return false;
              });
            } else {
              fields[key].value = [];
              fields[key].id = [];
              fields[key].fileName = [];
            }
          } else {
            const refKey = fields[key].keyAlias || key;
            fields[key].value = data && typeof data === 'string' ? data : Array.isArray(toJS(data[refKey])) ? data[refKey][0].url : data[refKey].url;
            fields[key].fileName = data && typeof data === 'string' ? data : Array.isArray(toJS(data[refKey])) ? data[refKey][0].fileName : data[refKey].fileName;
            fields[key].id = data && typeof data === 'string' ? data : Array.isArray(toJS(data[refKey])) ? data[refKey][0].id : data[refKey].id;
          }
        } else if (fields[key].objType === 'DATE') {
          const aliasKey = fields[key].keyAlias || key;
          fields[key].value = data && typeof data === 'string' ? Helper.formatDateObjectToLocal(data) : data[aliasKey] ? Helper.formatDateObjectToLocal(data[aliasKey]) : '';
        } else {
          const aliasKey = fields[key].keyAlias || key;
          fields[key].value = data && typeof data === 'object' ? (data[aliasKey] !== null && data[aliasKey] !== '' && data[aliasKey] !== undefined) ? data[aliasKey] : fields[key].value : data || fields[key].value;
        }
        if (fields[key].refSelector) {
          fields[key].refSelectorValue = fields[key].value !== '';
          if (fields[key].value !== undefined) {
            fields[fields[key].refSelector].value = (fields[key].value !== null && fields[key].value !== '');
          }
        }
      } catch (e) {
        // do nothing
      }
      return fields;
    });
    return fields;
  };

  setFormData = (form, dataSrc, ref, keepAtLeastOne = true) => {
    let currentForm = form;
    const data = ref ? this.getRefFromObjRef(ref, dataSrc) : dataSrc;
    currentForm.fields = this.setDataForLevel(currentForm.fields, data, keepAtLeastOne);
    return this.resetDirtyFields(currentForm);
  };

  evaluateObjectRef = (objRef, inputData, key, value) => {
    const tempRef = inputData;
    set(tempRef, `${objRef}.${key}`, value);
    return tempRef;
  };

  evalFileUploadObj = (fileData) => {
    const fileObj = toJS(fileData);
    let fileObjOutput;
    if (Array.isArray(fileObj.value)) {
      fileObjOutput = map(fileObj.value, (file, index) => ({
        id: fileObj.id[index] || Helper.generateUUID(),
        url: file,
        fileName: fileObj.fileName[index],
      }));
    } else {
      fileObjOutput = fileObj.value ? {
        id: fileObj.id || Helper.generateUUID(),
        url: fileObj.value,
        fileName: fileObj.fileName,
      } : null;
    }
    return fileObj.toJson ? JSON.stringify(fileObjOutput) : fileObjOutput;
  }

  // evalFileObj = (fileData) => {
  //   const fileObj = toJS(fileData);
  //   let fileObjOutput;
  //   if (Array.isArray(fileObj.fileId)) {
  //     fileObjOutput = map(fileObj.fileId, (file, index) => ({ fileId: file, fileName: fileObj.value[index] }));
  //   } else {
  //     fileObjOutput = { fileId: fileData.fileId ? fileData.fileId : '', fileName: fileData.value ? fileData.value : '' };
  //   }
  //   return fileObjOutput;
  // }

  evalDateObj = date => moment(new Date(date)).toISOString();

  evaluateFormData = (fields, params) => {
    const { disableDefaultValue, onlyDirtyFields } = { disableDefaultValue: false, onlyDirtyFields: false, ...params };
    let inputData = {};
    map(fields, (ele, key) => {
      try {
        if (!fields[key].skipField) {
          const records = toJS(fields[key]);
          let reference = false;
          if (fields[key] && Array.isArray(records)) {
            if (fields[key] && fields[key].length > 0) {
              const arrObj = [];
              records.forEach((field) => {
                // const dirtyFieldCheck = onlyDirtyFields ? field.isDirty : true;
                // if (dirtyFieldCheck) {
                let arrayFieldsKey = {};
                let arrayFields = {};
                map(field, (eleV, keyRef1) => {
                  if (!eleV.skipField) {
                    let reference2 = false;
                    let reference2Val = field[keyRef1].value;
                    if (eleV.objRefOutput && !reference) {
                      reference = eleV.objRefOutput;
                    }
                    if (eleV.objRefOutput2 && !reference2) {
                      reference2 = eleV.objRefOutput2;
                    }
                    else if (field[keyRef1].objType && field[keyRef1].objType === 'fileUpload') {
                      reference2Val = this.evalFileUploadObj(field[keyRef1]);
                    } else if (field[keyRef1].objType && field[keyRef1].objType === 'DATE') {
                      reference2Val = this.evalDateObj(field[keyRef1].value);
                    }
                    if (reference2) {
                      arrayFields = this.evaluateObjectRef(reference2, arrayFields, [field[keyRef1].keyAlias || keyRef1], reference2Val);
                    } else {
                      arrayFields = { ...arrayFields, [field[keyRef1].keyAlias || keyRef1]: reference2Val };
                    }
                    arrayFieldsKey = { ...arrayFieldsKey, ...arrayFields };
                  }
                });
                arrObj.push(arrayFieldsKey);
                if (reference) {
                  inputData = this.evaluateObjectRef(reference, inputData, [key], arrObj);
                } else {
                  inputData = { ...inputData, [key]: arrObj };
                }
                // }
              });
            }
          } else if ((onlyDirtyFields && !fields[key].alwaysEvaluate) ? fields[key].isDirty : true) {
            if (fields[key].objRefOutput && !reference) {
              reference = fields[key].objRefOutput;
            }
            let objValue = disableDefaultValue ? fields[key].value : (fields[key].value === '' || (fields[key].value === undefined && fields[key].refSelector === undefined)) && fields[key].defaultValue ? fields[key].defaultValue
              : fields[key].value;
            if (fields[key].objType && fields[key].objType === 'DATE') {
              objValue = this.evalDateObj(fields[key].value);
            } else if (fields[key].objType && fields[key].objType === 'fileUpload') {
              objValue = this.evalFileUploadObj(fields[key]);
            }
            if (reference) {
              inputData = this.evaluateObjectRef(reference, inputData, [fields[key].keyAlias || key], objValue);
            } else if (fields[key].refSelector !== undefined
              && fields[fields[key].refSelector].value !== undefined) {
              let val = '';
              if (fields[fields[key].refSelector].value) {
                if (fields[key].value !== '' && fields[key].value !== undefined && fields[key].value !== null) {
                  val = objValue;
                } else {
                  val = fields[key].defaultValue;
                }
              }
              inputData = { ...inputData, [fields[key].keyAlias || key]: fields[key].sanitize ? Helper.sanitizeContent(val) : val };
            } else {
              inputData = { ...inputData, [fields[key].keyAlias || key]: fields[key].sanitize ? Helper.sanitizeContent(objValue) : objValue };
            }
          }
        }
      } catch (e) {
        window.logger({ params: e });
      }
    });
    return inputData;
  }
}
export default new FormValidator();
