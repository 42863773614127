import React from 'react';
import { get } from 'lodash';
import moment from 'moment';
import { observer, inject } from 'mobx-react';
import { withRouter, Link } from 'react-router-dom';
import { Container, Header, Button, Icon, Divider, Menu, List } from 'semantic-ui-react';
import { InlineLoader } from '../../../theme/shared';
import PdfView from './component/PdfView';
import OrderTableView from './component/OrderTableView';

class OrderDetails extends React.Component {
  state = { loading: false, pageNumber: 1, activeItem: '', pdfLoading: false }
  showTableView = false;
  showPDFView = false; 
  pdfList = [];
  renameTab = false;

  async componentDidMount() {
    try {
      this.setState({ loading: true });
      const { kitStore, match } = this.props; //
      await kitStore.getOrderDetails(match.params.id, match.params.pdfId);
      const { orderDetails } = this.props.kitStore;
      // const panelPDF = get(kitStore, 'orderDetails.panelAttachmentsInfo[0]');
      const brandMetaDetail = JSON.parse(get(kitStore, 'orderDetails.brand.meta'));
      const PortalViewSetting = get(brandMetaDetail, 'resultPage');

      // const resultPdfOrder = get(this.props.kitStore.panelMeta, 'pdfOrder');

      if (get(orderDetails, 'resultsPdfAttachmentsInfo[0]')) {
        this.pdfList = (get(orderDetails, 'resultsPdfAttachmentsInfo') || []).map(o => ({ id: o.fileId, fileName: o.fileName ? ((o.fileName).split('.'))[0] : '', type: 'order' }));      
        this.renameTab = PortalViewSetting === 'PDF_ONLY' || PortalViewSetting === 'PDF_OR_TABLE' ? this.pdfList[0].id : false;
      }
  
      if (get(orderDetails, 'panelAttachmentsInfo[0]')) {
        const panelPdfList = (get(orderDetails, 'panelAttachmentsInfo') || []).map(p => ({ id: p.fileId, fileName: p.fileName ? ((p.fileName).split('.'))[0] : '' }));
        // const panelPdfList = (get(orderDetails, 'panelAttachmentsInfo') || []).map((p, k) => ({ id: p.fileId, fileName: `Panel-${k + 1}` }));
        // if (resultPdfOrder && resultPdfOrder === 'FIRST') {
        //   this.pdfList = [...this.pdfList, ...panelPdfList];
        // } else {
        //   this.pdfList = [...panelPdfList, ...this.pdfList];
        // }
        this.pdfList = [...this.pdfList, ...panelPdfList];
      }

      console.log('------------------------');
      console.log(this.pdfList);
      console.log('------------------------');
      
      switch(PortalViewSetting) {
        case 'PDF_ONLY': this.showPDFView = true; break;
        case 'TABLE_ONLY': this.showTableView = true; break;
        case 'PDF_OR_TABLE': this.pdfList.length > 0 ? this.showPDFView = true : this.showTableView = true; break;
        case 'PDF_AND_TABLE': this.showPDFView = true; this.showTableView = true; break;
        default: this.showPDFView = true; this.showTableView = true; break;
      }

      if (this.showTableView) {
        this.setState({ loading: false, activeItem: '1' });
      } else {
        await this.props.kitStore.getDownloadLink(get(kitStore.orderDetails, 'orderId'), get(this.pdfList, '[0].id'));
        this.setState({ loading: false, activeItem: this.pdfList[0].id });        
      }
    } catch (e) {
      this.setState({ loading: false });
    }
  }

  getDateFormatted = date => (date ? moment(date).format('MM/DD/YYYY') : 'Pending');

  handleItemClick = async (e, { id, orderId }) => {
    try {
      this.setState({ activeItem: id, pdfLoading: true });
      await this.props.kitStore.getDownloadLink(orderId, id);
      this.setState({ pdfLoading: false });
    } catch {
      this.setState({ pdfLoading: false });
    }
  }

  render() {
    const { orderDetails, orderPdfDetails, panelMeta, downloadAllPdf } = this.props.kitStore;
    // const resultPdfOrder = get(panelMeta, 'pdfOrder');
    let brandMetaDetail = get(orderDetails, 'brand.meta');
    // const reportPdf = (get(orderDetails, 'resultsPdfAttachmentsInfo[0]')) ? 
    //    (get(orderDetails, 'resultsPdfAttachmentsInfo') || []).map(o => ({ id: o.fileId, fileName: o.fileName ? ((o.fileName).split('.'))[0] : '', type: 'order' }))
    //    : [];
    const themeClass = get(brandMetaDetail, 'brandsTheme') || 'live-smart';

    try {
      if (get(orderDetails, 'brand.meta')) {
        brandMetaDetail = JSON.parse(get(orderDetails, 'brand.meta'));  
      }
    } catch (e) {
      console.log(e);
    }
    const PortalViewSetting = get(brandMetaDetail, 'resultPage', 'PDF_AND_TABLE');
    const isPdfView = (PortalViewSetting && (!['TABLE_ONLY'].includes(PortalViewSetting)) && get(orderPdfDetails, 'signedUrl'));
    const isEmptyPdfView = (PortalViewSetting && ['PDF_ONLY'].includes(PortalViewSetting) && !get(orderPdfDetails, 'signedUrl'))

    if (this.state.loading) {
      return (
        <InlineLoader />
      )
    }

    // if (get(orderDetails, 'resultsPdfInfo.fileId')) {
    //   pdfList = [{
    //     id: get(orderDetails, 'resultsPdfInfo.fileId'),
    //     fileName: 'Results',
    //     type: 'order',
    //   }]
    // }


    // const panelMeta = get(orderDetails, 'panel.meta') ? JSON.parse(get(orderDetails, 'panel.meta')) : {};

    const color = get(brandMetaDetail, 'titleTextColor') ? get(brandMetaDetail, 'titleTextColor') : themeClass === 'live-smart' ? '#170c76' : '#484d69'
    const borderColor = get(brandMetaDetail, 'headerColor') ? get(brandMetaDetail, 'headerColor') : themeClass === 'live-smart' ? '#170c76' : '#484d69'

    return (
      <section className="full-section d-flex jcc">
        <Container>
          <div className="pdf-header">
            <Button secondary style={{ borderColor: borderColor }} as={Link} to="/dashboard"><Icon name="left arrow" /> Back to Orders</Button>
            <Header as="h1" style={{ 'color': color}}>
              {get(panelMeta, 'panelName') || get(orderDetails, 'panel.name')} {get(panelMeta, 'panelPrefix') ? <span>({get(panelMeta, 'panelPrefix')})</span> : ''}
            </Header>
          </div>   
          <div className="order-id-wrap">
            <p>Order Id</p>
            <p>{get(orderDetails, 'orderId')}</p>            
          </div>
          <div className='order-details-header'>
            <List horizontal relaxed className="order-meta">
              <List.Item>
                <List.Content>
                  <span>Order ID</span>
                  {get(orderDetails, 'orderId')}
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <span>Lab</span>
                  {get(orderDetails, 'lab.name')}
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <span>Physician</span>
                  Dr. {`${get(orderDetails, 'releasedAssignedMd.firstName')} ${get(orderDetails, 'releasedAssignedMd.lastName')}`}
                </List.Content>
              </List.Item>
              {/* <List.Item>
                <List.Content>
                  <span>Result Date</span>
                  {this.getDateFormatted(get(orderDetails, 'resultsPdfInfo.createdAt'))}
                </List.Content>
              </List.Item> */}
            </List>
            {(get(orderDetails, 'resultsPdfAttachmentsInfo') || []).map(o => ({ id: o.fileId, fileName: o.fileName ? ((o.fileName).split('.'))[0] : '', type: 'order' })).length > 0 && (
            <Button 
              primary 
              as={Link} 
              onClick={() => downloadAllPdf(orderDetails.id)}
              style={{ 'backgroundColor': get(brandMetaDetail, 'headerColor') ? get(brandMetaDetail, 'headerColor') : themeClass === 'live-smart' ? '#170c76' : '#484d69'}}
            >Lab Result PDF <Icon name="download circle" className="right-side" /></Button>
            )}
          </div>

          <Divider hidden />
          <Menu tabular>
            {this.showTableView && (
            <Menu.Item
                  key="result"
                  name="Result"
                  id="1"
                  active={this.state.activeItem === '1'}
                  orderId={get(orderDetails, 'orderId')}
                  onClick={this.handleItemClick}
                />
            )}
            {this.showPDFView && this.pdfList.map(p => (
              <Menu.Item
                  key={p.id}
                  name={this.renameTab === p.id ? 'Result' : p.fileName}
                  id={p.id}
                  active={this.state.activeItem === p.id}
                  orderId={get(orderDetails, 'orderId')}
                  onClick={this.handleItemClick}
                />
              )) 
            }
          </Menu>
          <div className={isEmptyPdfView ? '' : `pdf-wrapper ${!isPdfView ? 'centered' : 'has-pdf'}`}>
            {isPdfView ?
              this.state.activeItem !== '1' ?
                <PdfView loading={this.state.pdfLoading} file={get(orderPdfDetails, 'signedUrl')} />
                :
                this.showTableView && <OrderTableView data={orderDetails} />
              : isEmptyPdfView ?
                <InlineLoader text="Documents Not Available" />
               :
                this.showTableView && <OrderTableView data={orderDetails} /> 
            }
          </div>
        </Container>
      </section>
    );
  }
}

export default inject('kitStore')(withRouter(observer(OrderDetails)));