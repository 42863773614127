import React, { Component } from 'react';
import { get } from 'lodash';
import { withRouter, Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Form, Header, Icon } from 'semantic-ui-react';
import formHOC from '../../../theme/form/formHOC';


const metaInfo = {
  store: 'authStore',
  form: 'OTP_FRM',
  resetErrors: true,
  resetForm: true,
};

class VerifyOtp extends Component {
  state = { loading: false, timer: 30 }
  onSubmit = async () => {
    try {
      this.setState({ loading: true });
      const { auth } = this.props.authStore;
      const response = await auth({ otp: true });
      if (['SIGNUP'].includes(get(response, 'state')) && get(response, 'status') === 'COMPLETE') {
        this.props.stepHandler('USER_DETAILS');
      }
      this.setState({ loading: false });
    } catch {
      this.setState({ loading: false });
    }
  }

  resendOtp = (e) => {
    e.preventDefault();
    if (this.state.timer === 30) {
      const updateTimer = () => {
        this.setState((prevState) => ({
          timer: prevState.timer - 1
        }));
        if (this.state.timer === 0) {
          clearInterval(timerInterval);
          this.setState({ timer: 30 })
        }
      }
      const timerInterval = setInterval(updateTimer, 1000);
    }
    this.props.authStore.auth({ otp: true });
  }

  render() {
    const { smartElement, authStore } = this.props;
    const { email } = authStore.SIGNUP_FRM.fields;
    const otpDisabled = this.state.timer < 30;
    const { brandMeta } = authStore;
    const themeClass = get(brandMeta, 'brandsTheme') || 'live-smart';
    const color = get(brandMeta, 'headerColor') ? get(brandMeta, 'headerColor') : themeClass === 'live-smart' ? '#170c76' : '#484d69';
    return (
      <>
        <Header 
          as="h2" 
          className="mb-30"
          style={{ 'color': get(brandMeta, 'titleTextColor') ? get(brandMeta, 'titleTextColor') : themeClass === 'live-smart' ? '#170c76' : '#484d69'}}
        >
          Please check your email for 6 digit security code.
          We sent your code to {email.value}.
        </Header>
        <Form error onSubmit={this.onSubmit}>
          {smartElement.CodeInput('code')}
          {smartElement.Errors()}
          <div className="center-align mt-30">
            {smartElement.Button({ color, content: <><Icon name="chevron circle left" /> Back</>, type: 'button', disabled: this.state.loading || false, onClick: () => this.props.stepHandler('EMAIL_PASS') })}
            {smartElement.Button({ color, content: <>Next <Icon name="chevron circle right" /></>, loading: this.state.loading })}
          </div>
        </Form>
        <p className="center-align form-link mt-20">Didn’t get the code?{otpDisabled ? '  Resend in' : <Link to='/' onClick={e => this.resendOtp(e)}>  Resend</Link>} {this.state.timer < 30 && <span>{this.state.timer}</span>}</p>
      </>
    );
  }
}
export default withRouter(formHOC(observer(VerifyOtp), metaInfo));
