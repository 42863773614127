import React from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Form, Icon } from 'semantic-ui-react';
import { get } from 'lodash';
import formHOC from '../../../theme/form/formHOC';

const metaInfo = {
  store: 'authStore',
  form: 'RESET_PASS_FRM',
};

function ResetPassword(props) {
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await props.authStore.forgotPasswordSubmit();
      if (res) {
        props.history.push('/login');
      }
    } catch(e) {
      window.logger({ params: e });
    }
  }

  const { smartElement, stepHandler, authStore } = props;
  const { currentScore, loading, brandMeta } = authStore;
  const themeClass = get(brandMeta, 'brandsTheme') || 'live-smart';
  const color = get(brandMeta, 'headerColor') ? get(brandMeta, 'headerColor') : themeClass === 'live-smart' ? '#170c76' : '#484d69';
  return (
    <section className="full-section d-flex aic jcc">
      <div className="auth-form-wrap">
        <p className="center-align mb-30">
          Enter verification code and create a new password.
        </p>
        <Form error onSubmit={onSubmit}>
          {smartElement.Input('confirmationCode', { size: 'large' })}
          {smartElement.FormPasswordStrength('password', { addonClasses: 'large', type: 'password', togglePassword: true })}
          {smartElement.Input('verify', { type: 'password', size: 'large', togglePassword: true })}
          {smartElement.Errors()}
          <div className="mt-30 center-align">
            {smartElement.Button({ color, type: 'button', secondary: true, content: <><Icon name="chevron circle left" /> Back</>, disabled: loading || false, onClick: () => stepHandler('FORGOT_PASS') })}
            {smartElement.Button({ color, content: 'Set New Password', addOnDisabled: currentScore < 2 })}
          </div>
        </Form>
      </div>
    </section>
  );
}
export default withRouter(formHOC(observer(ResetPassword), metaInfo));
