import React from 'react';
import { observer, inject } from 'mobx-react';
import { get, pick } from 'lodash';
import { Button, Message, Popup, Icon, Form, Responsive, Confirm } from 'semantic-ui-react';
import { ListErrors, MessageList } from '../../theme/shared/index';
import OtpInput from 'react-otp-input';
import {
  FormInput, FormPasswordStrength, FormDropDown, FormCheckBoxOrRadio, FormTextarea, PhoneNumber, MaskedInput,
} from '.';
import { Utilities as Utils } from '../../helper';
import Address from './src/Address';
import ImageCropper from './src/ImageCropper';
import { Image64 } from '../../theme/shared/index';

function formHoc(WrappedComponent, metaInfo) {
  return inject(metaInfo.store, 'uiStore')(observer((class extends React.Component {
    constructor(props) {
      super(props);
      if (metaInfo.resetForm) {
        this.props[metaInfo.store].resetForm(metaInfo.form);
      }
      if (metaInfo.resetErrors) {
        this.props.uiStore.setErrors(undefined);
      }
      if (metaInfo.formSetObj) {
        const { computed, ref, keepAtLeastOne, validateForm, isJson, jsonRef } = metaInfo.formSetObj;
        let data = this.props[metaInfo.store][computed];
        if (isJson) {
          let meta = get(this.props[metaInfo.store][computed], jsonRef) || null;
          meta = JSON.parse(meta);
          data = meta;
        }
        this.props[metaInfo.store].setFormData(metaInfo.form, data, ref, keepAtLeastOne, validateForm);
      }
      this.fieldsData = this.props[metaInfo.store][metaInfo.form].fields;
    }

    Message = (props) => (
      get(props, 'messages') && props.messages.length
      && (
        <MessageList messages={get(props, 'messages')} header={get(props, 'header')} />
      )
    )

    Errors = (props) => {
      return (
        <Message error className="mt-30">
          <ListErrors errors={get(props, 'errors') || [this.props.uiStore.errors]} />
        </Message>
      )
    };

    FormValid = () => this.props[metaInfo.store][metaInfo.form].meta.isValid;

    Button = (props) => {
      // const formDisabled = ['SIGNUP_FRM'].includes(metaInfo.form) ? !(this.props[metaInfo.store].currentScore > 1 && this.props[metaInfo.store][metaInfo.form].meta.isValid) : !this.props[metaInfo.store][metaInfo.form].meta.isValid;
      const formDisabled = (get(props, 'subForm') || get(props, 'subForm') === 0) ? !this.props[metaInfo.store][metaInfo.form][get(props, 'subForm')].meta.isValid : !this.props[metaInfo.store][metaInfo.form].meta.isValid;
      const loading = get(props, 'loading') !== undefined ? get(props, 'loading') : this.props[metaInfo.store].loading;
      let disabled = get(props, 'disabled') !== undefined ? get(props, 'disabled') : formDisabled;
      disabled = get(props, 'addOnDisabled') !== undefined ? (disabled || get(props, 'addOnDisabled')) : disabled;
      const disableFluid = get(props, 'disableFluid');
      const color = get(props, 'color') ? get(props, 'color') : get(props, 'themeClass') !== 'live-smart' ? '#484d69' : '#170c76';

      return (
        <Button
          fluid={disableFluid}
          primary
          className={`${get(props, 'classes')}`}
          content="Submit"
          loading={loading}
          disabled={disabled || loading}
          style={{'backgroundColor' : color}}
          {...props}
        />
      );
    }

    Input = (name, props) => {
      const fieldData = get(props, 'fielddata') || ((get(props, 'multiForm') && get(props, 'multiForm[1]') ? this.props[metaInfo.store][props.multiForm[0]].fields[props.multiForm[1]][props.multiForm[2]][name] : this.fieldsData[name]));
      return (
        <FormInput
          name={name}
          key={name}
          type="text"
          format={get(fieldData, 'format')}
          fielddata={fieldData}
          onblur={get(props, 'handleBlur') || false}
          changed={(e, result) => this.props[metaInfo.store].formChange(e, result, (get(props, 'multiForm') || metaInfo.form))}
          label={get(props, 'label') || false}
          {...props}
        />
      );
    }

    PhoneNumber = (name, props) => {
      const fieldData = get(props, 'fielddata') || ((get(props, 'multiForm') && get(props, 'multiForm[1]') ? this.props[metaInfo.store][props.multiForm[0]].fields[props.multiForm[1]][props.multiForm[2]][name] : this.fieldsData[name]));
      return (
        <PhoneNumber
          {...props}
          name={name}
          label={get(props, 'label')}
          fielddata={fieldData}
          onblur={get(props, 'handleBlur') || false}
          changed={(value) => this.props[metaInfo.store].phoneChange(name, value, (get(props, 'multiForm') || metaInfo.form))}
        />
      );
    }

    CodeInput = (name, props) => {
      const fieldData = get(props, 'fielddata') || ((get(props, 'multiForm') && get(props, 'multiForm[1]') ? this.props[metaInfo.store][props.multiForm[0]].fields[props.multiForm[1]][props.multiForm[2]][name] : this.fieldsData[name]));
      return (
        <OtpInput
          numInputs={get(props, 'numInputs') || 6}
          isInputNum
          value={fieldData.value}
          containerStyle="otp-field"
          onChange={otp => this.props[metaInfo.store].formChange({ name, value: otp }, { name, value: otp }, metaInfo.form)}
          {...props}
        />
      );
    }

    Address = props => {
      const fieldData = get(props, 'multiForm') && get(props, 'multiForm[1]') ? pick(this.props[metaInfo.store][props.multiForm[0]].fields[props.multiForm[1]][props.multiForm[2]], ['street', 'city', 'state', 'streetTwo', 'zip']) : pick(this.props[metaInfo.store][metaInfo.form].fields, ['street', 'city', 'state', 'streetTwo', 'zip']);
      return (
        <Address
          fielddata={fieldData}
          changed={(e, result) => this.props[metaInfo.store].formChange(e, result, (get(props, 'multiForm') || metaInfo.form))}
          populateData={place => this.props[metaInfo.store].setAddressFields(place, (get(props, 'multiForm') || metaInfo.form))}
          maskChanged={(values, name) => this.props[metaInfo.store].maskChange(values, name, (get(props, 'multiForm') || metaInfo.form))}
          {...props}
        />
      )
    }

    // GoogleAutoComplete = (name, props) => {
    //   const fieldData = get(props, 'fielddata') || ((get(props, 'multiForm') ? this.props[metaInfo.store][props.multiForm[0]].fields[props.multiForm[1]][props.multiForm[2]][name] : this.fieldsData[name]));
    //   return (
    //     <AutoComplete
    //       name={name}
    //       fielddata={fieldData}
    //       onPlaceSelected={place => this.props[metaInfo.store].setAddressFields(place, metaInfo.form)}
    //       componentRestrictions={{ country: 'us' }}
    //       changed={(e, result) => this.props[metaInfo.store].formChange(e, result, metaInfo.form)}
    //       {...props}
    //     />
    //   )
    // };

    FormCheckBoxOrRadio = (name, props) => {
      const fieldData = get(props, 'fielddata') || ((get(props, 'multiForm') && get(props, 'multiForm[1]') ? this.fieldsData[props.multiForm[1]][props.multiForm[2]][name] : this.fieldsData[name]));
      return (
        <FormCheckBoxOrRadio
          name={name}
          fielddata={fieldData}
          changed={(e, result) => this.props[metaInfo.store].formChange(e, result, (get(props, 'multiForm') || metaInfo.form), '')}
          containerClassName={`${get(props, 'customClass')}`}
          singleCheck={get(fieldData, 'singleCheck')}
          toggle={get(fieldData, 'toggle')}
          {...props}
        />
      );
    }

    FormDropDown = (name, props) => {
      const fieldData = get(props, 'fielddata') || ((get(props, 'multiForm') && get(props, 'multiForm[1]') ? this.fieldsData[props.multiForm[1]][props.multiForm[2]][name] : this.fieldsData[name]));
      return (
        <FormDropDown
          name={name}
          selection
          fielddata={fieldData}
          options={get(props, 'options') || fieldData.options}
          multiple={fieldData.multiple}
          onChange={(e, result) => this.props[metaInfo.store].formChange(e, result, (get(props, 'multiForm') || metaInfo.form), fieldData.multiple ? 'dropdown' : false)}
          {...props}
        />
      );
    }

    FormPasswordStrength = (name, props) => {
      const fieldData = get(props, 'fielddata') || this.fieldsData[name];
      return (
        <FormPasswordStrength
          key={name}
          type="password"
          minLength={8}
          minScore={4}
          tooShortWord="Weak"
          scoreWords={['Weak', 'Weak', 'Okay', 'Good', 'Strong']}
          inputProps={{
            name: name, autoComplete: 'off', placeholder: 'Password',
          }}
          userInputs={
            get(props, 'userInputs')
          }
          changed={(e, result) => this.props[metaInfo.store].passwordChange(e, { name }, metaInfo.form)}
          fielddata={fieldData}
          showRequiredError
          {...props}
        />
      );
    }

    Masked = (name, props) => {
      const fieldData = get(props, 'fielddata') || ((get(props, 'multiForm') && get(props, 'multiForm[1]') ? this.fieldsData[props.multiForm[1]][props.multiForm[2]][name] : this.fieldsData[name]));
      return (
        <MaskedInput
          name={name}
          key={get(props, 'key')}
          value={fieldData.value}
          format={fieldData.format}
          fielddata={fieldData}
          showError={fieldData.showError}
          // eslint-disable-next-line no-shadow
          changed={(values, name) => this.props[metaInfo.store].maskChange(values, name, (get(props, 'multiForm') || metaInfo.form), fieldData.maskFormattedChange)}
          {...props}
        />
      );
    }

    Textarea = (name, props) => {
      const fieldData = get(props, 'fielddata') || ((get(props, 'multiForm') && get(props, 'multiForm[1]') ? this.fieldsData[props.multiForm[1]][props.multiForm[2]][name] : this.fieldsData[name]));
      return (
        <FormTextarea
          name={name}
          fielddata={fieldData}
          changed={(e, result) => this.props[metaInfo.store].formChange(e, result, (get(props, 'multiForm') || metaInfo.form))}
          containerClassName="secondary"
          {...props}
        />
      );
    }

    getFieldValue = (name, params) => {
      const { keyField, formatting } = { keyField: 'value', formatting: true, ...params };
      if (this.fieldsData[name].objType === 'PhoneNumber' && keyField === 'value' && formatting) {
        return Utils.phoneNumberFormatter(this.fieldsData[name][keyField]);
      } else if (this.fieldsData[name].objType === 'DATE' && keyField === 'value' && formatting) {
        return Utils.formatDateObjectToLocal(this.fieldsData[name][keyField]);
      } else {
        return this.fieldsData[name][keyField];
      }
    };

    resetFormData = (params) => {
      if (params && typeof params === 'object' && Object.keys(params).length) {
        this.props[metaInfo.store].setFormData(metaInfo.form, params);
      } else if (metaInfo.formSetObj) {
        const { computed, ref, keepAtLeastOne, validateForm } = metaInfo.formSetObj;
        this.props[metaInfo.store].setFormData(metaInfo.form, this.props[metaInfo.store][computed], ref, keepAtLeastOne, validateForm);
      }
    };

    ImageCropper = (name, props) => {
      const fieldData = get(props, 'fielddata') || ((get(props, 'multiForm') && get(props, 'multiForm[1]') ? this.props[metaInfo.store][props.multiForm[0]].fields[props.multiForm[1]][props.multiForm[2]][name] : this.fieldsData[name]));
      const arrayName = Array.isArray(get(props, 'multiForm')) ? get(props, 'multiForm[1]') : false;
      const index = Array.isArray(get(props, 'multiForm')) ? get(props, 'multiForm[2]') : -1;
      const handleVerifyFileExtension = (fileExt, field) => {
        const validate = Utils.validateImageExtension(fileExt);
        if (validate.isInvalid) {
          const attr = 'error';
          const { errorMsg } = validate;
          this.props[metaInfo.store].setMediaAttribute(get(props, 'multiForm') || metaInfo.form, attr, errorMsg, field, index, arrayName);
          this.props[metaInfo.store].setMediaAttribute(get(props, 'multiForm') || metaInfo.form, 'value', '', field, index, arrayName);
        }
      };
      const handelImageDimension = (width, height, field) => {
        if (width < 200 || height < 200) {
          const attr = 'error';
          const errorMsg = 'Image size should not be less than 200 x 200.';
          this.props[metaInfo.store].setMediaAttribute(get(props, 'multiForm') || metaInfo.form, attr, errorMsg, field, index, arrayName);
          this.props[metaInfo.store].setMediaAttribute(get(props, 'multiForm') || metaInfo.form, 'value', '', field, index, arrayName);
        }
      };
      const setData = (attr, value) => {
        this.props[metaInfo.store].setMediaAttribute(get(props, 'multiForm') || metaInfo.form, attr, value, name, index, arrayName);
      };
      const handleResetImageCropper = () => {
        this.props[metaInfo.store].resetImageCropper(get(props, 'multiForm') || metaInfo.form, name, index, arrayName);
      };
      const setConfirmModal = (val) => {
        this.props[metaInfo.store].setMediaAttribute(get(props, 'multiForm') || metaInfo.form, 'confirmModal', val, name, index, arrayName);
      };
      const handleRemoveConfirm = () => {
        if (props.removeMedia) {
          props.removeMedia(get(props, 'multiForm') || metaInfo.form, name);
        }
        this.props[metaInfo.store].resetImageCropper(get(props, 'multiForm') || metaInfo.form, name, index, arrayName);
      };
      const imageCropper = (
        <ImageCropper
          disabled={props.isReadonly}
          fieldData={fieldData}
          setData={(attr, value) => setData(attr, value)}
          verifyExtension={handleVerifyFileExtension}
          handelReset={handleResetImageCropper}
          verifyImageDimension={handelImageDimension}
          field={fieldData}
          modalUploadAction={async fieldName => {
            await this.props[metaInfo.store].uploadMedia(fieldName, (get(props, 'multiForm') || metaInfo.form), {
              identifier: (get(props, 'identifier') || 'Upload.S3.Public'),
              resourceId: get(props, 'resourceId'),
              type: get(props, 'type') || get(fieldData, 'type'),
              tags: get(props, 'tags'),
              onUpload: get(props, 'onUpload')
            });
            if (typeof get(props, 'onUpload') === 'function') {
              props.onUpload();
            }
          }}
          name={name}
          cropInModal
          aspect={props.square ? 1 : props.widescreen ? 16 / 9 : props.ultrawide ? 21 / 7 : props.socialAspect ? 1.91 : 3 / 2}
          size="small"
          {...props}
        />
      );
      return (
        <Form.Field>
          {!props.isProfile ?
            <div className={`${!props.isImagePreviewDisabled ? 'cropper-wrap' : ''} ${props.isCompanyLogo ? 'headshot-img' : ''} tombstone-img`}>
              {!props.isHideLabel && <label>
                {fieldData.label}
                {props.tooltip &&
                  <Popup
                    trigger={<Icon className="help circle" />}
                    content={props.tooltip}
                    position="top center"
                    className="center-align"
                    wide
                  />
                }
              </label>}
              {fieldData.value && !props.isImagePreviewDisabled ? (
                <div className={`file-uploader attached ${props.isCompanyLogo ? 'square' : ''}`} style={get(props, 'style')}>
                  {!props.isReadonly
                    && <Button onClick={() => setConfirmModal(true)} circular icon={{ className: 'remove' }} />
                  }
                  <Image64 srcUrl={fieldData.value} placeholder={props.placeholder} />
                  <Confirm
                    header="Confirm"
                    content="Are you sure you want to remove this media file?"
                    open={fieldData.confirmModal}
                    onCancel={() => setConfirmModal(false)}
                    onConfirm={handleRemoveConfirm}
                    size="mini"
                    className="deletion"
                  />
                </div>
              ) : fieldData.value && props.isImagePreviewDisabled ? (
                <div className={`file-uploader attached ${props.isCompanyLogo ? 'square' : ''}`}>
                  <Responsive
                    {...props}
                    as={Button}
                    minWidth={768}
                    size="tiny"
                    compact
                    className="ghost-button remove pull-right"
                    onClick={() => setConfirmModal(true)}
                  >
                    Remove
                  </Responsive>
                  <Responsive
                    {...props}
                    as={Icon}
                    maxWidth={767}
                    name="remove"
                    className="pull-right"
                    onClick={() => setConfirmModal(true)}
                  />
                  <span title={fieldData.fileName}>{fieldData.fileName}</span>
                  <Confirm
                    header="Confirm"
                    content="Are you sure you want to remove this media file?"
                    open={fieldData.confirmModal}
                    onCancel={() => setConfirmModal(false)}
                    onConfirm={handleRemoveConfirm}
                    size="mini"
                    className="deletion"
                  />
                </div>
              ) : (imageCropper)
              }
            </div> :
            <div className="profile-avatar">
              <Image64 circular srcUrl={fieldData.value} placeholder={props.placeholder || 'profile'} />
              {imageCropper}
            </div>}
        </Form.Field>
      );
    };

    render() {
      const { currTime } = this.props[metaInfo.store];
      const smartElement = {
        Input: this.Input,
        PhoneNumber: this.PhoneNumber,
        Masked: this.Masked,
        FormValid: this.FormValid,
        Address: this.Address,
        FormPasswordStrength: this.FormPasswordStrength,
        CodeInput: this.CodeInput,
        FormCheckBoxOrRadio: this.FormCheckBoxOrRadio,
        FormDropDown: this.FormDropDown,
        Textarea: this.Textarea,
        Button: this.Button,
        Errors: this.Errors,
        Message: this.Message,
        getFieldValue: this.getFieldValue,
        resetFormData: this.resetFormData,
        ImageCropper: this.ImageCropper,
        // GoogleAutoComplete: this.GoogleAutoComplete,
      };
      return (
        <WrappedComponent
          {...this.props}
          smartElement={smartElement}
          currTime={currTime}
        />
      );
    }
  })));
}

export default (formHoc);
