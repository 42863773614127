import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { toJS } from 'mobx';
import { Icon, Popup, List, Checkbox } from 'semantic-ui-react';
import { PopUpModal } from '../../shared';

const FormCheckBoxOrRadio = (props) => {
  const [hidePlaceHolder, setHidePlaceHolder] = useState(false);

  useEffect(() => {
    if (((Array.isArray(toJS(value)) && get(toJS(value), '[0]')) || (!Array.isArray(toJS(value)) && toJS(value)))) {
      setHidePlaceHolder(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hidePlaceHolder]);

  const changed = (e, result) => {
    props.changed(e, props.singleCheck ? { name: props.name, value: result.checked } : result);
  }

  const linkOpener = (e, url) => {
    e.preventDefault();
    window.open(url, '_blank');
  }

  const {
    values, value, tooltip, label, radio,
  } = props.fielddata;
  const { uiStore, singleCheck, linkLabelWrapper } = props;
  const { isMobile } = uiStore.responsiveVars;
  return (
    <div className={props.containerClassName || 'ui relaxed list'}>
      {Array.isArray(values) ?
        <>
          {!props.isHideLabel && label ? <label>{props.label || label}</label> : null}
          {
            values.map(c => (
              <List.Item className="ui checkbox ml-10">
                {!props.iconic ? (
                  <Checkbox
                    radio={radio}
                    checked={singleCheck ? value : Array.isArray(value) ? value.includes(c.value) : value === c.value}
                    value={c.value}
                    propertyName={c.label || c.text}
                    {...props}
                    label={(
                      <label>
                        {linkLabelWrapper ? <Link onClick={(e) => linkOpener(e, linkLabelWrapper)}>{c.label || c.text}</Link> : (c.label || c.text)}
                        {c.tooltip && <Popup trigger={<Icon className="help-circle" />} content={c.tooltip} position="top center" wide />}
                      </label>
                    )}
                    onChange={
                      (e, result) => {
                        changed(e, result);
                        setHidePlaceHolder(!hidePlaceHolder);
                      }}
                    onClick={
                      (e) => {
                        setHidePlaceHolder(!hidePlaceHolder);
                      }
                    }
                  // onChange={changed}
                  />
                ) : (
                  <>
                    <input
                      type="checkbox"
                      readOnly
                      checked={Array.isArray(value) ? value.includes(c.value) : value === c.value}
                      value={c.value}
                      onChange={
                        (e, result) => {
                          changed(e, result);
                          setHidePlaceHolder(false);
                        }}
                      onClick={
                        (e) => {
                          setHidePlaceHolder(true);
                        }
                      }
                      {...props}
                    />
                    <label>
                      {c.icon
                        && <Icon className={c.icon} />
                      }
                      {tooltip
                        ? (
                          <PopUpModal
                            customTrigger={<span className="popup-label">{c.label || c.text}</span>}
                            content={c.tooltip}
                            position="top center"
                            className="center-align"
                            wide
                            showOnlyPopup={!isMobile}
                          />
                        )
                        : <span>{c.label || c.text}</span>
                      }
                    </label>
                  </>
                )
                }
              </List.Item>
            ))
          }
        </> :
        <Checkbox
          radio={radio}
          checked={singleCheck ? value : Array.isArray(value) ? value.includes(get(values, '[0].value')) : value === get(values, '[0].value')}
          value={get(values, '[0].value')}
          propertyName={get(values, '[0].label') || get(values, '[0].text')}
          {...props}
          label={(
            <label>
              {get(values, '[0].label') || get(values, '[0].text')}
              {get(values, '[0].tooltip') && <Popup trigger={<Icon className="help-circle" />} content={get(values, '[0].tooltip')} position="top center" wide />}
            </label>
          )}
          onChange={
            (e, result) => {
              changed(e, result);
              setHidePlaceHolder(false);
            }}
          onClick={
            (e) => {
              setHidePlaceHolder(true);
            }
          }
        // onChange={changed}
        />
      }
    </div>
  );
};

export default inject('uiStore')(observer(FormCheckBoxOrRadio));
