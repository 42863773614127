/* eslint-disable react/jsx-indent */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Icon, Form, Header, Divider } from 'semantic-ui-react';
import { isEmpty, pick, get, findLastIndex } from 'lodash';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { InlineLoader, SuspenseBoundary } from '../../../../theme/shared';
import formHOC from '../../../../theme/form/formHOC';

// const getFields = component => lazyRetry(() => import(`../../../../theme/form/src/${component}`));

// let FormTag = '';
let dynamicMetaInfo = {
  store: 'kitStore',
  form: 'DYNAMIC_PAYLOAD_FRM',
  resetErrors: true,
};

const DynamicForm = formHOC(observer((props) => {

  const [date, setDate] = useState('02-03-1993');
  useEffect(() => {
  }, [date]);
  // dynamicMetaInfo = props.metaInfo;
  function getFormInput(fieldKey, formProps, addonProps = undefined) {
    const { metaInfo, smartElement } = props;
    const { formChangeForPlugin, getFormElement, cleanUnwantedFormData } = props[metaInfo.store];
    const currentIdx = get(addonProps, 'currentIndex');
    const lastIndex = get(addonProps, 'lastIndex');
    const isLastElement = (currentIdx === lastIndex);
    // const currentForm = get(props, 'formObj.childForm');
    const question = get(formProps, 'question');
    const formAddonProps = !isEmpty(get(formProps, 'addonProps')) ? get(formProps, 'addonProps') : {};
    let additionalProps = formProps.type === 'TEXTAREA' ? { containerclassname: 'secondary huge' } : formProps.type === 'DROPDOWN' ? { selection: true, options: formProps.values.map(o => pick(o, ['key', 'value', 'text'])), onChange: (e, result) => formChangeForPlugin(e, result, props.formObj, true), placeholder: formProps.placeHolder || '' } : formProps.type === 'RADIO' ? { radio: true } : {};
    additionalProps = { ...additionalProps, ...formAddonProps };
    const elementProps = (['TEXTAREA', 'DROPDOWN', 'RADIO'].includes(formProps.type) || !isEmpty(formAddonProps)) && additionalProps;
    const formInputData = getFormElement(fieldKey, formProps, props.formObj);
    const formElementType = formInputData;
    // FormTag = getFields(formElementType);
    if (isLastElement) {
      cleanUnwantedFormData();
    }
    if (formProps.type === 'DATE') {
      return (
        <>
          <Form.Field>
            <label>{question}</label>
          <SemanticDatepicker
              size='large'
              format='MM-DD-YYYY'
              onChange={(event, data) => {
                changeDate(event, data);
                const formattedDate = formatDateToYYYYMMDD(data.value);
                formChangeForPlugin(event, { name: fieldKey, value: formattedDate }, props.formObj, true)
              }}
          />
          </Form.Field>
        </>
      );
    } else {
      return (
        <>
          <Form.Field>
            <label>{question}</label>
            {smartElement[formElementType](fieldKey, {
              ...elementProps,
              fielddata: formProps,
              changed: (e, result) => formChangeForPlugin(e, result, props.formObj, true)
              // onChange: (e, result) => formChangeForPlugin(e, result, currentForm),
            })}
          </Form.Field>
        </>
      );
    }
    
  }
  function changeDate(e, data) {
    setDate(data.value);
  }
  function formatDateToYYYYMMDD(dateString) {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1 and pad with leading zeros if needed.
    const day = String(date.getUTCDate()).padStart(2, '0'); // Pad with leading zeros if needed.
  
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }
  function loadFormElements(formPayload) {
    const [elem, setElem] = useState([]);
    const { metaInfo } = props;
    const { formCurrentIndex } = props[metaInfo.store];
    const elementArr = [];
    useEffect(() => {
      const formPayloadArr = Object.keys(formPayload.fields);
      const lastIndex = formPayloadArr && formPayloadArr.length > 0 ? findLastIndex(formPayloadArr) : 0;
      Object.keys(formPayload.fields).map((val, idx) => (
        elementArr.push(getFormInput(val, formPayload.fields[val], { currentIndex: idx, lastIndex: lastIndex }))
      ));
      // props[metaInfo.store].getPluginByType(props.listType, props.selectedPlugin);
      setElem(elementArr);
    }, [formCurrentIndex]);
    return elem;
  }

  const { metaInfo, formPayload, formNote, formHeader, smartElement, registerKit, onSubmit, loading } = props;
  const { formCurrentIndex, DYNAMIC_PAYLOAD_FRM, dynamicFormLength, incrementCurrentIndex, decrementCurrentIndex } = props[metaInfo.store];

  const tempFormPayload = { ...formPayload };

  const handleNext = (e) => {
    incrementCurrentIndex();
    props.stepHandler('INTAKE_FORM_DETAILS');
  }

  const handleBack = (e) => {
    if (formCurrentIndex === 0) {
      props.stepHandler('USER_DETAILS');
    } else {
      decrementCurrentIndex();
      props.stepHandler('INTAKE_FORM_DETAILS');
    }
  }
  const color = get(props, 'color');
  return (
    <>
      {
        tempFormPayload && tempFormPayload.fields && !isEmpty(tempFormPayload.fields) ? (
          <>
            <div className="featured-section">
              <SuspenseBoundary>
                <Header 
                  as="h2" 
                  className="mb-30"
                  style={{ 'color': color}}
                >
                  {formHeader}
                  {formNote ? <Header.Subheader>{formNote}</Header.Subheader> : null}
                </Header>
                <Divider section />
                <Form error>
                  {loadFormElements(tempFormPayload)}
                  {smartElement.Errors()}
                  <div className="right-align mt-30">
                    <>
                      {registerKit ? smartElement.Button({ color, classes: 'mini', content: <><Icon name="left arrow" /> Back</>, type: 'button', disabled: loading || false, subForm: formCurrentIndex, onClick: () => handleBack('USER_DETAILS') }) : ''}
                      {formCurrentIndex >= (dynamicFormLength - 1) ?
                        smartElement.Button({ color, content: 'Submit', disabled: !DYNAMIC_PAYLOAD_FRM[formCurrentIndex].meta.isValid, loading: loading, subForm: formCurrentIndex, onClick: (e) => onSubmit(e), classes: 'mini' })
                        : smartElement.Button({ color, content: 'Next', disabled: !DYNAMIC_PAYLOAD_FRM[formCurrentIndex].meta.isValid, loading: loading, subForm: formCurrentIndex, onClick: (e) => handleNext(e), classes: 'mini' })
                      }
                    </>
                  </div>
                </Form>
              </SuspenseBoundary>
            </div>
          </>
        )
          : <InlineLoader text="No questions added yet." />
      }
    </>
  );
}), dynamicMetaInfo);

export default inject('uiStore')(withRouter(observer(DynamicForm)));
