/**
 * Some generally used function definations being used at multiple places
 */
// import { toast } from 'react-toastify';
import React from 'react';
import moment from 'moment';
import crypto from 'crypto';
import FormHandle from 'validatorjs';
import { forIn, isObject, transform, has, intersection, set, get } from 'lodash';
import { formatPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input'
import { DEV_FEATURE_ONLY, IMAGE_UPLOAD_ALLOWED_EXTENSIONS, DOCUMENT_UPLOAD_ALLOWED_EXTENSIONS, DATE_FORMAT, GOOGLE_MAP_EMBED_URL } from '../../constants/common';
// import { commonStore } from '../../services/stores';
import { REACT_APP_GOOGLE_PLACE_API_KEY, REACT_APP_DEPLOY_ENV, REACT_APP_SPIDER_K, REACT_APP_SPIDER_I } from '../../constants/common';

export class Utility {
  /**
   * @desc To show alert notifications to the user
   * reference: https://fkhadra.github.io/react-toastify/
   */

  gAddressClean = (place) => {
    let result = {};
    const addressMap = {
      residentialStreet: {
        addressType: ['street_number', 'route', 'sublocality_level_1', 'sublocality_level_2', 'sublocality_level_3'],
        nameType: 'long_name'
      },
      city: { addressType: ['locality'], nameType: 'long_name' },
      state: { addressType: ['administrative_area_level_1'], nameType: 'short_name' },
      zip: { addressType: ['postal_code'], nameType: 'long_name' },
    };
    const address_components = get(place, 'address_components');
    if (address_components) {
      Object.keys(addressMap).forEach(aK => address_components.forEach((c) => {
        if (intersection(addressMap[aK].addressType, c.types).length > 0) {
          const addressEle = {};
          addressEle[aK] = addressMap[aK].addressType.length > 2 && result[aK] ? `${result[aK]} ${c[addressMap[aK].nameType]}` : c[addressMap[aK].nameType];
          result = has(result, aK) ? addressEle : { ...result, ...addressEle };
        }
      }));
    }
    const location = get(place, 'geometry.location');
    const formatted_address = get(place, 'formatted_address');
    if (location && formatted_address) {
      result = { ...result, lat: location.lat(), lng: location.lng(), formatted_address };
    }
    return result;
  };

  // eslint-disable-next-line no-useless-escape
  isValidEmail = (email) => {
    const validation = new FormHandle({ email }, { email: 'required|email' });
    return validation.passes();
  };

  isValidPhone = (phone) => isPossiblePhoneNumber(phone);

  CurrencyFormat = (amount, fraction = 2, maxFraction = 2) => new Intl.NumberFormat('en-US', {
    style: 'currency', currency: 'USD', minimumFractionDigits: fraction, maximumFractionDigits: maxFraction,
  }).format(amount)

  isUuid = value => value
    .match(new RegExp(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/)) !== null

  replaceKeysDeep = (obj, keysMap) => transform(obj, (result, value, key) => {
    const resultTmp = result;
    const currentKey = keysMap[key] || key;
    resultTmp[currentKey] = isObject(value) ? this.replaceKeysDeep(value, keysMap) : value;
  });

  getFormattedFileData = (file) => {
    const fileData = {};
    if (file) {
      const fileInfo = file;
      fileData.fileName = this.sanitize(fileInfo.name);
      fileData.fileType = fileInfo.type;
      fileData.fileExtension = fileInfo.name.substr((fileInfo.name.lastIndexOf('.') + 1));
      fileData.fileSize = fileInfo.size;
    }
    return fileData;
  }

  isSpecialCharPresent = str => (str ? new RegExp(/[^a-z0-9._-]+/gi).test(str) : '');

  sanitizeContent = (c) => {
    try {
      return c;
    } catch (e) {
      window.logger({ params: e });
      return '';
    }
  };

  sanitize = name => (name ? name.replace(/[^a-z0-9._-]+/gi, '_') : '');

  phoneNumberFormatter = (phoneNumber) => {
    return phoneNumber ? formatPhoneNumber(phoneNumber) : '-';
  };

  getDaysFromNow = (days) => {
    const d = new Date();
    let daysFromNow = d.setDate(d.getDate() + days);
    daysFromNow = new Date(daysFromNow).toISOString();
    return daysFromNow;
  }

  otpShield = () => {
    try {
      const OtpItems = document.getElementsByClassName('otp-field')[0]
        ? document.getElementsByClassName('otp-field')[0]
          .getElementsByTagName('input') : '';
      for (let i = 0; i < OtpItems.length; i += 1) {
        OtpItems[i].addEventListener('keydown', (e) => {
          if ([16, 107, 110, 109, 69, 187, 188, 189, 190].includes(e.keyCode)) {
            e.preventDefault();
          }
        });
      }
    } catch (e) {
      window.logger({ params: e });
    }
  }

  isBase64 = (data) => {
    try {
      const block = data.split(';');
      return block[1].split(',')[0] === 'base64';
    } catch (e) {
      return false;
    }
  }

  b64toBlob = (data, sliceSize = 512) => {
    const block = data.split(';');
    // Get the content type of the image
    const contentType = block[0].split(':')[1];
    // get the real base64 content of the file
    const b64Data = block[1].split(',')[1];
    const size = sliceSize || 512;
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += size) {
      const slice = byteCharacters.slice(offset, offset + size);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i += 1) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  logger = (userParams) => {
    const payLoad = { type: 'log', sendEmail: false, ...userParams };
    const { params, sendEmail, type } = payLoad;
    if (DEV_FEATURE_ONLY) {
      // eslint-disable-next-line no-unused-expressions
      type === 'info' ? console.info(params)
        : type === 'warn' ? console.warn(params)
          : type === 'clear' ? console.clear()
            : console.log(params);
      if (sendEmail && (type === 'warn' || type === 'info')) {
        this.sendAlertEmail({ body: params, type });
      }
    } else if (!DEV_FEATURE_ONLY && (type === 'warn' || type === 'info')) {
      // Send an email for these two type;
      this.sendAlertEmail({ body: params, type });
    }
  }

  sendAlertEmail = ({ body, type, action, subject }) => {
    // try {
    //   const emailParams = {
    //     type: type === 'warn' ? 'alert' : type === 'info' ? 'notification' : 'error',
    //     body: JSON.stringify(body),
    //     subject: subject || 'Triggered by client log',
    //     action: action || 'React Component'
    //   };
    //   commonStore.sendAlertToAdminFromClient(emailParams);
    // } catch (e) {
    //   window.logger({ params: e });
    // }
  }

  validateImageExtension = (ext) => {
    const obj = {
      isInvalid: ext ? !IMAGE_UPLOAD_ALLOWED_EXTENSIONS.includes(ext.toLowerCase()) : true,
      errorMsg: `Only ${IMAGE_UPLOAD_ALLOWED_EXTENSIONS.join(', ')} extensions are allowed.`,
    };
    return obj;
  };

  validateDocumentExtension = (ext) => {
    const obj = {
      isInvalid: ext ? !DOCUMENT_UPLOAD_ALLOWED_EXTENSIONS.includes(ext.toLowerCase()) : true,
      errorMsg: `Only ${DOCUMENT_UPLOAD_ALLOWED_EXTENSIONS.join(', ')} extensions are allowed.`,
    };
    return obj;
  };

  modalCssUpdate = (searchClass, addClass) => {
    const modal = document.querySelector(`.${searchClass}`).closest('.page');
    modal.classList.add(addClass);
  }

  customModalWrapper = () => {
    const mountNode = document.getElementsByClassName('custom-modal-wrapper');
    if (mountNode) {
      return mountNode[0];
    }
    const node = document.createElement('div');
    node.className = 'custom-modal-wrapper';
    document.body.appendChild(node);
    return node;
  }

  formatValue = (format, value) => {
    if (format && format.search('{{var}}') > -1) {
      const d = format.replace('{{var}}', value);
      return d;
    }
    return value;
  };

  cleanMsg = msg => (msg ? msg.replace('GraphQL error: ', '').replace('Error: ', '') : '');

  removeTypeNameFormObject = (data) => {
    const omitTypename = (key, value) => (key === '__typename' ? undefined : value);
    return JSON.parse(JSON.stringify(data), omitTypename);
  }

  //generates random id;
  generateUUID = () => {
    let dt = new Date().getTime();
    let UUID = () => {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return UUID() + UUID() + '-' + UUID() + '-' + UUID() + '-' + UUID() + '-' + UUID() + UUID() + UUID() + dt;
  }

  serializeJsonObject = (data) => {
    let result = {};
    forIn(data, (value, key) => {
      if(typeof value === 'object') {
        result = { ...result, ...this.serializeJsonObject(value) };
      } else {
        set(result, key, value);
      }
    });
    return result;
  };

  formatDateObjectToLocal = (date, format = DATE_FORMAT) => {
    return moment(date).format(format) ? moment(date).format(format) : '';
  };

  formatLocalDateToObject = (date, format = DATE_FORMAT) => {
    return moment(date, format).toDate();
  };

  getWeekDayName = (day, format = 'ddd:') => {
    return moment().day(day).format(format);
  };

  getTodayDayOfWeek = (format = DATE_FORMAT) => {
    return moment().format(format);
  };

  dateIsSame = (date, duration = 'month') => {
    return moment().isSame(date ,duration);
  };

  showDateInFormat = (params) => {
    const { date, dateFormat = DATE_FORMAT } = params;
    return moment(new Date(date)).format(dateFormat);
  };

  getAddress = (address, params) => {
    const { delimiter, formatted, twoLines, threeLines, singleLine } = { delimiter: ', ', formatted: false, singleLine: false, twoLines: false, threeLines: false,  ...params };
    let addressStr = '';
    const data = ['street', 'streetTwo', 'city', 'state', 'zip'];
    if (address && typeof address === 'object') {
      data.forEach((item) => {
        addressStr += get(address, item) ? `${get(address, item)}${item !== 'zip' ? delimiter : ''}` : '';
      });
    }
    if (addressStr && formatted && singleLine) {
      addressStr = <p>{addressStr}</p>;
    } else if (addressStr && formatted && ((!get(address, 'streetTwo') && addressStr.length > 46) || twoLines)) {
      addressStr = <p>{get(address, 'street')}<br />{get(address, 'city')}, {get(address, 'state')}, {get(address, 'zip')}</p>;
    }
    if (addressStr && formatted && ((get(address, 'streetTwo') && addressStr.length > 46) || threeLines)) {
      addressStr =
        <div>
          {get(address, 'street') ? (<p>{get(address, 'street')}<br /></p>) : ''}
          {get(address, 'streetTwo') ? (<p>{get(address, 'streetTwo')}<br /></p>) : ''}
          <p>
            {get(address, 'city') ? (<>{get(address, 'city')}, </>) : ''}
            {get(address, 'state') ? (<>{get(address, 'state')}, </>) : ''}
            {get(address, 'zip') ? (<>{get(address, 'zip')}</>) : ''}
          </p>
        </div>;
    } else if (addressStr && formatted) {
      addressStr = <p>{addressStr}</p>;
    }
    return addressStr;
  };

  getAddressUrl = (address) => {
    return `${GOOGLE_MAP_EMBED_URL}?key=${REACT_APP_GOOGLE_PLACE_API_KEY}&q=${address}`;
  };

  formatTime = (time, originFormat = 'H', resultFormat = 'hh:mm A') => {
    return moment(time, originFormat).format(resultFormat);
  }

  pageTitle = (t = '') => {
    try {
      return (!['production', 'prod', 'master'].includes(REACT_APP_DEPLOY_ENV) ? `[${REACT_APP_DEPLOY_ENV}] | ${t}` : t);
    } catch (e) {
      return 'Portal';
    }
  };

  encryptPass = (text) => {
    const cipher = crypto.createCipheriv('aes-256-cbc', REACT_APP_SPIDER_K, REACT_APP_SPIDER_I)
    let crypt = cipher.update(text,'utf8','hex');
    crypt += cipher.final('hex');
    return crypt;
  }

  decryptPass = (text) => {
    const decipher = crypto.createDecipheriv('aes-256-cbc', REACT_APP_SPIDER_K, REACT_APP_SPIDER_I)
    let dec = decipher.update(text,'hex','utf8')
    dec += decipher.final('utf8');
    return dec;
  }

}

export default new Utility();
