import React, { Component } from 'react';
import { Form, Popup, Icon } from 'semantic-ui-react';
import TextareaAutosize from 'react-textarea-autosize';
import { Link } from 'react-router-dom';
import { FieldError } from '../../shared';

class FormTextarea extends Component {
  state = { showError: false };
  constructor(props) {
    super(props);
    if (this.props.defaultValue && (this.props.fielddata.value === '' || this.props.fielddata.value === undefined)) {
      this.props.changed({}, { name: this.props.name, value: this.props.defaultValue });
    }
  }
  triggerError = (val) => {
    this.setState({ showError: val });
  }
  render() {
    const { props } = this;
    const {
      label,
      error,
      tooltip,
      placeHolder,
      defaultValue,
      value,
    } = props.fielddata;
    const { displayMode, readOnly, addon, classes, noAnimate } = props;
    const classesList = `${props.containerclassname || ''} ${props.readOnly ? 'display-only' : ''} ${classes} ${!noAnimate ? 'animate-input' : ''}`;

    return (
      <Form.Field className={classesList} error={(!!error && this.state.showError) || (!!error && props.showError)}>
        {noAnimate && !props.isHideLabel && label !== '' &&
          <label>
            {props.label || label}
            {tooltip &&
              <Popup
                trigger={<Icon className="help-circle" />}
                content={tooltip}
                position="top center"
                className="center-align"
                wide
              />
            }
            {props.removed &&
              <Link to={props.linkTo} onClick={e => props.removed(e)}>
                <Icon className="close-circle" color="grey" />
              </Link>
            }
          </label>
        }
        {props.readOnly ?
          <p className="comment-area">{value}</p> :
          <TextareaAutosize
            value={value === '' ? props.clear ? '' : undefined : value}
            label={label}
            minRows={props.minRows || 5}
            maxRows={props.minRows || 5}
            placeholder={(displayMode || readOnly) ? '' : placeHolder}
            defaultValue={props.defaultValue ? props.defaultValue : defaultValue}
            {...props}
            onChange={(e) => {
              props.changed(e, { name: e.target.name, value: e.target.value });
              this.triggerError(props.showError || false);
            }}
            onBlur={(e) => {
              props.changed(e, { name: e.target.name, value: e.target.value });
              this.triggerError(true);
            }}
          />
        }
        {!noAnimate && !props.isHideLabel && label !== '' &&
          <label>
            {props.label || label}
            {tooltip &&
              <Popup
                trigger={<Icon className="help-circle" />}
                content={tooltip}
                position="top center"
                className="center-align"
                wide
              />
            }
            {props.removed &&
              <Link to={props.linkTo} onClick={e => props.removed(e)}>
                <Icon className="close-circle" color="grey" />
              </Link>
            }
          </label>
        }
        {addon}
        {((error && this.state.showError) || (error && props.showError))
          && <FieldError error={error} />
        }
      </Form.Field>
    );
  }
}

export default FormTextarea;
