import React from 'react';
import { inject, observer } from 'mobx-react';
import { Header, Grid, Container, Button, Confirm } from 'semantic-ui-react';
import { get } from 'lodash';
import moment from 'moment';
import { Utilities } from '../../../../helper';
import { MaskedInput } from '../../../../theme/form';
import { DateTime, DetailsUnit } from './OrderView/shared';
import { InlineLoader } from '../../../../theme/shared';
// import { metaInfo } from '../constants';
// import OrderHistory from './OrderView/OrderHistory';
import Results from './OrderView/Results';

class Details extends React.Component {
  state = { deleteConfirm: false, showContext: false, deleteDocumentId: null };
  isReleased = false;

  render() {
    const { data, fileUpload } = this.props;
    const { isMobile } = this.props.uiStore.responsiveVars;

    return (
      !get(data, 'id') ? <InlineLoader /> :
      <>
        {/* <OrderHistory comment={ordersStore.comment} data={data} trigger={trigger} download={this.download} /> */}
        <div className="content-spacer">
          <Container>
            {isMobile ? (
              <Grid>
                <Grid.Column width={16} className={isMobile ? 'pr-0 pl-0' : ''}>
                  <DetailsUnit.FieldDisplayWOColumn as="h5" title="PATIENT">
                    {get(data, 'patient.firstName')} {get(data, 'patient.lastName')}
                  </DetailsUnit.FieldDisplayWOColumn>
                </Grid.Column>
                <Grid.Column width={16} className={isMobile ? 'pr-0 pl-0' : ''}>
                  <DetailsUnit.FieldDisplayWOColumn as="h3" title="GENDER / AGE">
                    {get(data, 'patient.gender')}
                    {data.patient && data.patient.dob && (
                    <>
                      , {moment().diff(data.patient.dob, 'years')}&nbsp;
                      <small>(<DateTime format="LL" datetime={data.patient.dob} />)</small>
                    </>
                      )}
                  </DetailsUnit.FieldDisplayWOColumn>
                </Grid.Column>
                <Grid.Column width={16} className={isMobile ? 'pr-0 pl-0' : ''}>
                  <DetailsUnit.FieldDisplayWOColumn as="h3" title="ADDRESS" type="address">
                    {get(data, 'patient.address')}
                  </DetailsUnit.FieldDisplayWOColumn>
                </Grid.Column>
                <Grid.Column width={16} className={isMobile ? 'pr-0 pl-0' : ''}>
                  <DetailsUnit.FieldDisplayWOColumn as="h3" title="PHONE / EMAIL">
                    {data.patient && data.patient.phone && (
                    <MaskedInput as="phone" value={data.patient.phone} displayType={'text'} isHideLabel />
                      )}
                    {get(data, 'patient.email') && get(data, 'patient.email')}
                  </DetailsUnit.FieldDisplayWOColumn>
                </Grid.Column>

                <Grid.Column width={16} className={isMobile ? 'pr-0 pl-0' : ''}>
                  <DetailsUnit.FieldDisplayWOColumn as="h3" title="PANEL / BRAND">
                    {get(data, 'panel.name')}<br />
                    <small>{get(data, 'brand.name')}</small>
                  </DetailsUnit.FieldDisplayWOColumn>
                </Grid.Column>
                <Grid.Column width={16} className={isMobile ? 'pr-0 pl-0' : ''}>
                  <input
                      onChange={e => this.upload(e.target.name, e.target.id, e.target.files)}
                      type="file"
                      style={{ display: 'none' }}
                      id={data.id}
                      name="ORDER"
                      ref={fileUpload}
                    />
                  <Header as="h3" className="mt-0">
                    <Header.Subheader>PERFORMING LAB</Header.Subheader>
                    {data.lab && (
                    <>
                      {data.lab.name}
                      {/* <br />
                      {data.lab.address &&
                      <small>
                        {get(data, 'lab.address.addressLine1')}<br />
                        {get(data, 'lab.address.city')},&nbsp;
                        {get(data, 'lab.address.state')} {get(data, 'lab.address.zip')}
                      </small>
                          } */}
                    </>
                      )}
                  </Header>
                </Grid.Column>
              </Grid>
              ) : (
                <Grid>
                  <Grid.Column width={8}>
                    <DetailsUnit.FieldDisplayWOColumn as="h5" title="PATIENT">
                      {get(data, 'patient.firstName')} {get(data, 'patient.lastName')}
                    </DetailsUnit.FieldDisplayWOColumn>
                    <DetailsUnit.FieldDisplayWOColumn as="h5" title="ADDRESS" type="address">
                      {get(data, 'patient.address')}
                    </DetailsUnit.FieldDisplayWOColumn>
                    <DetailsUnit.FieldDisplayWOColumn as="h5" title="PANEL / BRAND">
                      {get(data, 'panel.name')}<br />
                      <small>{get(data, 'brand.name')}</small>
                    </DetailsUnit.FieldDisplayWOColumn>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <DetailsUnit.FieldDisplayWOColumn as="h5" title="GENDER / AGE">
                      {get(data, 'patient.gender')}
                      {data.patient && data.patient.dob && (
                        <>
                          , {moment().diff(data.patient.dob, 'years')}&nbsp;
                          <small>(<DateTime format="LL" datetime={data.patient.dob} />)</small>
                        </>
                      )}
                    </DetailsUnit.FieldDisplayWOColumn>
                    <DetailsUnit.FieldDisplayWOColumn as="h5" title="PHONE / EMAIL">
                      {data.patient && data.patient.phone ? (
                        <MaskedInput as="phone" value={data.patient.phone} displayType={'text'} isHideLabel />
                      ) : null}
                      {get(data, 'patient.email') && get(data, 'patient.email')}
                    </DetailsUnit.FieldDisplayWOColumn>
                    <Header as="h5">
                      <Header.Subheader>PERFORMING LAB</Header.Subheader>
                      {data.lab && (
                        <>
                          {data.lab.name}
                          <br />
                          {/* {data.lab.address &&
                            <small>
                              {get(data, 'lab.address.addressLine1')}<br />
                              {get(data, 'lab.address.city')},&nbsp;
                              {get(data, 'lab.address.state')} {get(data, 'lab.address.zip')}
                            </small>
                          } */}
                        </>
                      )}
                    </Header>
                    <input
                      onChange={e => this.upload(e.target.name, e.target.id, e.target.files)}
                      type="file"
                      style={{ display: 'none' }}
                      id={data.id}
                      name="ORDER"
                      ref={fileUpload}
                    />
                    <DetailsUnit.FieldDisplayWOColumn as="h5" title="PHYSICIAN">
                      Dr. {`${get(data, 'releasedAssignedMd.firstName')} ${get(data, 'releasedAssignedMd.lastName')}`}
                    </DetailsUnit.FieldDisplayWOColumn>
                  </Grid.Column>
                </Grid>
              )}

            <div className="ordersResults"><Results {...this.props} editing={false} orderStatus={data.status} /></div>
            <Grid columns='2'>
              <Grid.Row>
                {get(data, 'labOrderId') ?
                  < Grid.Column >
                    <DetailsUnit.FieldDisplayWOColumn as="h5" title="Lab Order Id">
                      {get(data, 'labOrderId')}
                    </DetailsUnit.FieldDisplayWOColumn>
                  </Grid.Column> : null}
                {get(data, 'labOrderStatus') ?
                  <Grid.Column>
                    <DetailsUnit.FieldDisplayWOColumn as="h5" title="Lab Order Status">
                      {get(data, 'labOrderStatus')}
                    </DetailsUnit.FieldDisplayWOColumn>
                  </Grid.Column> : null}
              </Grid.Row>
            </Grid>
            <Grid columns='2'>
              <Grid.Row>
                {get(data, 'labOrderSyncBy') ?
                  <Grid.Column>
                    <DetailsUnit.FieldDisplayWOColumn as="h5" title="Lab Order Sync By">
                      {get(data, 'labOrderSyncBy')}
                    </DetailsUnit.FieldDisplayWOColumn>
                  </Grid.Column> : null}
                {get(data, 'labOrderSyncAt') ?
                  <Grid.Column>
                    <DetailsUnit.FieldDisplayWOColumn as="h5" title="Lab Order Sync At">
                      {Utilities.showDateInFormat({ date: get(data, 'labOrderSyncAt') })}
                    </DetailsUnit.FieldDisplayWOColumn>
                  </Grid.Column> : null}
              </Grid.Row>
            </Grid>
            {get(data, 'labOrderRequisitionPdf') &&
            <Grid columns='2'>
              <Grid.Row>
                <Grid.Column width={16} className={isMobile ? 'pr-0 pl-0' : ''}>
                  <DetailsUnit.FieldDisplayWOColumn as="h3" title="REQUISITION PDF">
                    <Button onClick={() => this.downloadRequisition(data.orderId)} size="large" className="normal link-button">Download</Button> <br />
                  </DetailsUnit.FieldDisplayWOColumn>
                </Grid.Column>
              </Grid.Row>
            </Grid>
              }
            <Confirm
                size="mini"
                content="Are you sure you want to delete this document ?"
                open={this.state.deleteConfirm}
                onCancel={() => this.setState({ deleteConfirm: false })}
                onConfirm={() => this.delete(data)}
              />
          </Container>
        </div>
      </>
    );
  }
}

export default inject('uiStore')(observer(Details));
