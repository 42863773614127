import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Image } from 'semantic-ui-react';
// import LazyLoad from 'react-lazyload';
import placeholder11 from '../../../assets/images/placeholder-1-1.jpg';
import placeholder23 from '../../../assets/images/placeholder-2-3.jpg';
import leader_placeholder from '../../../assets/images/leader-placeholder.jpg';
import placeholder52 from '../../../assets/images/placeholder-5-2.jpg';
import userPlaceholder from '../../../assets/images/leader-placeholder.jpg';

function Image64(props) {
  const [count, setCount] = useState(1);
  const [data, setData] = useState('');
  const [emptyImg, setEmptyImg] = useState(placeholder11);
  const { placeholder, srcUrl, setTimeout: timeout } = props;

  useEffect(() => {
    switch (placeholder) {
      case 'profile':
        setEmptyImg(leader_placeholder);
        break;
      case 'logo':
        setEmptyImg(placeholder11);
        break;
      case 'tombstone':
        setEmptyImg(placeholder23);
        break;
      case 'gallery':
        setEmptyImg(placeholder52);
        break;
      case 'user':
        setEmptyImg(userPlaceholder);
        break;
      default:
        setEmptyImg(placeholder11);
        break;
    }
  }, [placeholder]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getImage = () => {
    const UPLOADS_CONFIG = 'd';
    if (srcUrl) {
      const imgUrl = (srcUrl.includes('https://') || srcUrl.includes('http://')) ? (srcUrl) : `https://${UPLOADS_CONFIG.bucket}/${(srcUrl)}`;
      try {
        setData(imgUrl);
      } catch (e) {
        setData(emptyImg);
      }
    } else {
      setData(emptyImg);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getImage();
    }, timeout || 0);
  }, [getImage, timeout]);

  function handelOnError(e) {
    if (count < 10) {
      e.target.error = null;
      if (data) {
        e.target.src = `${data}`;
      } else {
        e.target.src = emptyImg;
      }
      setCount(count + 1);
    }
  }

  return props.bg ? (
    <div {...props} style={{ backgroundImage: `url(${data})` }} />
  ) : (
    <Image {...props} src={`${data}`} onError={handelOnError} />
    // <LazyLoad placeholder={emptyImg} height="300" once={true}>
    // </LazyLoad>
  );
}

export default inject('uiStore', 'authStore')(observer(Image64));
