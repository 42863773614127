import React, { Component } from 'react';
import { get } from 'lodash';
import { withRouter, Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Form, Header, Icon } from 'semantic-ui-react';
import formHOC from '../../../theme/form/formHOC';

const metaInfo = {
  store: 'authStore',
  form: 'LOGIN_FRM',
  resetForm: true,
  resetErrors: true,
};

class Login extends Component {
  onSubmit = async () => {
    try {
      const res = await this.props.authStore.auth({ login: true });
      if (res) {
        this.props.history.push('/dashboard');
      }
    } catch (e) {
      window.logger({ params: e });
    }
  };

  render() {
    const { smartElement, authStore } = this.props;
    const { brandMeta } = authStore;
    const brandsTheme = get(brandMeta, 'brandsTheme') || 'live-smart';
    const color = get(brandMeta, 'headerColor') ? get(brandMeta, 'headerColor') : brandsTheme === 'live-smart' ? '#170c76' : '#484d69';
    return (
      <section className="full-section d-flex aic jcc">
        <div className="auth-form-wrap">
          <Header 
            as="h2" 
            className="mb-30" 
            textAlign="center"
            style={{ 'color': get(brandMeta, 'titleTextColor') ? get(brandMeta, 'titleTextColor') : brandsTheme === 'live-smart' ? '#170c76' : '#484d69'}}
          >
            {get(brandMeta, 'loginTitle') || 'Log in to view your tests.'}
            {get(brandMeta, 'loginDesc') ? <Header.Subheader>{get(brandMeta, 'loginDesc')}</Header.Subheader> : ''}
          </Header>
          <Form error onSubmit={this.onSubmit}>
            {smartElement.Input('email', { size: 'large', autoFocus: true })}
            {smartElement.Input('password', { size: 'large', togglePassword: true, type: 'password' })}
            {smartElement.Errors()}
            <div className="center-align mt-30">
              {smartElement.Button({ color, content: <>Log in <Icon name="chevron circle right" /></> })}
              <p className="muted-text mt-30">New to {get(brandMeta, 'brandTitle') || (brandsTheme === 'live-smart' ? 'LiveSmart' : 'EBIEN')}? <Link to="/register">Sign Up</Link></p>
              <p className="muted-text mt-20">Forgot password? <Link to="/forgot-password">Reset Password</Link></p>
            </div>
          </Form>
        </div>
      </section>
    );
  }
}

export default withRouter(formHOC(observer((Login)), metaInfo));
