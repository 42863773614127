import gql from 'graphql-tag';

export const auth = gql`
  mutation auth($authDetails: AuthInput!, $action: AuthStateEnum, $code: String) {
    auth(authDetails: $authDetails, action: $action, code: $code) {
      state
      status
      auth
    }
  }
`;

export const initiateForgotPassword = gql`
  mutation initiateForgotPassword($email: String!) {
    initiateForgotPassword(email: $email) {
      status
    }
  }
`;

export const confirmForgotPassword = gql`
  mutation confirmForgotPassword($email: String!, $confirmationCode: String!, $password: String!) {
    confirmForgotPassword(email: $email, confirmationCode: $confirmationCode, , password: $password) {
      status
    }
  }
`;