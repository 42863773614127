import React, { useState } from 'react';
import { observer, inject } from 'mobx-react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Container, Icon, Button } from 'semantic-ui-react';
import { InlineLoader } from '../../../../theme/shared';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

function PdfView(props) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [zoomNumber, setZoomNumber] = useState(1.6);
  const numZoom = 2;

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset)
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }


  // Zoom logic
  function changeZoom(offset) {
    setZoomNumber(prevZoomNumber => prevZoomNumber + offset)
  }

  function zoom_in() {
    changeZoom(0.1)
  }

  function zoom_out() {
    changeZoom(-0.1)
  }

  const { isMobile } = props.uiStore.responsiveVars;
  return (
    <>
      <div className='pdf-control-wrapper'>
        {/* Pagination */}
        <Container text className='pagination d-flex aic jcb center-align'>
          <span className='pagination-page-counter'>Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}</span>
          <Button.Group>
            {/* <Button labelPosition='left' icon='left chevron' content='Back' /> */}
            <Button disabled={pageNumber <= 1} onClick={previousPage}><Icon name='left chevron' /> Prev</Button>
            <Button disabled={pageNumber >= numPages} onClick={nextPage}>Next <Icon name='right chevron' /></Button>
            {/* <Button icon='stop' content='Stop' /> */}
            {/* <Button icon='right chevron' content='Forward' /> */}
          </Button.Group>
          <div>
            <Button.Group>
              <Button disabled={zoomNumber <= 1} onClick={zoom_out}><Icon name='minus' className="m-0" /></Button>
              <Button disabled={zoomNumber >= numZoom} onClick={zoom_in}><Icon name='add' className="m-0" /></Button>
            </Button.Group>
            <span className='zoom-counter'>{Math.floor(`${zoomNumber}` * 100) + '%' || (numZoom ? 1 : '--')}</span>
          </div>
          <Button as='a' href={props.file || ''} download="pdf" labelPosition='right' icon='download' content='Download' />
        </Container>
      </div>

      {/* Document */}
      <div>
        {props.loading ?
          <div className="react-pdf__Document">
            <InlineLoader loadingText="Loading PDF..." />
          </div>
        : <Document
          loading={<InlineLoader loadingText="Loading PDF..." />}
          file={props.file}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page
            pageNumber={pageNumber}
            scale={isMobile ? 0.6 : zoomNumber}
          />
        </Document>}
      </div>
    </>
  );
}

export default inject('uiStore')(observer(PdfView));