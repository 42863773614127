import { camelCase, upperFirst } from 'lodash';
import moment from 'moment';
import momentZone from 'moment-timezone';
import { DEFAULT_TIME_ZONE_TO_DISPLAY, DATE_FORMAT } from '../../constants/common';

class DataFormatter {

  upperCamelCase = str => upperFirst(camelCase(str));

  getJsonFormattedError = (err) => {
    try {
      return JSON.parse(err.message.substring(err.message.indexOf('{')));
    } catch (e) {
      return {};
    }
  };

  diffDays = (timeStamp2, additionalProps) => {
    const { inHours = false, returnNegative = false } = additionalProps;
    const d1 = moment().format(DATE_FORMAT);
    const d2 = timeStamp2 ? moment(timeStamp2, DATE_FORMAT).format(DATE_FORMAT) : null;
    const diff = d2 ? moment(d2, DATE_FORMAT).diff(moment(d1, DATE_FORMAT), 'days') : null;
    if (inHours) {
      const date = new Date();
      const convertedtimeStamp2 = new Date(timeStamp2);
      const difference = convertedtimeStamp2.getTime() - date.getTime();
      return Math.floor(difference / 1000 / 60 / 60);
    }
    if (!returnNegative && diff <= 0) {
      return 0;
    }
    return diff;
  }

  addBusinessDays = (date, daysToAdd) => {
    let cnt = 0;
    let tmpDate = moment(date);
    while (cnt < daysToAdd) {
      tmpDate = tmpDate.add(1, 'days');
      if (tmpDate.weekday() !== moment().day('Sunday').weekday() && tmpDate.weekday() !== moment().day('Saturday').weekday()) {
        cnt += 1;
      }
    }
    return moment(tmpDate).format(DATE_FORMAT);
  }

  replaceAll = (input, search, replacement) => input.replace(new RegExp(search, 'g'), replacement);

  getDateFromNow = afterDays => new Date((new Date()).getTime() - (afterDays * 86400000)).toISOString();

  // eslint-disable-next-line no-useless-escape
  validateEmail = email => email.match(/[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}/igm);

  getDateAsPerTimeZone = (dataParam, additionalProps) => {
    const { isISOString = false, isLLFormat = false, showTime = true, isCustomFormat = undefined, timeZone = 'CST', addOne = false, inputFormat = undefined, onlyHHmm = false } = additionalProps;
    const localTimeZone = timeZone === 'CST' ? DEFAULT_TIME_ZONE_TO_DISPLAY : timeZone === 'local' ? momentZone.tz.guess(true) : timeZone;
    const dataVal = isISOString ? dataParam ? moment(dataParam, inputFormat) : moment() : addOne ? moment(dataParam).add(1, 'days') : dataParam;
    const utcCutoff = moment.utc(dataVal, `${DATE_FORMAT} HH:mm:ss`);
    const displayCutoff = utcCutoff.clone().tz(localTimeZone);
    return isLLFormat ? displayCutoff.format('ll') : isCustomFormat ? displayCutoff.format(isCustomFormat) : showTime ? displayCutoff.format(`${DATE_FORMAT} HH:mm:ssa`) : onlyHHmm ? displayCutoff.format('hh:mm a') : displayCutoff.format(DATE_FORMAT);
  }

  stringTemplateFormatting = (string, data) => {
    let result = string;
    if (string && typeof data === 'object' && Object.keys(data).filter((item) => data[item]).length) {
      Object.keys(data).forEach((item) => {
        result = this.replaceAll(result,`{{${item}}}`, data[item] ? data[item]: '-');
      });
      return result;
    }
    return '-';
  };

  getValueFromStringPattern = (str, start, end) => {
    let matches = str.match(new RegExp(`${start}(.*?)${end}`, 'g'));
    matches = matches.map(item => item.replace(start, '').replace(end, ''));
    return matches.length ? matches : [];
  }
}

export default new DataFormatter();
