import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Form, Icon } from 'semantic-ui-react';
import ReactPasswordStrength from 'react-password-strength';
import { FieldError } from '../../shared';

class FormPasswordStrength extends Component {
  state = { showError: false, showPassword: false, classNames: '' };

  triggerError = (val) => {
    this.setState({ showError: val });
  }

  setClassName = (val) => {
    this.setState({ classNames: val })
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  render() {
    const { props } = this;
    const {
      label,
      error,
      // tooltip,
      value,
      placeHolder,
    } = props.fielddata;
    const { classNames, showPassword, showError } = this.state;
    const { displayMode, togglePassword, noAnimate, addonClasses } = props;
    const fieldClass = `${props.containerclassname || ''} ${displayMode ? ' display-only' : ''}`;
    return (
      <Form.Field
        onFocus={() => this.setClassName('animate-label')}
        onBlur={() => this.setClassName('')}
        width={props.containerWidth || false}
        className={`${!noAnimate ? 'animate-input' : fieldClass}`}
        error={(!!error && showError)}
      >
        {noAnimate && !props.isHideLabel && (label !== '' || props.label !== '')
          && (
            <label>
              {props.label || label}{props.asterisk && props.asterisk === 'true' ? '*' : ''}
            </label>
          )
        }
        {props.type === 'password'
          && <input style={{ opacity: 0, position: 'absolute', width: 0 }} tabIndex={-1} value="something" placeholder={placeHolder} />
        }
        <ReactPasswordStrength
          key={props.key}
          className={`ui input ${classNames || (value ? 'animate-label' : '' )} ${addonClasses}`}
          placeholder={placeHolder}
          minLength={props.minLength}
          minScore={props.minScore}
          tooShortWord={props.tooShortWord}
          scoreWords={props.scoreWords}
          inputProps={{ ...props.inputProps, type: togglePassword && showPassword ? 'text' : 'password' }}
          userInputs={props.userInputs || []}
          changeCallback={(e) => { props.changed(e); this.triggerError(false); }}
          onBlur={() => this.triggerError(true)}
          defaultValue={value}
          showRequiredError={props.showRequiredError}
        />
        {!noAnimate && !props.isHideLabel && (label !== '' || props.label !== '')
          && (
            <label>
              {props.label || label}{props.asterisk && props.asterisk === 'true' ? '*' : ''}
            </label>
          )
        }
        {props.togglePassword && (
          <Icon onClick={this.handleClickShowPassword} name={showPassword ? 'eye' : 'eye slash'} link />
        )}
        {error && showError
          && <FieldError error={error} />
        }
        {error && props.showRequiredError
          && <FieldError error={error} />
        }
      </Form.Field>
    );
  }
}

export default observer(FormPasswordStrength);
