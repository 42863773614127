import React from 'react';
import { get, lowerCase, isNull } from 'lodash';
import { observer, inject } from 'mobx-react';
import { withRouter, Link } from 'react-router-dom';
import AssetImage from '../../modules/shared/AssetImage';
import { Menu, Icon } from 'semantic-ui-react';
import { Image64 } from '../shared';
import NavigationItems from './NavigationItems';

function Header(props) {
  const { brandMeta, isUserLoggedIn } = props.authStore;
  const { uiStore } = props;
  const { isMobile } = uiStore.responsiveVars;
  const logoPosition = lowerCase(get(brandMeta, 'logoPosition') || 'center');
  const logo = get(brandMeta, 'brandLogo.url') || null;
  const brandsTheme = get(brandMeta, 'brandsTheme') || 'live-smart';
  const urlMaker = url => (url.includes('https://') || url.includes('http://')) ? url : `https://${url}`;
  const brandLink = get(brandMeta, 'logoInfo.logoUrl') ? urlMaker(get(brandMeta, 'logoInfo.logoUrl')) : 'https://livesmart.health/';
  const clickHandle = (url, info = null) => {
    let logoDetails = { url: info.logoUrl || info.backUrl,
                        extLink: info.logoUrlExtLink || info.backUrlExtLink,
                        newTab: info.logoUrlNewTab || info.backUrlNewTab
                      };
    console.log(logoDetails);
    if(isNull(info)) {
      logoDetails = {url, extLink: false, newTab: false}
    }
    if (logoDetails.extLink && logoDetails.url) {
      if(logoDetails.newTab) {
        window.open(urlMaker(logoDetails.url), '_blank');
      } else {
        window.location.href = urlMaker(logoDetails.url);
      }
    } else if (logoDetails.newTab && logoDetails.url) {
      window.open(logoDetails.url, '_blank');
    } else if (logoDetails.url) {
      window.location.href = logoDetails.url;
    } else {
      props.history.push('/');
    }
  }
  const showPublicNav = (!!get(brandMeta, 'publicNav') && !isUserLoggedIn);
  let navs = [];
  if (showPublicNav && get(brandMeta, 'publicMenu[0]')) {
    navs = get(brandMeta, 'publicMenu').map(pNav => ({ title: get(pNav, 'ptitle'), to: get(pNav, 'pto'), extLink: get(pNav, 'pextLink'), newTab: get(pNav, 'pnewTab') }));
  }
  const logoComponent = (logo ?
    <Image64 srcUrl={logo} as={Link} onClick={() => clickHandle(brandLink, get(brandMeta, 'logoInfo'))} className="logo" /> :
    <AssetImage as={Link} onClick={() => clickHandle(brandLink, get(brandMeta, 'logoInfo'))} className="logo" path={brandsTheme === 'live-smart' ? 'livesmart_logo.png' : 'ebien_logo.png'} alt="Portal" />);

  const themeClass = get(brandMeta, 'brandsTheme') || 'live-smart';
  return (
    <>
      {showPublicNav ?
        <div className="header-wrap">
          <div className={`${themeClass === 'live-smart' ? '' : 'ui container'}`}>
            <Menu className="aic jcc">
              <Menu.Item className='left'>
                {logoComponent}
              </Menu.Item>
              <Menu.Menu position="right">
                <NavigationItems navList={navs} public />
              </Menu.Menu>
            </Menu>
          </div>
        </div>
        :
        <div className="header-wrap" style={{ 'border-top': get(brandMeta, 'headerColor') ? `12px solid ${get(brandMeta, 'headerColor')}` : null }}>
          <Menu className="aic jcc">
            {!isMobile ?
              <Menu.Item className="back-link" as={Link} onClick={() => clickHandle(brandLink, get(brandMeta, 'backInfo') || get(brandMeta, 'logoInfo'))}>
                {get(brandMeta, 'backInfo.backText') ?
                  get(brandMeta, 'backInfo.backText')
                  : <><Icon name="chevron left" />Back to {get(brandMeta, 'brandTitle') || (brandsTheme === 'live-smart' ? 'LiveSmart' : 'EBIEN')}</>
                }
              </Menu.Item> : null
            }
            <Menu.Item className={logoPosition === 'left' ? `logo ${logoPosition}` : logoPosition}>
              {logoComponent}
            </Menu.Item>
            {isUserLoggedIn ?
              <div
                onClick={() => props.uiStore.setFieldValue('pusherVisible', !props.uiStore.pusherVisible)}
                role="button"
                tabIndex="0"
                className={`hamburger ${props.uiStore.pusherVisible ? 'active' : ''}`}
              >
                <div>
                  <span />
                  <span />
                  <span />
                  <span />
                </div>
              </div> : ''}
          </Menu>
        </div>}
    </>
  )
}

export default inject('authStore', 'uiStore')(withRouter(observer(Header)));
