import { makeObservable, observable } from 'mobx';
import { get } from 'lodash';
import DataModelStore from './dataModelStore';
import { testQuery, testMutation } from '../../queries/test';
import { sendAlertToAdminFromClient, uploadObject } from '../../queries/common';

export class CommonStore extends DataModelStore {
  constructor() {
    super({ sendAlertToAdminFromClient, uploadObject, testQuery, testMutation });
    makeObservable(this, {
      token: observable,
    });
  }

  token = window.localStorage.getItem('jwt');

  sendAlertToAdminFromClient = params => new Promise(async (res, rej) => {
    try {
      const data = await this.executeMutation({
        mutation: 'sendAlertToAdminFromClient',
        variables: { payload: JSON.stringify({ subject: 'Client alert', whoTriggered: 'client', ...params }) },
      });
      res(data);
    } catch (err) {
      rej(err);
    }
  });

  uploadObject = (params) => new Promise(async (res, rej) => {
    const { resourceId, identifier, type, subType, fileName, fileType, fileSize, tags } = params;
    try {
      const data = await this.executeMutation({
        mutation: 'uploadObject',
        variables: {
          resourceId,
          identifier,
          type,
          subType,
          payload: {
            fileName,
            fileType,
            fileSize,
            tags
          },
         },
      });
      res(get(data, 'uploadObject'));
    } catch (err) {
      rej(err);
    }
  });

  testQueryExample = async () => {
    try {
      const data = await this.executeQuery({
        query: 'testQuery',
      });
      window.logger({ params: data });
    } catch (e) {
      window.logger({ params: e });
    }
  }

  testMutationExample = async () => {
    try {
      const data = await this.executeMutation({
        mutation: 'testMutation',
      });
      window.logger({ params: data });
    } catch (e) {
      window.logger({ params: e });
    }
  }

  removeLocalStorage = (keys) => {
    keys.map(k => window.localStorage.removeItem(k));
  }

}

export default new CommonStore();