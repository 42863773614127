import { toJS, observable, action, computed, makeObservable } from 'mobx';
import { matchPath } from 'react-router-dom';
import { intersection, get, filter, find } from 'lodash';
import DataModelStore from './dataModelStore';
import { PRIVATE_NAV, MOBILE_NAV, PUBLIC_NAV } from '../../../../constants/NavigationMeta';
import { userStore } from '../../index';
import { REACT_APP_DEPLOY_ENV } from '../../../../constants/common';

export class NavStore extends DataModelStore {
  constructor() {
    super();
    makeObservable(this, {
      NAV_ITEMS: observable.ref,
      MOBILE_NAV_ITEMS: observable.ref,
      PUBLIC_NAV_ITEMS: observable.ref,
      params: observable.ref,
      navMeta: observable.ref,
      specificNavMeta: observable.ref,
      currentActiveHash: observable.ref,
      myRoutes: computed,
      myMobileRoutes: computed,
      myPublicRoutes: computed,
      allNavItems: computed,
      specificNavs: computed,
      setAccessParams: action,
      setNavStatus: action,
      setMobileNavStatus: action,
    });
  }
  NAV_ITEMS = [...PRIVATE_NAV];

  PUBLIC_NAV_ITEMS = [...PUBLIC_NAV];

  MOBILE_NAV_ITEMS = [...MOBILE_NAV];

  params = {
    currentNav: [], appStatus: null, specificNav: null,
  };

  navMeta = [];

  specificNavMeta = [];

  currentActiveHash = null;

  canAccessBasedOnCapability = (capabilities = []) => {
    let capabilityCheck = [];
    let isAdmin = false;
    if (capabilities.includes('IS_ADMIN')) {
      const anyAdminCapability = find(userStore.myCapabilities, ca => (ca.includes('_SUPPORT') || ca.includes('_MANAGER') || ca.includes('_FULL')));
      isAdmin = !!anyAdminCapability;
    } else {
        capabilities.forEach(capability => {
        const rest = capability.substring(0, capability.lastIndexOf('_'));
        const last = capability.substring(capability.lastIndexOf('_') + 1, capability.length);
        const check = (last !== 'ANY') ? [capability]
          : [`${rest}_FULL`, `${rest}_MANAGER`, `${rest}_SUPPORT`];
        capabilityCheck = [...capabilityCheck, ...check];
      });
    }
    return intersection(userStore.myCapabilities, capabilityCheck).length > 0 || isAdmin;
  }

  filterRoutes = (params) => {
    const { isMobile } = { isMobile: false, ...params };
    let navigationItems = isMobile ? this.MOBILE_NAV_ITEMS : this.NAV_ITEMS;
    let routes = filter(
      navigationItems,
      n => ((!n.accessibleTo || n.accessibleTo.length === 0
        || this.canAccessBasedOnCapability(n.accessibleTo))
        && (!n.env || n.env.length === 0
          || intersection(n.env, [REACT_APP_DEPLOY_ENV]).length > 0)),
    );
    const filteredNavs = [];
    routes.forEach((navItem) => {
      const nItem = toJS(navItem);
      const filteredSubNavs = this.filterSubNavigations(nItem);
      filteredNavs.push(filteredSubNavs);
    });
    return filteredNavs;
  }

  filterSubNavigations = (nItem) => {
    if (nItem.subNavigations) {
      let newSubNav = nItem.subNavigations.filter(n => ((!n.accessibleTo
        || n.accessibleTo.length === 0
        || this.canAccessBasedOnCapability(n.accessibleTo)) && (!n.env
          || n.env.length === 0
          || intersection(n.env, [REACT_APP_DEPLOY_ENV]).length > 0)));
        if (newSubNav && newSubNav.length) {
          const filteredSubNavs = [];
          newSubNav.forEach((navSubItem) => {
            const nSubItem = toJS(navSubItem);
            const filteredSubItem = this.filterSubNavigations(nSubItem);
            filteredSubNavs.push(filteredSubItem);
          });
          newSubNav = filteredSubNavs;
        }
      nItem.subNavigations = [...newSubNav];
    }
    return nItem;
  }

  filterPublicRoutes = () => {
    let navigationItems = this.PUBLIC_NAV_ITEMS;
    const filteredNavs = filter(
      navigationItems,
      n => ((!n.env || n.env.length === 0
          || intersection(n.env, [REACT_APP_DEPLOY_ENV]).length > 0)),
    );
    return filteredNavs;
  }

  get myRoutes() {
    try {
      const uKey = get(userStore, 'currentUser.sub') || 'public';
      return this.filterRoutes({ uKey, isMobile: false });
    } catch (err) {
      window.logger({ params: err });
      return [];
    }
  }

  get myPublicRoutes() {
    try {
      return this.filterPublicRoutes();
    } catch (err) {
      window.logger({ params: err });
      return [];
    }
  }

  get myMobileRoutes() {
    try {
      const uKey = get(userStore, 'currentUser.sub') || 'public';
      return this.filterRoutes({ uKey, isMobile: true });
    } catch (err) {
      window.logger({ params: err });
      return [];
    }
  }

  filterByAccess = (sNavs, phase, exclude = []) => toJS(sNavs.filter(sN => !sN.accessFor
    || (sN.accessFor.includes(typeof phase === 'number' ? (phase <= 4 ? phase : 4) : phase) && !exclude.includes(sN.to))));

  get allNavItems() {
    const navItems = [...toJS(this.myRoutes)];
    const filteredNavs = [];
    navItems.forEach((navItem) => {
      const nItem = toJS(navItem);
      if (nItem.subNavigations) {
        const newSubNav = nItem.subNavigations.filter(n => ((!n.accessibleTo
          || n.accessibleTo.length === 0
          || this.canAccessBasedOnCapability(n.accessibleTo))) && (!n.env
            || n.env.length === 0
            || intersection(n.env, [REACT_APP_DEPLOY_ENV]).length > 0));
        nItem.subNavigations = [...newSubNav];
      }
      filteredNavs.push(nItem);
    });
    return filteredNavs;
  }

  get specificNavs() {
    const { specificNav } = this.params;
    let nav = [];
    if (specificNav) {
      nav = toJS(this.NAV_ITEMS.find(i => matchPath(specificNav, { path: `/dashboard/${i.to}` })));
      if (nav && nav.subNavigations) {
        nav.subNavigations = nav.subNavigations.filter(n => ((!n.accessibleTo
          || n.accessibleTo.length === 0 || this.canAccessBasedOnCapability(n.accessibleTo))
          && (!n.env || n.env.length === 0
            || intersection(n.env, [REACT_APP_DEPLOY_ENV]).length > 0)));
      }
    }
    return nav;
  }

  setAccessParams(key, value) {
    this.params[key] = value;
    const { currentNav, appStatus } = this.params;
    if (currentNav) {
      // hack for root so if /app i.e /dashboard
      const nav = toJS(this.allNavItems.find((i) => {
        if (value === '/dashboard' && currentNav === '/dashboard' && i.to === 'dashboard') {
          return true;
        }
        return matchPath(currentNav, { path: `/dashboard/${i.to}` });
      }));
      if (nav && nav.subNavigations) {
        nav.subNavigations = nav.subNavigations.filter(n => ((!n.accessibleTo
          || n.accessibleTo.length === 0 || this.canAccessBasedOnCapability(n.accessibleTo))
          && (!n.env || n.env.length === 0
            || intersection(n.env, [REACT_APP_DEPLOY_ENV]).length > 0)));
        if (nav.title === 'Application' && key === 'appStatus') {
          nav.subNavigations = this.filterByAccess(nav.subNavigations, appStatus);
        }
      }
      this.navMeta = nav;
    }
  }

  setNavStatus(calculations, forced, ignoreUpDirection = false) {
    const {
      topVisible, direction, bottomPassed, isMoveTop, topPassed,
    } = calculations;
    if (typeof topVisible === 'boolean') {
      this.navStatus = forced || (topPassed ? 'sub' : 'main');
      if ((this.navStatus === 'sub') && (bottomPassed)) {
        this.subNavStatus = (direction === 'down' ? 'animate' : !ignoreUpDirection ? 'animate reverse' : 'animate');
      } else if ((this.navStatus === 'main') && (bottomPassed) && (isMoveTop)) {
        this.subNavStatus = (direction === 'down' ? 'animate' : !ignoreUpDirection ? 'animate reverse' : 'animate');
      }
    }
  }

  setMobileNavStatus(calculations) {
    const {
      topVisible, bottomPassed,
    } = calculations;
    if (typeof topVisible === 'boolean') {
      // this.subNavStatus = 'main';
      this.campaignHeaderStatus = bottomPassed;
    }
  }
}

export default new NavStore();
