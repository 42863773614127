import { Component } from 'react';
import FormHandle from 'validatorjs';
import moment from 'moment';
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import { Utilities as Utils } from '../index';
class CustomValidations extends Component {
  loadCustomValidations = (form) => {
    // const currentForm = form;
    FormHandle.register('maskedField', (value, requirement) => value.toString().length === parseInt(requirement, 10));
    FormHandle.register('maxVal', (value, requirement) => parseInt(value, 10) <= parseInt(requirement, 10));
    /* Optional field validation register */
    FormHandle.register('optional', () => true);

    FormHandle.register('phoneNumber', (value) => {
      // isValidPhoneNumber check for length and valid phone number
      // isPossiblePhoneNumber only check the length
      return isPossiblePhoneNumber(`+1${value}`);
    }, 'Enter valid US phone number');

    FormHandle.register('emailOrPhone', (value) => {
      // isValidPhoneNumber check for length and valid phone number
      // isPossiblePhoneNumber only check the length
      return Utils.isValidEmail(value) || isPossiblePhoneNumber(`+1${value}`);
    }, 'Please enter valid email or US phone number.');

    FormHandle.register('hundreds', (value) => {
      const amount = parseFloat(value) || 0;
      return amount >= 100 && amount % 100 === 0;
    }, 'Investments must be in increments of $100');

    FormHandle.register('validDate', (value) => {
      const date = moment(value, 'MM/DD/YYYY', true);
      return date.isValid();
    }, 'Invalid Date.');

    FormHandle.register('isValidSlug', (value, requirement) => {
      return !requirement.includes(value);
    }, 'Invalid slug. Enter valid slug');

    //   FormHandle.register('futureDate', value => moment(value, DATE_FORMAT).isSameOrAfter(moment().format(DATE_FORMAT)),
    //     'The :attribute should not be less than today\'s date.');
  }
}

export default new CustomValidations();
