import React from 'react';
import { get } from 'lodash';
import { observer, inject } from 'mobx-react';
import { withRouter, Switch, Route, matchPath } from 'react-router-dom';
import './assets/semantic/semantic.min.css';
import cookie from 'react-cookies';
import Private from './modules/private';
import Public from './modules/public';
import { InlineLoader, OpenGraphTags } from './theme/shared';
import PusherMenu from './theme/layout/PusherMenu';
import DevPassProtected from './modules/auth/components/DevPassProtected';
import { isPreviewPasswordRequired } from './constants/common';

const restrictedScrollToTopPathArr = ['/faq', '/learn'];

class App extends React.Component {
  constructor(props) {
    super(props);
    window.addEventListener('resize', this.handleResize);
    this.props.uiStore.setFieldValue('responsiveVars', this.getSizes());
  }

  componentDidMount() {
    const { location, history } = this.props;
    if (location.pathname.endsWith('/') && !location.hash) { // resolved trailing slash issue.
      history.push(location.pathname.replace(/\/+$/, location.search));
    }
    this.checkForPasswordProtect();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidUpdate() {
    if (isPreviewPasswordRequired && this.props.location.pathname !== '/password-protected' && !cookie.load('DEV_PREVIEW_PASSWORD')) {
      cookie.remove('DEV_PREVIEW_PASSWORD');
      this.props.authStore.setDevAppAuthStatus(false);
      this.props.authStore.logout();
    }
    const currentLocation = this.props.location.pathname;
    if (!this.checkPathRestrictedForScrollTop(restrictedScrollToTopPathArr, currentLocation)) {
      window.scrollTo(0, 0);
    }
    this.checkForPasswordProtect();
  }

  checkForPasswordProtect = () => {
    const { authStore, location, history } = this.props;
    if (authStore.devPasswdProtection && location.pathname !== '/password-protected') {
      const setUrl = `${location.pathname}${location.search && location.search !== '' ? location.search : ''}`;
      this.props.uiStore.setFieldValue('passwordPreviewURL', setUrl);
      history.push('/password-protected');
    }
  }

  getSizes = () => {
    const { clientWidth } = document.documentElement;
    return {
      isMobile: clientWidth < 768,
      upToTablet: clientWidth < 992,
      isTablet: clientWidth >= 768 && clientWidth < 992,
      isTabletLand: clientWidth >= 768 && clientWidth < 1025,
      isSmallScreen: clientWidth >= 1024 && clientWidth < 1200,
    };
  };

  handleResize = () => {
    this.props.uiStore.setFieldValue('responsiveVars', this.getSizes());
  }

  checkPathRestrictedForScrollTop = (paths, pathname) => paths.some(val => pathname.includes(val));

  render() {
    const { location, authStore } = this.props;
    const { appLoaded, brandMeta } = authStore;
    const themeClass = get(brandMeta, 'brandsTheme') || 'live-smart';
    return (
      <>
        <div className={`${((!matchPath(location.pathname, { path: '/dashboard' })) ? 'public-pages' : 'private-pages')} ${themeClass}`}>
          <>
            <OpenGraphTags pathName={location.pathname} />
            {authStore.devPasswdProtection
                ? <Route exact path="/password-protected" component={DevPassProtected} /> :
            appLoaded ?
              (
                <PusherMenu>
                  <Switch>
                    <Route exact path={['/dashboard', '/dashboard/*']} component={Private} />
                    <Route path="/" component={Public} />
                  </Switch>
                </PusherMenu>
              )
              : <InlineLoader />}
          </>
          <div className="custom-modal-wrapper" />
        </div>
      </>
    );
  }
}

export default inject('uiStore', 'authStore')(withRouter(observer(App)));
