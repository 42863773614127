import React from 'react';
import { get } from 'lodash';
import { Menu } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { NavLink, withRouter } from 'react-router-dom';

const NavigationItems = (props) => {
  const logout = async () => {
    await props.authStore.logout();
    props.history.push('/');
  };
  const urlMaker = url => (url.includes('https://') || url.includes('http://')) ? url : `https://${url}`;
  const navClickHandle = (e, nav) => {
    e.preventDefault();
    const checkRootNav = (nav) => get(props, 'public') ? `/${nav}` : (nav === 'dashboard') ? `/${nav}` : `/dashboard/${nav}`;
    if (nav.extLink && nav.to) {
      const path = urlMaker(nav.to);
      if(nav.newTab) {
        window.open(path, '_blank')
      } else {
        window.location.href = path
      }
    } else if (nav.newTab && nav.to) {
      window.open(checkRootNav(nav.to), '_blank');
    } else if (nav.to) {
      props.history.push(checkRootNav(nav.to));
    } else {
      props.history.push('/');
    }
  };

  const { authStore, navList } = props;
  const { isUserLoggedIn } = authStore;
  const navs = navList || [];
  return (
    <>
      {navs.map(nav => (
        <Menu.Item
          onClick={e => navClickHandle(e, nav)}
          as={NavLink}
          to={get(nav, 'extLink') ? { pathname: nav.to } : `${get(props, 'public') ? '' : 'dashboard'}/${nav.to}`}
          location={props.location}
          isActive={(match, loc) => {
            if (!match) {
              return false;
            }
            return (loc.pathname === props.location.pathname);
          }}
        >
          {nav.title}
        </Menu.Item>
      ))}
      {isUserLoggedIn ?
        <Menu.Item onClick={() => logout()}>
          Log Out
        </Menu.Item> : ''}
    </>
  );
}

export default inject('authStore')(withRouter(observer(NavigationItems)))