import { action, computed, makeObservable, observable, toJS } from 'mobx';
import { get } from 'lodash';
import { kitStore } from '../../index';
import DataModelStore from '../shared/dataModelStore';
import { FormValidator as FormHandle } from '../../../../helper';
import { getProfile, updateProfile } from '../../queries/user';

export class UserStore extends DataModelStore {
  constructor() {
    super({ getProfile, updateProfile });
    makeObservable(this, {
      currentUser: observable.ref,
      userDetailLoading: observable,
      getUserId: computed,
      getCurrentUser: computed,
      forgetUser: action,
      setCurrentUser: action,
    });
  }

  currentUser = null;

  userDetailLoading = false;

  get getCurrentUser() {
    return this.currentUser || null;
  }

  getProfile = async () => {
    try {
      const data = await this.executeQuery({
        query: 'getProfile',
      });
      this.setCurrentUser(get(data, 'getProfile'));
    } catch (err) {
      window.logger({ params: this.errorObj(err) });
    }
  };

  updateProfile = async () => {
    try {
      const variables = FormHandle.evaluateFormData(kitStore.USERS_DETAILS_FRM.fields, { onlyDirtyFields: true });
      const data = await this.executeMutation({
        mutation: 'updateProfile',
        variables,
      });
      this.setCurrentUser(get(data, 'updateProfile'));
    } catch (err) {
      window.logger({ params: this.errorObj(err) });
    }
  };

  forgetUser() {
    this.currentUser = undefined;
  }

  setCurrentUser(user) {
    this.currentUser = user;
  }

  get myCapabilities() {
    return (this.currentUser && this.currentUser.capabilities) ? toJS(this.currentUser.capabilities.map(c => c.capability)) : [];
  }

  myAccessForModule(module) {
    return this.myCapabilities.includes(`${module}_FULL`)
      ? { asAdmin: true, asManager: true, asSupport: true, level: 'FULL' } : (
        this.myCapabilities.includes(`${module}_MANAGER`)
          ? { asManager: true, asSupport: true, level: 'MANAGER' } : (
            this.myCapabilities.includes(`${module}_SUPPORT`)
              ? { asSupport: true, level: 'SUPPORT' } : {}
          )
      );
  }

  get getUserId() {
    return (this.currentUser && get(this.currentUser, 'id')) || null;
  }
}

export default new UserStore();