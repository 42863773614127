import Dashboard from '../modules/private/Dashboard';
import AccountSettings from '../modules/private/AccountSettings';
import RegisterKit from '../modules/private/RegisterKit';
import OrderDetails from '../modules/private/OrderDetails';
import Home from '../modules/public/Home';

export const PRIVATE_NAV = [
  {
    title: 'Dashboard',
    to: 'dashboard',
    path: 'Dashboard',
    exact: true,
    asRoot: true,
    skipInHeader: true,
    component: Dashboard
  },
  {
    title: 'Account Setting',
    to: 'account-settings',
    path: 'AccountSettings',
    component: AccountSettings
  },
  {
    title: 'Register Kit',
    to: 'register-kit',
    path: 'RegisterKit',
    component: RegisterKit
  },
  {
    title: 'Order details page',
    to: 'order/:id',
    path: 'OrderDetails',
    component: OrderDetails
  },
];

export const PUBLIC_NAV = [
  {
    title: 'Home',
    to: '',
    exact: true,
    path: 'Home',
    component: Home
  }
];

export const HEADER_LINK = [
  {
    section: 'Testing',
    routes: [
      { label: 'COVID Tests', url: 'testing-solution', pageTitle: 'Testing Solution - Portal' },
      { label: 'Corporate', url: 'corporate', pageTitle: 'Corporate - Portal' },
      { label: 'Events', url: 'events', pageTitle: 'Events - Portal' },
      { label: 'Science', url: 'learn', pageTitle: 'Learn - Portal' },
    ],
  },
  {
    section: 'Become an Operator',
    routes: [{ label: 'Become an Operator', url: 'become-operator', pageTitle: 'Become Operator - Portal' }],
  },
  {
    section: 'About Us',
    routes: [{ label: 'About Us', url: 'about-us', pageTitle: 'About Us - Portal' }],
  },
];

export const FOOTER_DEFAULTS = {
  logoDescription: 'by',
  social: [
    { type: 'FACEBOOK', url: 'https://www.facebook.com/wellness4humanity/' },
    { type: 'INSTAGRAM', url: 'https://www.instagram.com/w4humanity/' },
    { type: 'YOUTUBE', url: 'https://www.youtube.com/channel/UCJTQiSaKvA4yi9IpzhHsbug' },
    { type: 'LINKEDIN', url: 'https://www.linkedin.com/company/wellness4humanity-colorado/' },
  ],
};

export const FOOTER_LINK = [
  {
    section: 'Testing',
    routes: [
      { label: 'COVID Tests', url: 'testing-solution', pageTitle: 'Testing Solution - Portal' },
      { label: 'Corporate', url: 'corporate', pageTitle: 'Corporate - Portal' },
      { label: 'Events', url: 'events', pageTitle: 'Events - Portal' },
    ],
  },
  {
    section: 'Company',
    routes: [
      { label: 'About Us', url: 'about-us', pageTitle: 'About Us - Portal' },
      { label: 'Privacy Policy', url: 'privacy-policy', pageTitle: 'Privacy policy - Portal' },
      { label: 'Terms & Conditions', url: 'terms-and-conditions', pageTitle: 'Terms and conditions - Portal' },
    ],
  },
  {
    section: 'More',
    routes: [
      { label: 'Become an Operator', url: 'become-operator', pageTitle: 'Become Operator - Portal' },
      { label: 'Learn the Science', url: 'learn', pageTitle: 'Learn - Portal' },
      { label: 'FAQ', url: 'faq', pageTitle: 'Faqs - Portal' },
    ],
  },
];

export const MOBILE_NAV = [];
