import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { get } from 'lodash';
import { Form, Popup, Icon, Dropdown } from 'semantic-ui-react';
import { FieldError } from '../../shared';
// import { US_STATES } from '../../../constants/account';

const style = {
  hidden: {
    display: 'none',
  }
};

const FormDropDown = observer((props) => {
  const [hidePlaceHolder, setHidePlaceHolder] = useState(false);
  const [hideLabel, setHideLabel] = useState(false);
  const {
    label,
    error,
    tooltip,
    value,
    multiple,
    rule,
  } = props.fielddata;
  useEffect(() => {
    if (((Array.isArray(toJS(value)) && get(toJS(value), '[0]')) || (!Array.isArray(toJS(value)) && toJS(value)))) {
      setHidePlaceHolder(true);
    }
    const hasValue = (Array.isArray(toJS(value)) && toJS(value).length > 0) || (!Array.isArray(toJS(value)) && toJS(value));
    if(hasValue) {
      setHideLabel(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, hidePlaceHolder, hideLabel);
  // let {
  //   value,
  // } = props.fielddata;
  const {
    displayMode,
    noAnimate,
    // name, checkStateCode
  } = props;
  // if (name === 'state' && value && checkStateCode) {
  //   const state = US_STATES.find(s => s.text === value.toUpperCase());
  //   value = state ? state.key : value;
  // }
  const fieldClass = `${props.containerClassName || ''} ${displayMode ? ' display-only' : ''}`;
  const hidePlaceHolderCond = ((Array.isArray(toJS(value)) && get(toJS(value), '[0]')) || (!Array.isArray(toJS(value)) && toJS(value)) || hidePlaceHolder);
  
  return (
    <Form.Field error={error} width={props.containerWidth || false} className={`${!noAnimate ? 'animate-input' : fieldClass}`}>
      {noAnimate && !props.isHideLabel && label !== ''
        && (
          <label>
            {label}
            {(props.tooltip || tooltip)
              && (
                <Popup
                  trigger={<Icon className="help-circle" />}
                  content={props.tooltip || tooltip}
                  position="top center"
                  className="center-align"
                  wide
                />
              )
            }
          </label>
        )
      }
      <Dropdown 
      {...props} 
      onOpen={() => {setHidePlaceHolder(true); setHideLabel(true);}}
       onClose={() => setHidePlaceHolder(false)} 
       multiple={multiple} value={toJS(value)}
        className={`${displayMode && 'readonly'} ${hidePlaceHolderCond ? 'hasValue' : ''}`} 
        disabled={displayMode} 
        selectOnBlur={false} />
      <input 
      className="test" 
      style={style.hidden} 
      placeholder={hidePlaceHolderCond ? '' : `${props.label || label}${rule.includes('required') ? '*' : ''}`}
       />
      {!noAnimate && !props.isHideLabel &&  !hideLabel && (label !== '' || props.label !== '')
        && (
          <label>
            {`${props.label || label}${rule.includes('required') ? '*' : ''}`}
          </label>
        )
      }
      {/* {!props.isHideLabel && label !== ''
        && <div className="dropdown-effect">{label}</div>
      } */}
      {error
        && <FieldError error={error} />
      }
    </Form.Field>
  );
});

export default FormDropDown;
