import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Form } from 'semantic-ui-react';
import { get } from 'lodash';
import formHOC from '../../../theme/form/formHOC';

const metaInfo = {
  store: 'authStore',
  form: 'FORGOT_PASS_FRM',
};

function ForgotPasswordInitiate(props) {
  const onSubmit = async (event) => {
    event.preventDefault();
    try {
      const res = await props.authStore.forgotPassword();
      if (res) {
        props.stepHandler('RESET_PASS');
      }
    } catch(e) {
      window.logger({ params: e });
    }
  };

  const { smartElement } = props;
  const { brandMeta } = props.authStore;
  const themeClass = get(brandMeta, 'brandsTheme') || 'live-smart';
  const color = get(brandMeta, 'headerColor') ? get(brandMeta, 'headerColor') : themeClass === 'live-smart' ? '#170c76' : '#484d69';

  return (
    <section className="full-section d-flex aic jcc">
      <div className="auth-form-wrap">
        <p className="center-align mb-30">
          Please enter the email address associated with your account. 
          We&#39;ll send you a verification code to reset your password.
        </p>
        <Form error onSubmit={onSubmit}>
          {smartElement.Input('email')}
          {smartElement.Errors()}
          <div className="mt-30 center-align">
            {smartElement.Button({ content: 'Send Email', color })}
            <p className="muted-text mt-30"><Link to="/login">Back to Login</Link></p>
          </div>
        </Form>
      </div>
    </section>
  );
}
export default formHOC(observer(ForgotPasswordInitiate), metaInfo);
