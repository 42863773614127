import React from 'react';
import Parser from 'html-react-parser';
import { Message } from 'semantic-ui-react';

class MessageList extends React.Component {
  render() {
    const { messages, header } = this.props;
    if (messages && messages.length) {
      return (
        <Message info className="mb-30">
          {header && <Message.Header className="left-align">{Parser(header)}</Message.Header>}
          {messages.length === 1 ? <p>{Parser(messages[0])}</p> :
            (
              <Message.List>
                {Object.keys(messages).map(key => <Message.Item key={key}>{Parser(messages[key])}</Message.Item>)}
              </Message.List>
            )}
        </Message>
      )
    }
    return null;
  }
}

export default MessageList;
