import React from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { get } from 'lodash';
import DynamicForm from './DynamicForm';

const metaInfo = {
  store: 'kitStore',
  form: 'DYNAMIC_PAYLOAD_FRM',
};

class IntakeForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: false }
    this.props.kitStore.prepareDynamicForm();
  }

  onSubmit = async () => {
    try {
      this.setState({ loading: true });
      const { kitStore, userStore, history, registerKit } = this.props;
      if (registerKit) {
        const res = await kitStore.portalRegisterKit();
        this.setState({ loading: false });
        if (res) {
          if (!get(userStore.currentUser, 'phone')) {
            await userStore.updateProfile();
          }
          history.push('/dashboard');
        }
      } else {
        await userStore.updateProfile();
        this.discardChanges();
        this.setState({ loading: false });
      }
    } catch(error) {
      this.setState({ loading: false });
      this.props.uiStore.setErrors(error);
    }
  }

  render() {
    const { DYNAMIC_PAYLOAD_FRM, formCurrentIndex, getCurrentFormDetails, currTime } = this.props.kitStore;
    const formHeader = !get(getCurrentFormDetails, 'hideHeader') && get(getCurrentFormDetails, 'title') ? get(getCurrentFormDetails, 'title') : null;
    const formNote = !get(getCurrentFormDetails, 'hideHeader') && get(getCurrentFormDetails, 'note') ? get(getCurrentFormDetails, 'note') : null;
    const { loading } = this.state;
    return (
      <DynamicForm
        {...this.props}
        formPayload={DYNAMIC_PAYLOAD_FRM[formCurrentIndex]}
        formObj={{ parentForm: 'DYNAMIC_PAYLOAD_FRM', childForm: formCurrentIndex }}
        formNote={formNote}
        formHeader={formHeader}
        metaInfo={metaInfo}
        currTime={currTime}
        loading={loading}
        onSubmit={this.onSubmit}
      />
    );
  }
}

export default inject('kitStore', 'userStore', 'uiStore')(withRouter(observer(IntakeForm)));