import { mapKeys } from 'lodash';

const FILE_UPLOAD = {
  fileName: '',
  fileType: '',
  fileSize: '',
  id: '',
  base64String: '',
  tags: [],
  showLoader: false,
  confirmModal: false,
  objType: 'fileUpload',
};

const prepItemMeta = (item) => {
  const [key, label, value, rule, placeHolder, additionalProps] = item;
  return {
    key,
    label,
    value,
    isDirty: false,
    rule: rule || 'optional',
    error: undefined,
    placeHolder,
    ...{
      ...(additionalProps && additionalProps.asIn
        ? { ...additionalProps.props } : {})
    },
    ...{
      ...(additionalProps && additionalProps.fileUpload
        ? {
          ...FILE_UPLOAD,
          objRef: additionalProps.objRef,
          objRefOutput2: additionalProps.objRefOutput2
        } : {})
    },
  };
};

const prepColumnMeta = (item) => {
  const [Header, accessor, additionalProps] = item;
  return {
    Header,
    accessor,
    ...{ ...(additionalProps ? { ...additionalProps } : {}) },
  };
};

class FormHelper {
  generateMeta = items => (Array.isArray(items) ? mapKeys(items.map(item => prepItemMeta(item)), v => v.key) : prepItemMeta(items));
  generateColumnMeta = items => (Array.isArray(items) ? items.map(item => prepColumnMeta(item)) : prepColumnMeta(items));
}

const FormMetaGenerator = new FormHelper();

export default FormMetaGenerator.generateMeta([
  ['email', 'Email address', '', 'required|email', 'Enter email address'],
  ['password', 'Password', '', 'required|min:8|max:40', 'Enter password'],
  ['kitId', 'Kit registration ID', '', 'required', 'Ex: SC.01-0004'],
  ['firstName', 'First name', '', 'required', 'Enter first name'],
  ['lastName', 'Last name', '', 'required', 'Enter last name'],
  ['verify', 'Verify Password', '', 'required|same:password', 'Verify Password'],
  ['code', 'Enter verification code', '', 'required|min:6', 'Enter verification code', { asIn: true, props: { key: 'code' } }],
  ['phoneNumber', 'Phone number', '', 'required|phoneNumber', 'Enter phone number'],
  ['oldPassword', 'Old Password', '', 'required', 'Old Password'],
  ['retypePassword', 'Confirm New Password', '', 'required|same:password', 'Confirm New Password'],
  ['toc', '', false, 'accepted', '', {
    asIn: true,
    props: {
      defaultValue: false, values: [{ label: 'I accept the Terms and Conditions', value: true }],
    },
  }],
  ['gender', 'Gender', 'MALE', 'required', 'Select gender', {
    asIn: true, props: {
      options: [
        { key: 'Male', value: 'MALE', text: 'Male' },
        { key: 'Female', value: 'FEMALE', text: 'Female' },
      ]
    }
  }],
  ['dob', 'Date of Birth', '', `validDate|required`, 'Date of Birth'],
  ['street', 'Residential Street', '', 'required', 'Residential Street'],
  ['streetTwo', 'Apt / Unit', '', 'optional', 'Apt / Unit'],
  ['city', 'City', '', 'required', 'City'],
  ['state', 'State', '', 'required', 'State'],
  ['zip', 'ZIP Code', '', 'required', 'ZIP Code'],
  ['featureImage', 'Feature Image', '', 'optional', '', { asIn: true, fileUpload: true, props: { type: 'SITE_TOMBSTONE_IMAGE' } }],
]);
