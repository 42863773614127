import gql from 'graphql-tag';

export const testQuery = gql`
  query testQuery {
    testQuery
  }
`;

export const testMutation = gql`
  mutation testMutation {
    testMutation
  }
`;