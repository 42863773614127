export const DATE_FORMAT = 'MM/DD/YY';
export const DATE_ONLY = 'MMM DD, YYYY hh:mm a';
export const {
  REACT_APP_API_URL, REACT_APP_DEPLOY_ENV, REACT_APP_GOOGLE_CLIENT_ID, REACT_APP_FACEBOOK_CLIENT_ID, REACT_APP_GOOGLE_PLACE_API_KEY,
  REACT_APP_AWS_REGION, REACT_APP_COGNITO_USER_POOL_CLIENT_ID, REACT_APP_COGNITO_USER_POOL_ID, REACT_APP_FROALA_API_KEY_NEW, REACT_APP_SPIDER,
  REACT_APP_SPIDER_I, REACT_APP_SPIDER_K
} = process.env;

export const DEFAULT_TIME_ZONE_TO_DISPLAY = 'America/Chicago';

export const IMAGE_UPLOAD_ALLOWED_EXTENSIONS = ['jpeg', 'jpg', 'png'];

export const DOCUMENT_UPLOAD_ALLOWED_EXTENSIONS = ['jpeg', 'jpg', 'png', 'pdf', 'txt', 'doc', 'docx', 'xls', 'xlsx', 'odt', 'csv'];

export const DEV_FEATURE_ONLY = ['localhost', 'dev', 'review'].includes(REACT_APP_DEPLOY_ENV);

export const LOCAL_FEATURE_ONLY = ['localhost'].includes(REACT_APP_DEPLOY_ENV);

export const isProduction = ['production', 'prod', 'master'].includes(REACT_APP_DEPLOY_ENV);

export const isCatchErrorBoundarySendEmail = !['localhost'].includes(REACT_APP_DEPLOY_ENV);

export const DEV_PASS = 'c3BpZGVy';

export const GOOGLE_MAP_EMBED_URL = 'https://www.google.com/maps/embed/v1/place';

// eslint-disable-next-line no-useless-escape
const isOrdrsDomain = hostname => (hostname ? new RegExp(/(^|^[^:]+:\/\/|[^\.]+\.)ordrs\.io/gm).test(hostname) : false);
// eslint-disable-next-line no-useless-escape
const isAmazonEnv = hostname => (hostname ? new RegExp(/(^|^[^:]+:\/\/|[^\.]+\.)amazonaws\.com/gm).test(hostname) : false);

const checkPreviewPasswordRequired = () => {
  const { hostname } = window.location;
  if (hostname && (hostname === 'localhost' || isAmazonEnv(hostname) || isOrdrsDomain(hostname))) {
    return true;
  }
  return false;
}

export const isPreviewPasswordRequired = checkPreviewPasswordRequired();
