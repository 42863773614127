import gql from 'graphql-tag';

const profile = `
  id
  firstName
  lastName
  email
  gender
  dob
  phone
  address {
    addressLine1
    addressLine2
    city
    state
    zip
  }
  `;

export const changePassword = gql`
  mutation changePassword($oldPassword: String!, $newPassword: String!) {
    changePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
      id
    }
  }
`;

export const updateProfile = gql`
  mutation updateProfile(
    $firstName: String
    $lastName: String
    $gender: GenderEnum
    $dob: String
    $phone: String
    $address: AddressInputType
    ) {
    updateProfile(
      firstName: $firstName
      lastName: $lastName
      gender: $gender
      dob: $dob
      phone: $phone
      address: $address
    ) {
      ${profile}
    }
  }
`;


export const getProfile = gql`
  query getProfile {
    getProfile {
      ${profile}
    }
  }
`;
