import React from 'react';
import { Image } from 'semantic-ui-react';

export default class AssetImage extends React.Component {
  render() {
    const { path, alt, bg, ...restProps } = this.props;
    if (bg) {
      return (
        <div
          {...restProps}
          style={{ backgroundImage: `url(${require(`../../assets/images/${path}`)})` }}
        />
      );
    } else {
      return (
        <Image
          alt={alt || 'Image not found!'}
          {...restProps}
          src={require(`../../assets/images/${path}`)}
        />
      );
    }
  }

}
