export const MARKER_READING_CONFIG = {
  MAX_LIMIT: 100
};

export const MARKER_RANGE_TYPE = {
  SMALLER: '<', SMALLER_EQ: '<=', EQUAL: '=', LARGER_EQ: '>=', LARGER: '>', EQUAL_STRING: 'EQUAL'
};

export const RANGE_TYPE = {
  NORMAL: 'NORMAL', ABNORMAL: 'ABNORMAL', CRITICAL: 'CRITICAL'
};

export const UNITS = [
  '%', 'µg/dL', 'μmol/L', 'g/L', 'IU/L', 'mg/dL', 'mg/L', 'mmol/L', 'ng/dL', 'ng/mL', 'u/L', 'other'
];