import React from 'react';
import UserDetails from '../../shared/kit/UserDetails';
import UpdatePassword from './component/UpdatePassword';
import { Divider } from 'semantic-ui-react';

const AccountSettings = () => {
  return (
    <section className="full-section d-flex aic jcc">
      <div className="auth-form-wrap">
        <UserDetails />
        <Divider section hidden />
        <UpdatePassword />
      </div>
    </section>
  );
}
export default AccountSettings;
