import moment from 'moment';
import { action, makeObservable, observable, toJS } from 'mobx';
import { forEach, get, isEmpty, isObject, set } from 'lodash';
import cleanDeep from 'clean-deep';
import omitDeep from 'omit-deep';
import { uiStore } from '../../index';
import { GqlClient as client } from '../../../../api/gqlApi';
import { GqlClient as publicClient } from '../../../../api/gqlPublicApi';
import apiService from '../../../../api/restApi';
import commonStore from './commonStore';
import { FormValidator, MobxApollo, Utilities } from '../../../../helper'

const defaultRequestState = {
  lek: { 'page-1': null },
  skip: 0,
  page: 1,
  perPage: 25,
  filters: false,
  search: {
    limit: 300
  }
};

export default class DataModelStore {
  constructor(queryMutations) {
    makeObservable(this, {
      currTime: observable,
      currentScore: observable,
      auStatus: observable.ref,
      loading: observable.ref,
      requestState: observable,
      setFieldValue: action,
      uploadMedia: action,
      formChange: action,
      evaluateUpsertPayload: action,
      maskChange: action,
      phoneChange: action,
      validateForm: action,
      executeQuery: action,
      executeMutation: action,
      resetAll: action,
      resetForm: action,
      passwordChange: action,
      setAddressFields: action,
      initiateSearch: action,
      setFormData: action,
      setFormKeyData: action,
      uploadObject: action,
      resetFilters: action,
      setInitiateSearch: action,
      addMore: action,
      removeOne: action,
      resetAllForms: action,
      updateCurrentTime: action,
      resetImageCropper: action,
    });
    this.auStatus = null;
    this.gqlRef = queryMutations;
  }

  gqlRef = {};

  currentScore = undefined;

  currTime = null;

  // 0: error, 1: loading, 2: success
  auStatus = null;

  loading = false;

  apiHit = false;

  requestState = toJS(defaultRequestState);

  errorObj = err => (typeof err) === 'string' ? err : ({
    statusCode: get(err, 'statusCode'),
    code: get(err, 'code'),
    message: get(err, 'message'),
  });

  setFieldValue = (field, value, objRef = false, isArray = false) => {
    if (isArray) {
      this[field] = this[field].concat(value);
    } else if (objRef) {
      const tempRef = this[field];
      this[field] = set(tempRef, objRef, value);
      this[field] = tempRef;
    } else {
      this[field] = value;
    }
    this.currTime = +new Date();
  }

  executeMutation = async (params) => {
    uiStore.setErrors(undefined);
    const payLoad = { clientType: 'PRIVATE', refetchQueries: undefined, setLoader: undefined, removeLoader: undefined, ...params };
    const { mutation, refetchQueries, variables, queryParams, update, clientType } = payLoad;
    this.setFieldValue('loading', true);
    this.auStatus = 1;
    let result = {};
    try {
      let graphQlMutation = this.gqlRef[mutation];
      if (typeof this.gqlRef[mutation] === 'function') {
        graphQlMutation = this.gqlRef[mutation](queryParams);
      }
      const gqlClient = clientType === 'PRIVATE' ? client : publicClient;
      result = await gqlClient.mutate({
        mutation: graphQlMutation,
        variables,
        refetchQueries: refetchQueries || [],
        update,
      });
      this.auStatus = 2;
      this.setFieldValue('loading', false);
      return JSON.parse(JSON.stringify(get(result, 'data'))) || true;
    } catch (err) {
      window.logger({ params: err });
      this.setFieldValue('loading', false);
      this.auStatus = 0;
      const errorMessage = { message: get(payLoad, 'message.error') || err.message };
      return Promise.reject(errorMessage);
    }
  }

  // fetchPolicy: cache-first | cache-and-network | network-only | cache-only | no-cache
  // ref: https://www.apollographql.com/docs/react/api/react/hoc/#optionsfetchpolicy

  executeQuery = (params) => new Promise((res, rej) => {
    uiStore.setErrors(undefined);
    const payLoad = { clientType: 'PRIVATE', fetchPolicy: 'cache-first', setLoader: undefined, removeLoader: undefined, ...params };
    const { query, fetchPolicy, variables, queryParams, clientType } = payLoad;
    this.setFieldValue('loading', true);
    this.auStatus = 1;
    // need to accept fetch policy from parameter
    let graphQlQuery = this.gqlRef[query];
    if (typeof this.gqlRef[query] === 'function') {
      graphQlQuery = this.gqlRef[query](queryParams);
    }
    const gqlClient = clientType === 'PRIVATE' ? client : publicClient;
    MobxApollo.graphql({
      client: gqlClient,
      query: graphQlQuery,
      fetchPolicy,
      variables,
      onFetch: (data) => {
        if (data) {
          this.auStatus = 2;
          this.setFieldValue('loading', false);
          res(JSON.parse(JSON.stringify(data)));
        }
        this.currTime = +new Date();
      },
      onError: (err) => {
        window.logger({ params: err });
        this.auStatus = 0;
        this.setFieldValue('loading', false);
        rej(err);
      },
    });
  });

  jsonParser = (jsonKeys = [], data = {}) => {
    jsonKeys.forEach(key => {
      if (get(data, key) && typeof get(data, key) === 'string') {
        try {
          set(data, key, JSON.parse(get(data, key)));
        } catch (e) {
          window.logger({ params: e });
          set(data, key, {});
        }
      }
    });
    return data;
  };

  mergeObj = (observableFieldVal, payloadVal = {}) => {
    const observableField = JSON.parse(JSON.stringify(observableFieldVal));
    const payload = JSON.parse(JSON.stringify(payloadVal));
    forEach(payload, (val, key) => {
      if (isObject(payload[key]) && !Array.isArray(payload[key]) && typeof payload[key] !== 'string') {
        set(observableField, key, this.mergeObj(observableField[key] || {}, payload[key]));
      } else if (typeof payload[key] === 'string' || Array.isArray(payload[key]) || !observableField[key]) {
        set(observableField, key, payload[key]);
      } else {
        set(observableField, key, { ...observableField[key], ...payload[key] });
      }
    });
    return observableField;
  }

  evaluateUpsertPayload = (params, existingData) => {
    // try {
      const { forms, overrideData, cleanData, allFields, toJson, keyName, formFields } = { toJson: false, ...params };
      let data;
      if (overrideData) {
        data = overrideData;
      }else if (Array.isArray(forms)) {
        forms.forEach((f) => {
          data = { ...data, ...FormValidator.evaluateFormData(this[f].fields, { onlyDirtyFields: !allFields }) };
        });
      } else {
        data = FormValidator.evaluateFormData(this[forms].fields, { onlyDirtyFields: !allFields });
      }
      if (formFields) {
        data = { ...data, ...FormValidator.evaluateFormData(formFields, { onlyDirtyFields: !allFields }) };
      }
      if (cleanData) {
        data = cleanDeep(data);
        data = omitDeep(data, ['__typename', 'fileHandle']);
      }
      if (toJson) {
        if (Array.isArray(toJson)) {
          toJson.forEach((j) => {
            let jsonData = get(data, j);
            if (get(existingData, j)) {
              jsonData = { ...get(existingData, j), ...jsonData, }
            }
            set(data, j, JSON.stringify(jsonData));
          })
        } else {
          let jsonData = get(data, toJson);
            if (get(existingData, toJson)) {
              jsonData = { ...get(existingData, toJson), ...jsonData, }
            }
          set(data, toJson, JSON.stringify(jsonData));
        }
      }
      if (keyName) {
        return { [keyName]: data };
      }
      return data;
    // } catch(err) {
    //   window.logger(err);
    // }
  }

  formChange = (e, result, form, type, checked = undefined) => {
    const formName = Array.isArray(form) ? form[0] : form;
    if (Array.isArray(form)) {
      this[formName] = FormValidator.onArrayFieldChange(
        this[formName],
        FormValidator.pullValues(e, result),
        form[1],
        form[2],
        type,
        checked,
      );
    } else {
      this[formName] = FormValidator.onChange(this[formName], FormValidator.pullValues(e, result), type, checked);
    }
    if ((typeof result.name === 'undefined' ? e.target.name : result.name) === 'emailOrPhone' && formName === 'STEP1_FRM') {
      const value = (typeof result.value === 'undefined' ? e.target.value : result.value);
      if (get(value, '[0]') !== '+' && !isNaN(parseInt(get(value, '[0]'), 10)) && e.keyCode !== 8) {
        this.setFormKeyData('STEP1_FRM', 'emailOrPhone', `+1${value}`);
        this.setFormKeyData('STEP1_FRM', 'emailOrPhone', undefined, 'error');
      }
    }
    this.currTime = +new Date();
  };

  phoneChange = (field, value, form, excludeCountryCode) => {
    const formName = Array.isArray(form) ? form[0] : form;
    if (Array.isArray(form)) {
      this[formName] = FormValidator.onArrayFieldChange(
        this[formName],
        { name: field, value: value },
        form[1],
        form[2],
      );
    } else {
      this[formName] = FormValidator.onChange(this[formName], { name: field, value });
    }
    this.currTime = + new Date();
  };

  passwordChange = (e, result, form) => {
    this.currentScore = undefined;
    this[form] = FormValidator.onChange(this[form], FormValidator.pullValuesForPassword(e, result));
    if (e.score !== undefined) {
      this.currentScore = e.score;
    }
    this.currTime = +new Date();
  };

  setAddressFields = (place, form) => {
    const formName = Array.isArray(form) ? form[0] : form;
    if (Array.isArray(form)) {
      FormValidator.setAddressFieldsIndex(place, this[formName], form[1], form[2]);
    } else {
      FormValidator.setAddressFields(place, this[formName]);
    }
    this.currTime = +new Date();
  }

  setMediaAttribute = (form, attr, value, field, index = -1, arrayName) => {
    const formName = Array.isArray(form) ? form[0] : form;
    if (attr) {
      if (index > -1) {
        this.setFormKeyData(formName, field, value, attr, arrayName, index);
      } else {
        this.setFormKeyData(formName, field, value, attr);
      }
    }
  }

  //  name: fieldName, form: formName, params: { type, tags, identifier, resourceId }
  uploadMedia = async (name, form, params) => {
    const formName = Array.isArray(form) ? form[0] : form;
    const arrayName = Array.isArray(form) ? form[1] : false;
    const index = Array.isArray(form) ? form[2] : -1;
    try {
      const field = index > -1 ? this[formName].fields[arrayName][index][name] : this[formName].fields[name]
      const { base64String, fileName, fileType, type, fileSize, tags } = field;
      const fileObj = {
        fileData: Utilities.isBase64(base64String) ? Utilities.b64toBlob(base64String) : base64String,
        fileName: `${moment().unix()}_${Utilities.sanitize(fileName)}`,
        fileType,
        fileSize: fileSize.toString(),
        type: type || get(params, 'type'),
        tags: tags || get(params, 'tags'),
        identifier: get(params, 'identifier'),
        resourceId: get(params, 'resourceId')
      };
      this.setMediaAttribute(formName, 'showLoader', true, name, index, arrayName);
      // uploading file to S3 bucket or any other services as per identifier.
      const res = await this.uploadObject(fileObj);
      this.setMediaAttribute(formName, 'value', get(res, 'storageDetails.url'), name, index, arrayName);
      this.setMediaAttribute(formName, 'id', get(res, 'id'), name, index, arrayName);
      this.setMediaAttribute(formName, 'isDirty', true, name, index, arrayName);
      this.setMediaAttribute(formName, 'showLoader', false, name, index, arrayName);
      // this.validateForm(formName, { isMultiForm: !!arrayName });
    } catch (err) {
      window.logger({ params: err });
      this.setMediaAttribute(formName, 'showLoader', false, name, index, arrayName);
    }
    this.setFieldValue('currTime', new Date());
    // this.currTime = +new Date();
  };

  uploadObject = (payload) => new Promise(async (resolve, reject) => {
    try {
      const res = await commonStore.uploadObject(payload);
      await apiService.uploadOnS3(res.signedUrl, payload.fileData, payload.fileType);
      setTimeout(() => {
        resolve(res);
      }, 500);
    } catch (err) {
      reject(err);
    }
  });

  maskChange = (values, field, form, type) => {
    const formName = Array.isArray(form) ? form[0] : form;
    let fieldValue = type ? values[`${type}Value`] : values.value; // floatValue, fo
    if (Array.isArray(form)) {
      this[formName] = FormValidator.onArrayFieldChange(
        this[formName],
        { name: field, value: fieldValue },
        form[1],
        form[2],
      );
    } else {
      this[formName] = FormValidator.onChange(this[formName], { name: field, value: fieldValue === undefined ? null : fieldValue });
    }
    this.currTime = +new Date();
  };

  validateForm = (formName, params) => {
    this[formName] = FormValidator.validateForm(this[formName], params);
    this.currTime = +new Date();
  }

  resetForm = (form) => {
    this[form] = FormValidator.resetFormData(this[form]);
    this.currTime = +new Date();
  }

  addMore = (form, key, count = 1) => {
    this[form] = FormValidator.addMoreRecordToSubSection(this[form], key, count, true);
    this.currTime = +new Date();
  }

  removeOne = (form, arrayName, index, e = undefined) => {
    if (e) {
      e.preventDefault();
    }
    this[form].fields[arrayName].splice(index, 1);
    this.currTime = +new Date();
  }

  resetAll = () => {
    client.clearStore();
    publicClient.clearStore();
    this.currTime = +new Date();
  }

  resetAllForms = () => {
    this.formArr.forEach((f) => {
      this[f] = FormValidator.resetFormData(this[f]);
    });
  }

  setFormData = (form, data, ref, keepAtLeastOne, validateForm = null) => {
    const details = toJS({ ...data });
    if (!details) {
      return false;
    }
    this[form] = FormValidator.setFormData(this[form], details, ref, keepAtLeastOne);
    if (validateForm) {
      this[form] = FormValidator.validateForm(this[form], validateForm);
    }
  }

  setFormDataForDynamicForm = (form, elemRef, elementValue, subForm = false) => {
    if (subForm || subForm === 0) {
      this[form][subForm].fields[elemRef].value = elementValue;
    } else {
      this[form].fields[elemRef].value = elementValue;
    }
    this.currTime = +new Date();
  }

  setFormKeyData = (form, field, elementValue, fieldKey = 'value', subForm = false, index = -1) => {
    if (subForm && index > -1) {
      this[form].fields[subForm][index][field][fieldKey] = elementValue;
    } else if (subForm) {
      this[form][subForm].fields[field][fieldKey] = elementValue;
    } else {
      this[form].fields[field][fieldKey] = elementValue;
    }
    if (fieldKey === 'error' && elementValue) {
      this[form].meta.isValid = false;
    }
    this.currTime = +new Date();
  }

  initiateSearch = (searchParams) => {
    const requestState = { ...toJS(this.requestState), lek: { 'page-1': null }, page: 0, search: searchParams };
    this.setFieldValue('requestState', requestState);
    this.initRequest({ isConfig: true });
    this.currTime = +new Date();
  }

  setInitiateSearch = (name, value, setDefaultFilter = undefined) => {
    if (name === 'startDate' || name === 'endDate') {
      this.requestState.search[name] = value ? name === 'startDate' ? moment(new Date(`${value.formattedValue} 00:00:00`)).toISOString() : moment(new Date(`${value.formattedValue} 23:59:59`)).toISOString() : '';
      if (this.requestState.search.startDate || this.requestState.search.endDate) {
        const searchParams = { ...this.requestState.search };
        this.initiateSearch(searchParams);
      }
    } else {
      const searchParams = { ...this.requestState.search };
      const temp = { ...this.requestState };
      temp.search[name] = { ...this.requestState.search };
      this.setFieldValue('requestState', temp);
      if ((Array.isArray(value) && value.length > 0) || (typeof value === 'string' && value !== '')) {
        searchParams[name] = value;
      } else {
        delete searchParams[name];
      }
      if (setDefaultFilter && isEmpty(searchParams)) {
        searchParams[setDefaultFilter.defaultFilterType] = setDefaultFilter.defaultValue;
      }
      this.initiateSearch(searchParams);
    }
    this.currTime = +new Date();
  };

  resetFilters = () => {
    this.requestState = toJS(defaultRequestState);
    this.currTime = +new Date();
  };

  searchRecord = (props) => {
    const { column, value } = props;
    this.setInitiateSearch(column, value);
  }

  updateCurrentTime = () => {
    this.currTime = +new Date();
  }

  resetImageCropper = (form, field, index = -1, arrayName) => {
    const formName = Array.isArray(form) ? form[0] : form;
    const attributes = ['fileName', 'fileType', 'fileSize', 'error', 'base64String', 'showLoader', 'value', 'confirmModal', 'isDirty'];
    attributes.forEach((val) => {
      const typeCheck = index > -1 ? this[formName].fields[arrayName][index][field][val] : this[formName].fields[field][val];
      if ((typeof typeCheck === 'object') && (typeCheck !== null)) {
        if (index > -1) {
          this[formName].fields[arrayName][index][field][val] = val === 'isDirty' ? true : {};
        } else {
          this[formName].fields[field][val] = val === 'isDirty' ? true : {};
        }
      } else if (index > -1) {
        this[formName].fields[arrayName][index][field][val] = val === 'isDirty' ? true : ['showLoader', 'confirmModal'].includes(val) ? false : '';
      } else {
        this[formName].fields[field][val] = val === 'isDirty' ? true : ['showLoader', 'confirmModal'].includes(val) ? false : '';
      }
    });
    this.currTime = +new Date();
  }
}
