import request from 'superagent';

/**
 * @desc Service to make asynchronous API calls from system
 */
export class Api {
  uploadOnS3 = (url, file, type = 'text/plain') => (
    new Promise((resolve, reject) => {
      request
        .put(`${url}`)
        .set('Content-Type', type) // File upload (Binary Mode)
        .set('x-amz-acl', 'bucket-owner-full-control')
        .send(file)
        .end((err, data) => {
          if (err) {
            reject(err);
          }
          resolve(data);
        });
    })
  )
}

export default new Api();
