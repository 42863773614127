import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import cookie from 'react-cookies';
import { Button, Form, Divider } from 'semantic-ui-react';
import { WFHModal } from '../../../theme/shared';
import AssetImage from '../../shared/AssetImage';
import FromInput from '../../../theme/form/src/FormInput';
import { get } from 'lodash';

class DevPassProtected extends Component {
  state = { password: '', error: '', previewPassLoader: false };

  constructor(props) {
    super(props);
    this.state = { password: '', error: '' };
  }

  submit = async () => {
    this.setState({ previewPassLoader: true });
    cookie.save('DEV_PREVIEW_PASSWORD', this.state.password, { maxAge: 86400 });
    const valid = await this.props.authStore.validateDevPass();
    if (valid) {
      this.props.authStore.setDevAppAuthStatus(true);
      const url = this.props.uiStore.passwordPreviewURL || '/';
      this.props.history.push(url);
      this.props.uiStore.setFieldValue('passwordPreviewURL', null);
      // window.location.reload();
      this.setState({ previewPassLoader: false });
    } else {
      this.setState({ error: 'Entered password is invalid, please try again.', previewPassLoader: false });
    }
  }

  render() {
    const { brandMeta } = this.props.authStore;
    return (
      <>
        <WFHModal
          className="auth-modal"
          size="tiny"
          open
          centered
          disableCloseIcon
        >
          <AssetImage className="mb-30" centered path="ordrs_portal.png" width="300" alt={get(brandMeta, 'brandTitle') || 'LiveSmart'} />
          <Divider hidden />
          <Form onSubmit={this.submit}>
            <FromInput
              changed={e => this.setState({ password: e.target.value, error: '' })}
              fluid
              fielddata={{
                name: 'password',
                label: 'Password',
                value: this.state.password,
                placeHolder: 'Please enter password here',
                error: this.state.error,
                rule: 'required'
              }}
              togglePassword
              type='password'
              autoFocus
              name='password'
              error={this.state.error}
              showError
            />
            <div className="center-align">
              <Button disabled={!this.state.password} primary size="large" className="very relaxed" loading={this.state.previewPassLoader}>Log in</Button>
            </div>
          </Form>
        </WFHModal>
      </>
    );
  }
}

export default inject('authStore', 'uiStore')(withRouter(observer(DevPassProtected)));
