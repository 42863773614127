import React, { Component } from 'react';
import 'react-phone-number-input/style.css';
import { get } from 'lodash';
// import PhoneInput from 'react-phone-number-input';
import Input from 'react-phone-number-input/input';
import { parsePhoneNumber } from 'react-phone-number-input';
import { Form } from 'semantic-ui-react';
import { FieldError } from '../../shared';

class PhoneNumber extends Component {
  state = { showError: true };
  triggerError = (val) => {
    this.setState({ showError: val });
  }
  getPhoneNumberOnly = phone => {
    const phoneNumber = phone ? parsePhoneNumber(phone) : phone;
    return get(phoneNumber, 'nationalNumber') || '';
  }
  render() {
    const { props } = this;
    const {
      label,
      error,
      placeHolder,
      value,
      rule,
    } = props.fielddata;
    const { displayMode, readOnly, classes, noAnimate } = props;
    const fieldClass = `${props.containerClassName || ''} ${displayMode ? ' display-only' : ''} ${classes}`;
    return (
      <Form.Field
        width={props.containerWidth || false}
        className={`${!noAnimate ? 'animate-input' : fieldClass}`}
        error={(!!error && this.state.showError) || (!!error && props.showError)}
      >
        {noAnimate && !props.isHideLabel && label !== '' &&
          <label>
            {`${props.label || label}${rule.includes('required') ? '*' : ''}`}
          </label>
        }
        <div className="ui fluid input">
          <Input
            // maxLength={15}
            country="US"
            placeholder={(displayMode || readOnly) ? '' : (placeHolder || 'Enter phone number')}
            onChange={
              (value) => {
                props.changed(this.getPhoneNumberOnly(value));
                this.triggerError(props.showError || false);
              }}
            onBlur={
              () => {
                this.triggerError(true);
                if (props.onblur) {
                  this.props.onblur((this.getPhoneNumberOnly(value)))
                }
              }
            }
            readOnly={displayMode}
            {...props}
            value={value ? `+1${value}` : ''}
          />
          {/* <input /> */}
          {!noAnimate && !props.isHideLabel && (label !== '' || props.label !== '')
            && (
              <label>
                {`${props.label || label}${rule.includes('required') ? '*' : ''}`}
              </label>
            )
          }
          {/* </Input> */}
        </div>
        {((error && this.state.showError) || (error && props.showError)) &&
          <FieldError error={error} />
        }
      </Form.Field>
    );
  }
}

export default PhoneNumber;
