import React from 'react';
import { Modal, Loader, Dimmer } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { Utilities as Utils } from '../../../helper';

const WFHModal = (props) => {
  const { closeOnDimmerClick, onClose, closeRedirectPath, className, disableCloseIcon, actions, size, modalContentClass, useMountNode, header, centered, open, loading, loaderText } = props;
  const onCloseHandel = () => {
    props.history.push(closeRedirectPath || '/');
  }
  const mountNode = Utils.customModalWrapper();
  return (
    <Modal
      size={size || 'large'}
      open={open}
      mountNode={!useMountNode ? mountNode : false}
      closeOnDimmerClick={Boolean(closeOnDimmerClick)}
      closeOnRootNodeClick={Boolean(closeOnDimmerClick)}
      // dimmer="inverted"
      onClose={onClose || onCloseHandel}
      className={`${props.isLoading && 'dimmer-visible'} ${className}`}
      closeIcon={!disableCloseIcon}
      {...props}
      // basic
      centered={!!centered}
    >
      {header && <Modal.Header>{header}</Modal.Header>}
      <Modal.Content className={modalContentClass}>
        <Dimmer active={loading}>
          <Loader size='medium'>{loaderText || 'Loading...'}</Loader>
        </Dimmer>
        {props.children}
      </Modal.Content>
      {
        actions
        && (
          <Modal.Actions>
            {actions}
          </Modal.Actions>
        )
      }
    </Modal>
  );
};
export default withRouter(WFHModal);
