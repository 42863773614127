import React, { Component } from 'react';
import { get } from 'lodash';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import ValidateKit from '../../shared/kit/ValidateKit';
import UserDetails from '../../shared/kit/UserDetails';
import IntakeForm from './components/IntakeForm';

class Register extends Component {
  state = {
    // VALIDATE_KIT|USER_DETAILS
    nextStep: 'VALIDATE_KIT',
  }
  constructor(props) {
    super(props);
    const { kitStore } = this.props;
    kitStore.resetForm('KIT_FRM');
    kitStore.resetForm('USERS_DETAILS_FRM');
  }

  stepHandler = (nextStep) => {
    this.setState({ nextStep })
  }

  render() {
    const { nextStep } = this.state;
    const { brandMeta } = this.props.authStore;
    const title = get(brandMeta, 'rkPersonalTitle') || false;
    const description = get(brandMeta, 'rkPersonalDesc') || false;
    const themeClass = get(brandMeta, 'brandsTheme') || 'live-smart';
    const color = get(brandMeta, 'headerColor') ? get(brandMeta, 'headerColor') : themeClass === 'live-smart' ? '#170c76' : '#484d69';
    return (
      <section className="full-section d-flex aic jcc">
        <div className="auth-form-wrap">
          {nextStep === 'VALIDATE_KIT' && <ValidateKit registerKit stepHandler={this.stepHandler} />}
          {nextStep === 'USER_DETAILS' && <UserDetails registerKit stepHandler={this.stepHandler} title={title} description={description} />}
          {nextStep === 'INTAKE_FORM_DETAILS' && <IntakeForm registerKit stepHandler={this.stepHandler} title={title} description={description} color={color}/>}
        </div>
      </section>
    );
  }
}
export default inject('authStore', 'kitStore')(withRouter(observer(Register)));
