import React from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter, Switch, Route } from 'react-router-dom';
import { InlineLoader, SuspenseBoundary } from '../../theme/shared';
import Login from '../auth/containers/Login';
import Register from '../auth/containers/Register';
import ForgotPassword from '../auth/containers/ForgotPassword';

class Public extends React.Component {
  constructor(props) {
    super(props);
    if (props.authStore.isUserLoggedIn) {
      props.history.push('/dashboard');
    }
  }
  componentDidUpdate() {
    if (this.props.authStore.isUserLoggedIn) {
      this.props.history.push('/dashboard');
    }
  }
  render() {
    const { navStore, match } = this.props;
    const routeArr = navStore.myPublicRoutes || [];
    return (
      <>
        <SuspenseBoundary fallback={<InlineLoader styledAs={{ marginTop: '100px' }} />}>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/register" component={Register} />
            <Route path="/forgot-password" component={ForgotPassword} />
            {routeArr.map(item => {
              const CurrentComponent = item.component;
              return (
                <Route
                  exact={!!item.exact}
                  key={item.to}
                  path={`/${item.to}`}
                  render={props => (
                    <CurrentComponent
                      refLink={match.url}
                      {...props}
                    />
                  )}
                />
              )})}
          </Switch>
        </SuspenseBoundary>
      </>
    );
  }
}

export default inject('navStore', 'authStore')(withRouter(observer(Public)))

