import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Form, Icon, Input, Button } from 'semantic-ui-react';
import { FieldError } from '../../shared';
import { get } from 'lodash';

class FormInput extends Component {
  state = { showError: false, showPassword: false };

  constructor(props) {
    super(props);
    this.textInput = React.createRef();
    if (props.triggerError && props.fielddata.value !== '') {
      this.setState({ showError: props.fielddata.error !== undefined });
    }
  }

  componentDidMount() {
    if (this.props.autoFocus) {
      this.textInput.current.focus();
    }
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  triggerError = (val) => {
    this.setState({ showError: val });
  }

  change = (e) => {
    const { dataid, lowercase } = this.props;
    const value = this.props.fielddata && this.props.fielddata.rule && this.props.fielddata.rule.includes('email') ? e.target.value.trim() : e.target.value;
    this.props.changed(e, { name: e.target.name, value: (lowercase ? value.toLowerCase() : value), dataid });
  }

  render() {
    const { props } = this;
    const {
      label,
      error,
      placeHolder,
      defaultValue,
      value,
      rule,
      maxLength,
    } = props.fielddata;
    const { displayMode, type, togglePassword, icon, noAnimate, isColorPicker, fieldClasses } = props;
    const fieldClass = `${props.containerClassName || ''} ${displayMode ? ' display-only' : ''}`;
    return (
      <Form.Field
        width={props.containerWidth || false}
        className={`${!noAnimate ? 'animate-input' : fieldClass} ${fieldClasses} ${isColorPicker ? 'mb-0' : ''}`}
        error={(!!error && this.state.showError) || (!!error && props.showError)}
      >
        {noAnimate && !props.isHideLabel && (label !== '' || props.label !== '')
          && (
            <label>
              {`${props.label || label}${rule.includes('required') ? '*' : ''}`}
            </label>
          )
        }
        {props.type === 'password'
          && <input style={{ opacity: 0, position: 'absolute', width: 0 }} tabIndex={-1} value="something" />
        }
        <Input
          fluid
          autoComplete="nope"
          maxLength={(props.maxLength || maxLength) || false}
          placeholder={(displayMode) ? 'N/A' : placeHolder}
          // defaultValue={defaultValue}
          defaultValue={props.defaultValue ? props.defaultValue : defaultValue}
          icon={(icon || togglePassword) && <Icon onClick={togglePassword ? this.handleClickShowPassword : false} name={togglePassword ? (this.state.showPassword ? 'eye' : 'eye slash') : icon} link />}
          onChange={
            (e) => {
              this.change(e);
              this.triggerError(props.showError || false);
            }}
          onBlur={
            (e) => {
              e.target.value = e.target.value ? e.target.value.trim() : e.target.value;
              this.change(e);
              this.triggerError(true);
              if (props.onblur) {
                this.props.onblur(e.target.value);
              }
            }
          }
          readOnly={displayMode}
          {...props}
          type={togglePassword && this.state.showPassword ? 'text' : (type || 'text')}
          value={value || props.defaultValue ||''}
          label={props.prefix || false}
          ref={this.textInput}
        >
          <input />
          {get(props, 'buttonProps') ? <Button {...get(props, 'buttonProps')} /> : null}
          {!noAnimate && !props.isHideLabel && (label !== '' || props.label !== '')
            && (
              <label>
                {`${props.label || label}${rule.includes('required') ? '*' : ''}`}
              </label>
            )
          }
          {(icon || togglePassword) && <Icon onClick={togglePassword ? this.handleClickShowPassword : false} name={togglePassword ? (this.state.showPassword ? 'eye' : 'eye slash') : icon} link />}
        </Input>
        {((error && this.state.showError) || (error && props.showError))
          && <FieldError error={error} />
        }
      </Form.Field>
    );
  }
}

export default inject('uiStore')(observer(FormInput));