import gql from 'graphql-tag';

export const sendAlertToAdminFromClient = gql`
  mutation sendAlertToAdminFromClient($payload: String) {
    sendAlertToAdminFromClient(payload: $payload)
  }
`;

export const uploadObject = gql`
  mutation uploadObject($identifier: String!, $payload: UploadObjectPayloadInputType!, $resourceId: String!, $type: FileTypeEnum!, $subType: String) {
    uploadObject(
      identifier: $identifier
      payload: $payload
      resourceId: $resourceId
      type: $type
      subType: $subType
    ) {
      id
      status
      signedUrl
      storageDetails {
        url
      }
    }
  }
`;

export const salesLead = gql`
  mutation salesLead($type: SalesLeadTypeEnum!, $meta: String!) {
    salesLead(type: $type, meta: $meta)
  }
`;