import { makeObservable, observable, action } from 'mobx';
import DataModelStore from './dataModelStore';

export class UiStore extends DataModelStore {
  constructor() {
    super();
    makeObservable(this, {
      responsiveVars: observable,
      pusherVisible: observable,
      passwordPreviewURL: observable,
      errors: observable.ref,
      setErrors: action,
    });
  }
  responsiveVars = {};

  pusherVisible = false;

  passwordPreviewURL = null;

  errors = undefined;

  setErrors(errors) {
    this.errors = errors;
  }

}

export default new UiStore();