import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Divider, Form, Header } from 'semantic-ui-react';
import formHOC from '../../../../theme/form/formHOC';
import { get } from 'lodash';


const metaInfo = {
  store: 'authStore',
  form: 'CHANGE_PASS_FRM',
  resetForm: true,
  resetErrors: true,
};

class UpdatePassword extends Component {
  state = { error: null, loading: false }
  onSubmit = async () => {
    try {
      this.setState({ error: null, loading: true });
      const { authStore } = this.props;
      const res = await authStore.changeMyPassword();
      if (get(res, 'status') === 'error') {
        this.setState({ error: res });
      } else {
        authStore.resetForm('CHANGE_PASS_FRM');
      }
      this.setState({ loading: false });
    } catch {
      this.setState({ loading: false });
    }
  }

  render() {
    const { smartElement, authStore } = this.props;
    const { currentScore, brandMeta } = authStore;
    const themeClass = get(brandMeta, 'brandsTheme') || 'live-smart';
    const color = get(brandMeta, 'headerColor') ? get(brandMeta, 'headerColor') : themeClass === 'live-smart' ? '#170c76' : '#484d69';
    return (
      <>
        <Header 
          as="h2" 
          className="mb-30"
          style={{ 'color': get(brandMeta, 'titleTextColor') ? get(brandMeta, 'titleTextColor') : themeClass === 'live-smart' ? '#170c76' : '#484d69'}}
        >
          Update Password</Header>
        <Divider section />
        <Form error onSubmit={this.onSubmit}>
          {smartElement.Input('oldPassword', { fieldClasses: 'mb-40', size: 'large', togglePassword: true, type: 'password' })}
          {['password', 'retypePassword'].map(field => (
            smartElement.FormPasswordStrength(field, { addonClasses: 'large', togglePassword: true, type: 'password' })
          ))}
          {this.state.error ? smartElement.Errors({ errors: [this.state.error] }) : ''}
          <div className="right-align mt-30">
            {smartElement.Button({ color, content: 'Change Password', addOnDisabled: currentScore < 2, loading: this.state.loading })}
          </div>
        </Form>
      </>
    );
  }
}
export default withRouter(formHOC(observer(UpdatePassword), metaInfo));
