import React from 'react';
import ReactDOM from 'react-dom';
import { configure } from 'mobx';
import { Provider } from 'mobx-react';
import { BrowserRouter } from 'react-router-dom';
import ReactDependentScript from 'react-dependent-script';
import * as stores from './services/stores';
import * as serviceWorker from './serviceWorker';
import App from './App';
import { REACT_APP_DEPLOY_ENV } from './constants/common';
// import { REACT_APP_DEPLOY_ENV, REACT_APP_GOOGLE_PLACE_API_KEY } from './constants/common';
import { ErrorBoundary, Utilities as Utils } from './helper';

configure({ enforceActions: true });

window.logger = Utils.logger;

if (['localhost', 'demo', 'dev', 'review'].includes(REACT_APP_DEPLOY_ENV)) {
  window.APP_STATE = stores;
}

ReactDOM.render(
  <Provider {...stores}>
    <BrowserRouter>
      <ErrorBoundary>
        <ReactDependentScript
          scripts={[
            `https://maps.googleapis.com/maps/api/js?key=AIzaSyBvKFCcWjBr02Px34HFqAMLgsc114mRj24&libraries=places`,
          ]}
        >
          <App />
        </ReactDependentScript>
      </ErrorBoundary>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
