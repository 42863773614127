import React, { Component } from 'react';
import { get } from 'lodash';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Form, Header, Icon, Button } from 'semantic-ui-react';
import formHOC from '../../../theme/form/formHOC';


const metaInfo = {
  store: 'authStore',
  form: 'SIGNUP_FRM',
  resetErrors: true,
};

class SignUp extends Component {
  state = { loading: false }
  onSubmit = async () => {
    try {
      this.setState({ loading: true });
      const { auth } = this.props.authStore;
      const response = await auth();
      if (['LOGIN'].includes(get(response, 'state'))) {
        this.props.stepHandler('USER_DETAILS');
      } else if (['SIGNUP'].includes(get(response, 'state')) && get(response, 'status') === 'STARTED') {
        this.props.stepHandler('VERIFY_OTP');
      }
      this.setState({ loading: false });
    } catch {
      this.setState({ loading: false });
    }
  }

  render() {
    const { smartElement, authStore } = this.props;
    const { currentScore, brandMeta } = authStore;
    const themeClass = get(brandMeta, 'brandsTheme') || 'live-smart';
    const color = get(brandMeta, 'headerColor') ? get(brandMeta, 'headerColor') : themeClass === 'live-smart' ? '#170c76' : '#484d69';
    return (
      <>
        <Header 
          as="h2" 
          className="mb-30"
          style={{ 'color': get(brandMeta, 'titleTextColor') ? get(brandMeta, 'titleTextColor') : themeClass === 'live-smart' ? '#170c76' : '#484d69'}}
        >
          {get(brandMeta, 'signup2Title') || 'Account Details'}
          {get(brandMeta, 'signup2Desc') ? <Header.Subheader>{get(brandMeta, 'signup2Desc')}</Header.Subheader> : ''}
        </Header>
        <Form error onSubmit={this.onSubmit}>
          {smartElement.Input('email', {fieldClasses: 'mb-40', size: 'large', autoFocus: true })}
          {smartElement.FormPasswordStrength('password', { addonClasses: 'large', type: 'password', togglePassword: true })}
          {smartElement.Input('retypePassword', { type: 'password', size: 'large', togglePassword: true })}
          {smartElement.Errors()}
          <div className="center-align mt-30">
            <Button secondary style={{ borderColor: color }} onClick={() => this.props.stepHandler('VALIDATE_KIT')} to="/dashboard"><Icon name="left arrow" /> Back </Button>
            {/* {smartElement.Button({ color, content: <><Icon name="chevron circle left" /> Back</>, type: 'button', disabled: this.state.loading || false, onClick: () => this.props.stepHandler('VALIDATE_KIT') })} */}
            {smartElement.Button({ color, content: <>Next <Icon name="chevron circle right" /></>, addOnDisabled: currentScore < 2, loading: this.state.loading })}
          </div>
        </Form>
      </>
    );
  }
}
export default withRouter(formHOC(observer(SignUp), metaInfo));
