import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { Divider, Form, Header, Icon } from 'semantic-ui-react';
import formHOC from '../../../theme/form/formHOC';
import { get } from 'lodash';

const metaInfo = {
  store: 'kitStore',
  form: 'USERS_DETAILS_FRM',
  resetErrors: true,
};

class UserDetails extends Component {
  state = { loading: false }
  constructor(props) {
    super(props);
    this.discardChanges();
    this.props[metaInfo.store].setIntakeFormDetails();
  }
  onSubmit = async () => {
    try {
      this.setState({ loading: true });
      const { kitStore, userStore, history, registerKit } = this.props;
      if (registerKit) {
        const { isIntakeFormExists } = kitStore;

        if (isIntakeFormExists) {
          // [STEP] :: Dynamic Form Questions:
          this.props.stepHandler('INTAKE_FORM_DETAILS');
          this.setState({ loading: false });
        } else {
          const res = await kitStore.portalRegisterKit();
          this.setState({ loading: false });
          if (res) {
            if (!get(userStore.currentUser, 'phone')) {
              await userStore.updateProfile();
            }
            history.push('/dashboard');
          }
        }
      } else {
        await userStore.updateProfile();
        this.discardChanges();
        this.setState({ loading: false });
      }
    } catch {
      this.setState({ loading: false });
    }
  }

  discardChanges = () => {
    const { userStore, kitStore, registerKit } = this.props;
    kitStore.setFormData('USERS_DETAILS_FRM', userStore.currentUser);
    if (!registerKit) {
      kitStore.setFormKeyData('USERS_DETAILS_FRM', 'toc', 'optional', 'rule');
    } else {
      kitStore.setFormKeyData('USERS_DETAILS_FRM', 'toc', 'accepted', 'rule');
    }
  }

  render() {
    const { smartElement, registerKit, title, description, authStore, kitStore } = this.props;
    const { isIntakeFormExists } = kitStore;
    const { brandMeta } = authStore;
    const themeClass = get(brandMeta, 'brandsTheme') || 'live-smart';
    const color = get(brandMeta, 'headerColor') ? get(brandMeta, 'headerColor') : themeClass === 'live-smart' ? '#170c76' : '#484d69';
    return (
      <>
        <Header 
          as="h2" 
          className="mb-30"
          style={{ 'color': get(brandMeta, 'titleTextColor') ? get(brandMeta, 'titleTextColor') : themeClass === 'live-smart' ? '#170c76' : '#484d69'}}
        >
          {title || 'Personal Info'}
          {description ? <Header.Subheader>{description}</Header.Subheader> : null}
        </Header>
        <Divider section />
        <Form error onSubmit={this.onSubmit}>
          <Form.Group widths='equal'>
            {smartElement.Input('firstName', { autoFocus: registerKit })}
            {smartElement.Input('lastName')}
          </Form.Group>
          <Form.Group widths='equal'>
            {smartElement.Masked('dob', { dateOfBirth: true })}
            {smartElement.FormDropDown('gender')}
            {smartElement.PhoneNumber('phoneNumber')}
          </Form.Group>
          {smartElement.Address({ noAnimate: true, streetTwo: true })}
          {registerKit ? smartElement.FormCheckBoxOrRadio('toc', { singleCheck: true, linkLabelWrapper: get(brandMeta, 'tocUrl') || null }) : null}
          {smartElement.Errors()}
          <div className="right-align mt-30">
            {!registerKit ? smartElement.Button({ color, content: 'Discard Changes', secondary: true, type: 'button', disabled: this.state.loading || false, onClick: this.discardChanges }) : ''}
            {registerKit ? smartElement.Button({ color, content: <><Icon name="left arrow" /> Back</>, type: 'button', disabled: this.state.loading || false, onClick: () => this.props.stepHandler('VALIDATE_KIT') }) : ''}
            {smartElement.Button({ color, content: isIntakeFormExists ? 'Next' : 'Submit', loading: this.state.loading })}
          </div>
        </Form>
      </>
    );
  }
}
export default inject('userStore', 'authStore')(withRouter(formHOC(observer(UserDetails), metaInfo)));
