import React from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter, Route, Switch } from 'react-router-dom';
import { InlineLoader, SuspenseBoundary } from '../../theme/shared';

class Private extends React.Component {
  constructor(props) {
    super(props);
    if (!props.authStore.isUserLoggedIn) {
      props.history.push('/login');
    }
  }
  render() {
    const { navStore, match } = this.props;
    const routeArr = navStore.myRoutes || [];
    return (
      <>
        <SuspenseBoundary fallback={<InlineLoader styledAs={{ marginTop: '100px' }} />}>
          <Switch>
            {routeArr.map(item => {
              const CurrentComponent = item.component;
              return (
                <Route
                  exact={!!item.exact}
                  key={item.to}
                  path={item.asRoot ? '/dashboard' : `/dashboard/${item.to}`}
                  render={props => (
                    <CurrentComponent
                      refLink={match.url}
                      {...props}
                    />
                  )}
                />
              )})}
          </Switch>
        </SuspenseBoundary>
      </>
    );
  }
}

export default inject('authStore', 'navStore')(withRouter(observer(Private)));