import { action, computed, makeObservable, observable, toJS } from 'mobx';
import { get, isEmpty, orderBy, filter, size } from 'lodash';
import DataModelStore from './dataModelStore';
import { checkKitId, allOrders, portalRegisterKit, orderDetails, getDownloadLink, downloadAllPdf } from '../../queries/kit';
import { FormValidator as FormHandle, FormMeta } from '../../../../helper';
import { uiStore, userStore } from '../../index';
// import { INTAKE_META } from '../../../../constants/titleMeta';

export class kitStore extends DataModelStore {
  constructor() {
    super({ checkKitId, allOrders, portalRegisterKit, orderDetails, getDownloadLink, downloadAllPdf });
    makeObservable(this, {
      kitOrders: observable,
      orderDetails: observable.ref,
      orderPdfDetails: observable,
      KIT_FRM: observable,
      USERS_DETAILS_FRM: observable,
      panelMeta: observable,
      isIntakeFormExists: observable,
      intakeFormDetails: observable.ref,
      DYNAMIC_PAYLOAD_FRM: observable.ref,
      formCurrentIndex: observable,
      dynamicFormLength: observable,
      defaultPanelContext: observable,
      getKitOrders: computed,
      getIntakeFormDetails: computed,
      getIntakeFormList: computed,
      setIntakeFormDetails: action,
      formChangeForPlugin: action,
      resetDynamicForm: action,
      checkFormQuestionsExistence: action,
    });
  }

  kitOrders = null;

  orderDetails = null;

  orderPdfDetails = null;

  panelMeta = null;

  isIntakeFormExists = false;

  intakeFormDetails = [];

  formCurrentIndex = 0;

  dynamicFormLength = 0;

  DYNAMIC_PAYLOAD_FRM = {};

  defaultPanelContext = null;


  KIT_FRM = FormHandle.prepareFormObject({
    kitId: { ...FormMeta.kitId },
    toc: { ...FormMeta.toc, skipField: true },
  });

  USERS_DETAILS_FRM = FormHandle.prepareFormObject({
    firstName: { ...FormMeta.firstName },
    lastName: { ...FormMeta.lastName },
    dob: { ...FormMeta.dob, maskFormattedChange: 'formatted', format: '##/##/####' },
    gender: { ...FormMeta.gender },
    phoneNumber: { ...FormMeta.phoneNumber, keyAlias: 'phone' },
    // email: { ...FormMeta.email, keyAlias: 'email' },
    street: { ...FormMeta.street, objRef: 'address', objRefOutput: 'address', keyAlias: 'addressLine1' },
    streetTwo: { ...FormMeta.streetTwo, objRef: 'address', objRefOutput: 'address', keyAlias: 'addressLine2' },
    city: { ...FormMeta.city, objRef: 'address', objRefOutput: 'address', },
    state: { ...FormMeta.state, objRef: 'address', objRefOutput: 'address', },
    zip: { ...FormMeta.zip, objRef: 'address', objRefOutput: 'address', },
    toc: { ...FormMeta.toc, skipField: true },
  });

  checkKitId = async () => {
    try {
      const { kitId } = FormHandle.evaluateFormData(this.KIT_FRM.fields);
      const data = await this.executeQuery({
        clientType: 'PUBLIC',
        query: 'checkKitId',
        variables: { kitId },
        // fetchPolicy: 'network-only',
      });
      if (get(data, 'checkKitId.contextDefaults')) {
        this.setFieldValue('defaultPanelContext', get(data, 'checkKitId.contextDefaults'))
      }
      return { isValid: !!get(data, 'checkKitId.valid'), panelMeta: get(data, 'checkKitId.panelMeta') }
    } catch (err) {
      window.logger({ params: err });
      uiStore.setErrors(this.errorObj(err));
    }
  }

  portalRegisterKit = async () => {
    try {
      const userDetails = FormHandle.evaluateFormData(this.USERS_DETAILS_FRM.fields);
      const { kitId } = FormHandle.evaluateFormData(this.KIT_FRM.fields);
      const userInfo = userStore.currentUser;
      let contextDetails = null;
      if (this.isIntakeFormExists) {
        const userIntakeDetails = FormHandle.evaluateFormData(this.DYNAMIC_PAYLOAD_FRM);
        let intakeFormDetails = {};
        Object.keys(userIntakeDetails).forEach((elem, key) => {
          // intakeFormDetails[key] = { ...FormHandle.evaluateFormData(this.DYNAMIC_PAYLOAD_FRM[elem].fields) }
          intakeFormDetails = { ...intakeFormDetails, ...FormHandle.evaluateFormData(this.DYNAMIC_PAYLOAD_FRM[elem].fields) }
        });

        let DynamicFormDetails = { intakeForm: intakeFormDetails };

        if (!isEmpty(this.defaultPanelContext)) {
          const contextInfo = JSON.parse(this.defaultPanelContext);
          DynamicFormDetails = { ...DynamicFormDetails, defaultContext: contextInfo };
        }
        contextDetails = JSON.stringify(DynamicFormDetails);
      } else if (!isEmpty(this.defaultPanelContext)) {
        const contextInfo = JSON.parse(this.defaultPanelContext);
        contextDetails = JSON.stringify({ defaultContext: contextInfo });
      }
      const email = get(userInfo, 'email');
      const patientDetails = contextDetails ? { ...userDetails, email, context: contextDetails } : { ...userDetails, email };
      await this.executeMutation({
        mutation: 'portalRegisterKit',
        variables: { patient: patientDetails, kitId },
      });
      return true;
    } catch (err) {
      window.logger({ params: err });
      uiStore.setErrors(this.errorObj(err));
      return Promise.reject(this.errorObj(err));
    }
  };

  allOrders = async () => {
    try {
      const data = await this.executeQuery({
        query: 'allOrders',
        fetchPolicy: 'network-only',
      });
      this.setFieldValue('kitOrders', get(data, 'orders'));
    } catch (err) {
      window.logger({ params: err });
      uiStore.setErrors(this.errorObj(err));
    }
  }

  getOrderDetails = async (orderId) => {
    try {
      this.setFieldValue('orderDetails', null);
      this.setFieldValue('orderPdfDetails', null);
      const data = await this.executeQuery({
        query: 'orderDetails',
        variables: { orderId },
      });
      this.setFieldValue('orderDetails', get(data, 'order'));
      const pdfDetail = get(data, 'order.resultsPdfAttachmentsInfo[0]');
      const pdfId = get(pdfDetail, 'fileId');
      if (pdfId) {
        await this.getDownloadLink(orderId, pdfId);
      }
      const parsedData = this.jsonParser(['meta'], get(data, 'order.panel'));
      this.setFieldValue('panelMeta', get(parsedData, 'meta'));
      return pdfId;
    } catch (err) {
      window.logger({ params: err });
      uiStore.setErrors(this.errorObj(err));
    }
  }

  getDownloadLink = async (orderId, fileId) => {
    try {
      const data = await this.executeQuery({
        query: 'getDownloadLink',
        variables: { orderId, fileId },
      });
      this.setFieldValue('orderPdfDetails', get(data, 'getDownloadLink'));
    } catch (err) {
      window.logger({ params: err });
      uiStore.setErrors(this.errorObj(err));
    }
  }

  downloadAllPdf = async (id) => {
    try {
      const data = await this.executeMutation({
        mutation: 'downloadAllPdf',
        variables: { id },
      });
      window.open(get(data, 'downloadAllPdf'));
    } catch (err) {
      window.logger({ params: err });
      uiStore.setErrors(this.errorObj(err));
    }
  }

  get getKitOrders() {
    return get(this.kitOrders, '[0]') ? this.kitOrders : [];
  }

  /* [START] :: Dynamic Form Builder Functions */

  setIntakeFormDetails = (param = undefined) => {
    if (param) {
      try {
        const intakeFormMeta = JSON.parse(param);
        if (!isEmpty(get(intakeFormMeta, 'intakeForm'))) {
          const panelMeta = get(intakeFormMeta, 'intakeForm') || [];
          this.setFieldValue('intakeFormDetails', panelMeta);
        }
      } catch (err) {
        console.log('ERROR :: kitStore :: setIntakeFormDetails ==>', err);
      }
      this.checkFormQuestionsExistence();
    }
  }

  checkFormQuestionsExistence = () => {
    const intakeForms = this.getIntakeFormList;
    if (get(intakeForms, '[0]')) {
      const validQuestionArr = [];
      intakeForms.forEach((val, key) => {
        if (!isEmpty(val)) {
          const formData = val;
          const formQuestions = get(formData, 'questions') || [];
          if (formQuestions.length > 0) {
            formQuestions.forEach(element => {
              if (get(element, 'status') === 'PUBLISHED') {
                validQuestionArr.push(element);
              }
            });
          }
        }
      });
      if (validQuestionArr.length > 0) {
        this.setFieldValue('isIntakeFormExists', true);
      }
    }
  }

  get getIntakeFormDetails() {
    return (this.intakeFormDetails && toJS(this.intakeFormDetails)) || [];
  }

  get getIntakeFormList() {
    let intakeFormArr = this.getIntakeFormDetails;
    let intakeForms = [];
    intakeForms = [...intakeForms, ...orderBy(filter(intakeFormArr, i => i.page > 0), ['page'], ['asc'])];
    return intakeForms;
  }


  prepareDynamicForm = () => {
    const intakeForms = this.getIntakeFormList;
    if (get(intakeForms, '[0]')) {
      intakeForms.forEach((val, key) => {
        this.setFieldValue('DYNAMIC_PAYLOAD_FRM', {}, key);
        if (!isEmpty(val)) {
          const formData = val;
          const formQuestions = get(formData, 'questions') || [];
          let formMeta = {};
          if (formQuestions.length > 0) {
            formQuestions.forEach(element => {
              if (get(element, 'status') === 'PUBLISHED') {
                formMeta = { ...formMeta, ...this.pullValuesForDynamicInput(element) };
              }
            });
          }
          this.createDynamicFormFields(formMeta, key);
        }
      });
    }
  }

  createDynamicFormFields = (formMeta, form) => {
    // this.DYNAMIC_PAYLOAD_FRM[form] = {};
    const formFieldObj = FormHandle.prepareFormObject(formMeta, false, false, true);
    if (!isEmpty(get(formFieldObj, 'fields'))) {
      this.DYNAMIC_PAYLOAD_FRM[form] = formFieldObj;
    }
  }

  formChangeForPlugin = (e, res, form, subForm = false, additionalProps = {}) => {
    if (subForm) {
      this[form.parentForm][form.childForm] = FormHandle.onChange(this[form.parentForm][form.childForm], FormHandle.pullValues(e, res));
      this.currTime = +new Date();
    }
  }


  pullValuesForDynamicInput = (data) => {
    let dynamicFormElement = null;
    try {
      if (!isEmpty(data) && get(data, 'formElementMeta')) {
        const inputType = get(data, 'inputType');
        const questionDetails = get(data, 'questionLabel');
        const additionProps = !isEmpty(get(data, 'addonProps')) ? { addonProps: JSON.parse(get(data, 'addonProps')) } : null;
        const key = get(data, 'inputKeyName');
        dynamicFormElement = JSON.parse(get(data, 'formElementMeta'));
        dynamicFormElement[key] = { ...dynamicFormElement[key], type: inputType, question: questionDetails, ...additionProps };
      }
    } catch (err) {
      console.log('ERROR ==>', err);
    }
    return dynamicFormElement;
  };

  get getCurrentFormDetails() {
    const intakeForms = this.getIntakeFormList;
    const index = this.formCurrentIndex;
    return intakeForms[index];
  }

  getFormElement = (fieldKey, formProps, formObj) => {
    let formElement = '';
    const elementType = formProps.type;
    switch (elementType) {
      case 'TEXTAREA':
        // formElement = 'FormTextarea';
        formElement = 'Textarea';
        this.setFormDataForDynamicForm(formObj.parentForm, fieldKey, formProps.defaultValue, formObj.childForm);
        break;
      case 'DROPDOWN':
        // formElement = 'FormDropDown';
        formElement = 'FormDropDown';
        this.setFormDataForDynamicForm(formObj.parentForm, fieldKey, formProps.defaultValue, formObj.childForm);
        break;
      case 'CHECKBOX':
        formElement = 'FormCheckBoxOrRadio';
        const refVal = (Array.isArray(formProps.defaultValue)) ? '' : formProps.defaultValue;
        this.setFormDataForDynamicForm(formObj.parentForm, fieldKey, refVal, formObj.childForm);
        break;
      case 'RADIO':
        formElement = 'FormCheckBoxOrRadio';
        this.setFormDataForDynamicForm(formObj.parentForm, fieldKey, formProps.defaultValue, formObj.childForm);
        break;
        case 'DATE': // New case for DATE type
        formElement = 'FormDateInput';
        this.setFormDataForDynamicForm(formObj.parentForm, fieldKey, formProps.defaultValue, formObj.childForm);
        break;
      default:
        // formElement = 'FormInput';
        formElement = 'Input';
        this.setFormDataForDynamicForm(formObj.parentForm, fieldKey, formProps.defaultValue, formObj.childForm);
        break;
    }
    return formElement;
  }

  resetIntakeFormDetails() {
    this.setFieldValue('DYNAMIC_PAYLOAD_FRM', {});
    this.setFieldValue('formCurrentIndex', 0);
    this.setFieldValue('isPreview', false);
    this.setFieldValue('intakeFormDetails', []);
  }

  cleanUnwantedFormData = () => {
    const dynamicFormObj = { ...this.DYNAMIC_PAYLOAD_FRM };
    Object.keys(dynamicFormObj).forEach((val, key) => {
      if (val !== 'fields' || (val === 'fields' && !isEmpty(dynamicFormObj[val]))) {
        this.DYNAMIC_PAYLOAD_FRM[val] = { ...dynamicFormObj[val] };
      } else if (val === 'fields' && isEmpty(dynamicFormObj[val])) {
        delete this.DYNAMIC_PAYLOAD_FRM[val];
      }
    });
    this.setFieldValue('dynamicFormLength', size(this.DYNAMIC_PAYLOAD_FRM));
  }

  incrementCurrentIndex = () => {
    this.setFieldValue('formCurrentIndex', this.formCurrentIndex + 1);
  }

  decrementCurrentIndex = () => {
    this.setFieldValue('formCurrentIndex', this.formCurrentIndex - 1);
  }

  resetDynamicForm = () => {
    this.setFieldValue('formCurrentIndex', 0);
    this.setFieldValue('dynamicFormLength', 0);
    this.setFieldValue('intakeFormDetails', []);
    this.setFieldValue('isIntakeFormExists', false);
    this.setFieldValue('defaultPanelContext', null);
    this.setFieldValue('DYNAMIC_PAYLOAD_FRM', {});
  }
}

export default new kitStore();