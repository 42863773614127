import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Form, Icon, Button, Modal, Header } from 'semantic-ui-react';
import { has } from 'lodash';
import NumberFormat from 'react-number-format';
import InputMask from 'react-input-mask';
import { Link } from 'react-router-dom';
import { FieldError, PopUpModal } from '../../shared';

// const NumberFormatWrapped = props => (
//   <div className={props.wrapperClass}>
//     <NumberFormat {...props} />
//   </div>
// );


class MaskedInput extends Component {
  state = { showError: false };

  triggerError = (val) => {
    this.setState({ showError: val });
  }

  render() {
    const { props } = this;
    const {
      label,
      error,
      value,
      tooltip,
      rule,
      placeHolder,
    } = props.fielddata || props;
    const { displayMode, readOnly, allowNegative, uiStore, noAnimate } = props;
    const { isMobile } = uiStore.responsiveVars;
    const commonProps = {
      readOnly: displayMode,
      allowNegative: allowNegative || false,
      placeholder: (displayMode || readOnly) ? 'N/A' : placeHolder,
    };
    const fieldClass = `${props.containerClassName || ''} ${displayMode ? 'display-only' : ''}`;
    const CustomToolTip = ({ trigger }) => (
      <>
        {isMobile ? (
          <Modal className="mobile-tooltip" size="tiny" trigger={trigger} closeIcon>
            <Modal.Content>
              <Header as="h5">
                {label}
              </Header>
              <span>{tooltip}</span>
            </Modal.Content>
          </Modal>
        )
          : (
            <PopUpModal
              on="hover"
              hoverable={props.toolTipOnLabel ? false : props.hoverable}
              customTrigger={trigger}
              position={isMobile ? 'bottom center' : 'top center'}
              className={props.containerClassname}
              content={tooltip}
              showOnlyPopup={!isMobile}
              wide
            />
          )
        }
        {props.removed
          && (
            <Link to={props.linkTo} onClick={e => props.removed(e)}>
              <Icon className="close-circle" color="grey" />
            </Link>
          )
        }
      </>
    );
    const fieldLabel = (props.label
      && (props.asterisk && props.asterisk === 'true'
        ? `${props.label}*` : props.label))
      || (props.asterisk && props.asterisk === 'true' ? `${label}*` : label);
    return (
      <Form.Field
        error={this.props.forceError || (!!error && this.state.showError) || (!!error && props.showError)}
        className={`${!noAnimate ? 'animate-input' : fieldClass}`}
        width={props.containerWidth || false}
      >
        <div className={`ui input ${props.action ? 'action' : 'fluid'}`}>
          {noAnimate && !props.isHideLabel
            && (
              <label className={props.toolTipOnLabel}>
                {props.toolTipOnLabel ? <CustomToolTip trigger={<span className="popup-label">{fieldLabel}</span>} /> : fieldLabel}
                {!props.toolTipOnLabel && tooltip
                  && (
                    <>
                      <CustomToolTip trigger={<Icon className="help-circle" />} />
                    </>
                  )}
              </label>
            )
          }
          {props.action ? (
            <>
              <InputMask
                {...props}
                value={value}
                format={props.format}
                onChange={(e) => { props.changed(e); this.triggerError(props.showError || false); }}
                onBlur={() => this.triggerError(true)}
                error={(!!error && this.state.showError) || (!!error && props.showError)}
                placeholder={(displayMode || readOnly) ? 'N/A' : placeHolder}
                alwaysShowMask
                maskChar=" "
              />
              <Button
                className={props.actionClass}
                color={props.actionColor}
                onClick={() => props.clickOnAction()}
              >
                {props.actionLabel}
              </Button>
            </>
          ) : props.currency ? (
            <NumberFormat prefix="$" {...commonProps} maxLength={props.maxLength || 15} thousandSeparator {...props} value={value} onValueChange={(values) => { props.changed(values, props.name); this.triggerError(props.showError || false); }} onBlur={props.onblur ? () => { props.onblur(); this.triggerError(true); } : () => this.triggerError(true)} onKeyUp={props.onkeyup ? () => { props.onkeyup(); this.triggerError(props.showError || false); } : null} error={(!!error && this.state.showError) || (!!error && props.showError)} mask="_" decimalScale={props.disableDecimal ? 0 : 2} />
          ) : props.number ? (
            <NumberFormat {...commonProps} maxLength={props.maxLength || 10} {...props} value={value} onValueChange={(values) => { props.changed(values, props.name); this.triggerError(props.showError || false); }} onBlur={() => this.triggerError(true)} onKeyUp={props.onkeyup ? () => { props.onkeyup(); this.triggerError(props.showError || false); } : null} error={(!!error && this.state.showError) || (!!error && props.showError)} mask="_" decimalScale={0} />
          ) : props.percentage ? (
            <NumberFormat {...commonProps} maxLength={props.maxLength || 6} {...props} value={value} onValueChange={(values) => { props.changed(values, props.name); this.triggerError(props.showError || false); }} onBlur={() => this.triggerError(true)} error={(!!error && this.state.showError) || (!!error && props.showError)} mask="%" suffix="%" />
          ) : props.phoneNumber ? (
            <NumberFormat {...commonProps} type="tel" format={props.format} {...props} value={value} onValueChange={(values) => { props.changed(values, props.name); this.triggerError(props.showError || false); }} onBlur={() => this.triggerError(true)} error={(!!error && this.state.showError) || (!!error && props.showError)} />
          ) : props.phoneNumberDisplayMode ? (
            // <div className="ui huge fluid input">
            <NumberFormat {...commonProps} type="tel" format={props.format} {...props} value={value} onValueChange={(values) => { props.changed(values, props.name); this.triggerError(props.showError || false); }} onBlur={() => this.triggerError(true)} error={(!!error && this.state.showError) || (!!error && props.showError)} />
            // </div>
          ) : props.zip ? (
            <NumberFormat {...commonProps} type="tel" format="#####" {...props} value={value} onValueChange={(values) => { props.changed(values, props.name); this.triggerError(props.showError || false); }} onBlur={() => this.triggerError(true)} error={(!!error && this.state.showError) || (!!error && props.showError)} />
          ) : props.ssn ? (
            <NumberFormat className="fs-block" {...commonProps} type="tel" format="###-##-####" {...props} value={value} onValueChange={(values) => { props.changed(values, props.name); this.triggerError(props.showError || false); }} onBlur={() => this.triggerError(true)} error={(!!error && this.state.showError) || (!!error && props.showError)} />
          ) : props.dateOfBirth ? (
            <NumberFormat {...commonProps} type="text" format={props.format ? props.format : '##/##/####'} {...props} value={value} onValueChange={(values) => { props.changed(values, props.name); this.triggerError(props.showError || false); }} onBlur={() => this.triggerError(true)} onKeyUp={props.onkeyup ? () => { props.onkeyup(); this.triggerError(props.showError || false); } : null} error={(!!error && this.state.showError) || (!!error && props.showError)} />
          ) : props.taxId ? (
            <NumberFormat {...commonProps} type="tel" format="##-#######" {...props} value={value} onValueChange={(values) => { props.changed(values, props.name); this.triggerError(props.showError || false); }} onBlur={() => this.triggerError(true)} error={(!!error && this.state.showError) || (!!error && props.showError)} />
          ) : props.accountNumber ? (
            <NumberFormat {...commonProps} type="tel" format="#################" {...props} value={has(props, 'value') ? props.value : value} onValueChange={(values) => { props.changed(values, props.name); this.triggerError(props.showError || false); }} onBlur={() => this.triggerError(true)} />
          ) : props.routingNumber ? (
            <NumberFormat {...commonProps} type="tel" format="#########" {...props} value={has(props, 'value') ? props.value : value} onValueChange={(values) => { props.changed(values, props.name); this.triggerError(props.showError || false); }} onBlur={() => this.triggerError(true)} />
          ) : <NumberFormat {...commonProps} format="(###) ###-####" {...props} value={value} onValueChange={(values) => { props.changed(values, props.name); this.triggerError(props.showError || false); }} onBlur={() => this.triggerError(true)} error={(!!error && this.state.showError) || (!!error && props.showError)} mask="_" />
          }
          {!noAnimate && !props.isHideLabel && (label !== '' || props.label !== '')
            && (
              <label>
                {`${props.label || label}${rule && rule.includes('required') ? '*' : ''}`}
              </label>
            )
          }
        </div>
        {((error && this.state.showError && !props.showErrorOnField)
          || (error && props.showError && !props.showErrorOnField))
          && <FieldError error={error} />
        }
        {props.icon && <Icon {...props.icon} />}
      </Form.Field>
    );
  }
}

export default inject('uiStore')(observer(MaskedInput));