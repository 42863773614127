import React, { useEffect } from 'react';
import { get, map } from 'lodash';
import { Helmet } from 'react-helmet';
import { inject, observer } from 'mobx-react';

const OpenGraphTags = ({ metaTagsData, authStore }) => {
  const { brandMeta } = authStore;
  const favIcon = get(brandMeta, 'brandFavIcon.url') || null;
  const brandsTheme = get(brandMeta, 'brandsTheme') || 'live-smart';
  const updateFavIcon = () => {
    const favIconEle = document.getElementById('favicon');
    if (favIconEle && favIcon) {
      favIconEle.href = favIcon;
    }
  }
  useEffect(() => {
    updateFavIcon();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[favIcon])
  return (
    <Helmet>
      <title>{get(brandMeta, 'brandTitle') || (brandsTheme === 'live-smart' ? 'LiveSmart' : 'EBIEN')} Portal</title>
      <link rel="canonical" href={window.location.href} />
      {map((metaTagsData || []), d => (
        d.type === 'meta'
          ? <meta key={new Date().getTime()} name={d.name} content={d.content} />
          : d.type === 'ogTag'
            ? <meta property={d.property} key={d.type} content={d.content} />
            : d.type === 'title'
            ? <title>{d.content}</title>
            : null
      ))}
    </Helmet>
  );
};

export default inject('authStore')(observer(OpenGraphTags));
