import React from 'react';
import { observer } from 'mobx-react';
import { isCatchErrorBoundarySendEmail } from '../../constants/common';
import Helper from '../utilities/utility';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    let emailContent = error.stack;
    if (isCatchErrorBoundarySendEmail) {
      try {
        const params = {
          type: 'error',
          body: emailContent.toString(),
          subject: 'Triggered by error boundary',
          action: 'ErrorBoundary'
        };
        Helper.sendAlertEmail(params);
      } catch (e) {
        window.logger({ params: e });
      }
    } else {
      window.logger({ params: { error, type: typeof error } });
      window.logger({ params: info });
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-page">
          <h2>
            Oops! Something went wrong.
          </h2>
          <span>
            Hang tight -
            we&lsquo;ve notified the team, and we&lsquo;re taking you back to the homepage.<br />
            <a href={window.location.origin}>Click here to be redirected now</a>
          </span>
        </div>
      )
    }
    return this.props.children;
  }
}

export default (observer(ErrorBoundary));
