import { observable, action, makeObservable, flow, reaction, computed } from 'mobx';
import Amplify, { Auth } from 'aws-amplify';
import { get } from 'lodash';
import cookie from 'react-cookies';
import { GqlClient as client } from '../../../../api/gqlApi';
import awsExports from '../../../../constants/aws';
import { FormValidator as FormHandle, FormMeta, Utilities as Utils } from '../../../../helper';
import { auth, initiateForgotPassword, confirmForgotPassword } from '../../queries/auth';
import { changePassword } from '../../queries/user';
import { getBrandMeta } from '../../queries/brand';
import DataModelStore from './dataModelStore';
import { uiStore, userStore } from '../../index';
import { isProduction, isPreviewPasswordRequired } from '../../../../constants/common';

export class AuthStore extends DataModelStore {
  constructor() {
    super({ auth, initiateForgotPassword, confirmForgotPassword, getBrandMeta, changePassword });
    makeObservable(this, {
      isUserLoggedIn: observable,
      brandMeta: observable.ref,
      everLogsIn: observable,
      cognitoUser: observable,
      signUpDetails: observable.ref,
      appLoaded: observable.ref,
      LOGIN_FRM: observable,
      CHANGE_PASS_FRM: observable,
      SIGNUP_FRM: observable,
      OTP_FRM: observable,
      FORGOT_PASS_FRM: observable,
      RESET_PASS_FRM: observable,
      devAuth: observable,
      setUserLoggedIn: action,
      resetStoreData: action,
      setDevAppAuthStatus: action,
      validateDevPass: action,
      devPasswdProtection: computed,
    });
    reaction(
      () => this.authAction,
      (authAction) => {
        if (authAction === null) {
          this.authDispose();
        }
      },
    );
    Amplify.configure(awsExports);
    this.initLoad();
  }

  signUpDetails = null;

  brandMeta = null;

  cognitoUser = null;

  everLogsIn = localStorage.getItem('EVER_LOGS_IN') || false;

  userExistList = [];

  appLoaded = false;

  isUserLoggedIn = false;

  devAuth = {
    required: isPreviewPasswordRequired,
    authStatus: cookie.load('DEV_AUTH_TOKEN'),
  };

  LOGIN_FRM = FormHandle.prepareFormObject({
    email: { ...FormMeta.email },
    password: { ...FormMeta.password, rule: 'required' },
  });

  SIGNUP_FRM = FormHandle.prepareFormObject({
    email: { ...FormMeta.email },
    password: { ...FormMeta.password },
    retypePassword: { ...FormMeta.retypePassword },
  });

  OTP_FRM = FormHandle.prepareFormObject({
    code: { ...FormMeta.code },
  });

  FORGOT_PASS_FRM = FormHandle.prepareFormObject({
    email: { ...FormMeta.email },
  });

  RESET_PASS_FRM = FormHandle.prepareFormObject({
    confirmationCode: { ...FormMeta.code },
    password: { ...FormMeta.password },
    verify: { ...FormMeta.verify },
  });

  CHANGE_PASS_FRM = FormHandle.prepareFormObject({
    oldPassword: { ...FormMeta.oldPassword },
    password: { ...FormMeta.password },
    retypePassword: { ...FormMeta.retypePassword },
  });

  getBrandMeta = async (fetchPolicy) => {
    try {
      const data = await this.executeQuery({
        clientType: 'PUBLIC',
        query: 'getBrandMeta',
        fetchPolicy: fetchPolicy || false,
      });
      const parsedData = this.jsonParser(['meta'], get(data, 'getBrandMeta'));
      this.setFieldValue('brandMeta', get(parsedData, 'meta'));
      window.logger({ params: data });
      return get(data, 'getBrandMeta.meta');
    } catch (e) {
      window.logger({ params: e });
      return false;
    }
  }

  validateDevPass = async () => {
    try {
      const data = await this.getBrandMeta('network-only');
      if (data) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      window.logger({ params: err });
      return false;
    }
  }

  initLoad = async (login = false) => {
    this.setFieldValue('loading', true);
    try {
      await this.getBrandMeta();
      const currentUser = await Auth.currentSession();
      if (currentUser) {
        const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
        if (user) {
          this.setUserLoggedIn(true);
          await userStore.getProfile();
        }
      }
      this.setFieldValue('loading', false);
      this.setFieldValue('appLoaded', true);
      return;
    } catch (err) {
      uiStore.setErrors(this.errorObj(err));
      this.setFieldValue('loading', false);
      this.setFieldValue('appLoaded', true);
    }
  };

  auth = async (params) => {
    try {
      const payload = { login: false, otp: '', ...params };
      const { login, otp } = payload;
      const { email, password } = FormHandle.evaluateFormData(this[login ? 'LOGIN_FRM' : 'SIGNUP_FRM'].fields);
      const { code } = FormHandle.evaluateFormData(this.OTP_FRM.fields);
      const data = await this.executeMutation({
        mutation: 'auth',
        variables: { authDetails: { email: email.toLowerCase(), password: Utils.encryptPass(password) }, action: login ? 'LOGIN' : 'SIGNUP', code: otp ? code : '' },
        clientType: 'PUBLIC',
      });
      const authResponse = get(data, 'auth');
      window.logger({ params: authResponse });
      if (get(authResponse, 'status') === 'COMPLETE') {
        await this.login({ username: email.toLowerCase(), password: Utils.decryptPass(get(authResponse, 'auth')) });
      } else if (get(authResponse, 'status') === 'RESERVED') {
        uiStore.setErrors({ message: 'Email already taken. Please try with different email.' });
        return false;
      } else if (get(authResponse, 'status') === 'INVALID') {
        uiStore.setErrors({ message: 'Invalid credentials' });
        return false;
      }
      return { ...authResponse };
    } catch (err) {
      uiStore.setErrors(this.errorObj(err));
    }
  }

  login = flow(function*(params) {
    uiStore.setErrors(undefined);
    this.setFieldValue('loading', true);
    try {
      const user = yield Auth.signIn(params);
      this.setFieldValue('cognitoUser', user);
      if (get(user, 'signInUserSession')) {
        yield this.initLoad();
      }
      this.setFieldValue('loading', false);
      return true;
    } catch(err) {
      this.setFieldValue('loading', false);
      uiStore.setErrors(this.errorObj(err));
    }
  });

  changeMyPassword = async () => {
    uiStore.setErrors(undefined);
    try {
      const { oldPassword, password } = FormHandle.evaluateFormData(this.CHANGE_PASS_FRM.fields);
      await this.executeMutation({
        mutation: 'changePassword',
        variables: { oldPassword: Utils.encryptPass(oldPassword), newPassword: Utils.encryptPass(password) },
      });
      this.setFieldValue('loading', false);
    } catch (err) {
      this.setFieldValue('loading', false);
      return { status: 'error', message: get(this.errorObj(err), 'message') };
    }
  };

  forgotPassword = async () => {
    uiStore.setErrors(undefined);
    try {
      const { email } = FormHandle.evaluateFormData(this.FORGOT_PASS_FRM.fields);
      const data = await this.executeMutation({
        mutation: 'initiateForgotPassword',
        variables: { email: email.toLowerCase() },
        clientType: 'PUBLIC',
      });
      this.setFieldValue('loading', false);
      return !!get(data, 'initiateForgotPassword.status');
    } catch (err) {
      this.setFieldValue('loading', false);
      uiStore.setErrors(this.errorObj(err));
      return false;
    }
  };

  forgotPasswordSubmit = async () => {
    uiStore.setErrors(undefined);
    try {
      const { email } = FormHandle.evaluateFormData(this.FORGOT_PASS_FRM.fields);
      const { confirmationCode, password } = FormHandle.evaluateFormData(this.RESET_PASS_FRM.fields);
      const data = await this.executeMutation({
        mutation: 'confirmForgotPassword',
        variables: { email: email.toLowerCase(), confirmationCode, password: Utils.encryptPass(password) },
        clientType: 'PUBLIC',
      });
      this.setFieldValue('loading', false);
      return !!get(data, 'confirmForgotPassword.status');
    } catch (err) {
      this.setFieldValue('loading', false);
      uiStore.setErrors(this.errorObj(err));
      return false;
    }
  };

  logout = flow(function* () {
    uiStore.setErrors(undefined);
    try {
      yield Auth.signOut({ global: isProduction });
      this.resetStoreData();
    } catch (err) {
      uiStore.setErrors(this.errorObj(err));
      window.logger({ params: err });
    }
  });

  setUserLoggedIn = (status) => {
    this.isUserLoggedIn = status;
    if (status) {
      localStorage.setItem('EVER_LOGS_IN', status)
      this.setFieldValue('everLogsIn', localStorage.getItem('EVER_LOGS_IN'));
    }
  }

  get devPasswdProtection() {
    return this.devAuth.required && !this.devAuth.authStatus;
  }

  setDevAppAuthStatus(status) {
    if (cookie.save('DEV_AUTH_TOKEN')) {
      cookie.remove('DEV_AUTH_TOKEN');
    }
    cookie.save('DEV_AUTH_TOKEN', status, { maxAge: 86400 });
    this.devAuth.authStatus = status;
  }

  resetStoreData = () => {
    client.clearStore();
    client.stop();
    this.setUserLoggedIn(false);
    uiStore.setFieldValue('pusherVisible', false);
    userStore.forgetUser();
    this.authDispose();
  }

  authDispose = () => {
    this.signUpDetails = null;
    uiStore.setErrors(undefined);
    this.setFieldValue('loading', false);
    this.resetForm('SIGNUP_FRM');
    this.resetForm('LOGIN_FRM');
    this.resetForm('FORGOT_PASS_FRM');
    this.resetForm('CHANGE_PASS_FRM');
    this.resetForm('RESET_PASS_FRM');
  }
}

export default new AuthStore();
