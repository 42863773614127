import React from 'react';
import { observer } from 'mobx-react';
import { Form } from 'semantic-ui-react';
import { US_STATES } from '../../../constants/account';
import { FormInput, AutoComplete, FormDropDown, MaskedInput } from '../index';

class Address extends React.Component {
  render() {
    const { fielddata, changed, streetTwo, maskChanged, siblingElement, populateData } = this.props;
    return (
      <>
        <AutoComplete
          name="street"
          fielddata={fielddata.street}
          onPlaceSelected={place => populateData(place)}
          componentRestrictions={{ country: 'us' }}
          changed={changed}
        />
        {
          streetTwo
          && (
            <FormInput
              key="streetTwo"
              type="text"
              name="streetTwo"
              fielddata={fielddata.streetTwo}
              changed={changed}
              showerror
            />
          )
        }
        <Form.Group widths='equal'>
          <FormInput
            key="city"
            name="city"
            fielddata={fielddata.city}
            changed={changed}
          />
          <FormDropDown
            name="state"
            options={US_STATES}
            fielddata={fielddata.state}
            search
            fluid
            selection
            onChange={changed}
          />
          <MaskedInput
            zip
            key="zip"
            name="zip"
            format="#####"
            fielddata={fielddata.zip}
            changed={maskChanged}
          />
          {siblingElement}
        </Form.Group>
      </>
    );
  }
}

export default observer(Address);
