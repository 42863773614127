import { get } from 'lodash';
import { Auth } from 'aws-amplify';
import fetch from 'isomorphic-fetch';
import cookie from 'react-cookies';
import { ApolloClient, InMemoryCache, createHttpLink, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { REACT_APP_API_URL, DEV_FEATURE_ONLY, REACT_APP_DEPLOY_ENV, isPreviewPasswordRequired } from '../constants/common';
import Helper from '../helper/utilities/utility';

global.fetch = fetch;

const httpLink = createHttpLink({ uri: `${REACT_APP_API_URL}/graphql` });
const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const previewPassword = isPreviewPasswordRequired ? (cookie.load('DEV_PREVIEW_PASSWORD') || '') : '';
  let jwtToken = '';
  try {
    const session = await Auth.currentSession();
    jwtToken = get(session, 'accessToken.jwtToken');
  } catch (e) {
    jwtToken = '';
  }
  return {
    headers: {
      ...headers,
      authorization: jwtToken ? `Bearer ${jwtToken}` : '',
      previewPassword,
    },
  };
});
const afterWareLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    const context = operation.getContext();
    window.logger({ params: { operation, context } });
    return response;
  });
});

const errorLink = onError((res) => {
  if (!['localhost'].includes(REACT_APP_DEPLOY_ENV) && (get(res, 'graphQLErrors[0]') || get(res, 'networkError'))) {
    const params = {
      type: 'error',
      body: get(res, 'networkError') || get(res, 'graphQLErrors'),
      subject: 'Triggered by graphql client error handler',
      action: get(res, 'operation.operationName') || 'GraphQL Client'
    };
    Helper.sendAlertEmail(params);
  }
  const context = res.operation.getContext();
  window.logger({ params: { res, context } });
});

export const RETRY_CONFIG = {
  delay: {
    initial: 100, max: 3000, jitter: false,
  },
  attempts: {
    max: 5,
    retryIf: error => !!error && error.message === 'Failed to fetch',
  },
};

const retryLink = new RetryLink(RETRY_CONFIG);

// use with apollo-client
export const GqlClient = new ApolloClient({
  link: ApolloLink.from([authLink, afterWareLink, errorLink, retryLink, httpLink]),
  cache: new InMemoryCache(),
  connectToDevTools: DEV_FEATURE_ONLY,
});